import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Alert,
  Avatar,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Modal,
  Typography,
} from "@mui/material";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { C } from "../../util/C";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ImageService } from "../../../services/ImageService";
import { StudentService } from "../../../services/studentService";
import { yellow } from "@mui/material/colors";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import AlertModal from "../../buildSchool/Dashboard/popup/alertPopUp";
import { useNavigate } from "react-router-dom";
import { UploadFileService } from "../../../services/FileUploadService";
import { ImageConverter } from "../../../programs/imgaeToBase";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // height: 80
  // color: theme.palette.text.secondary,
}));
const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemBg = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
}));
const DataItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // height: 80
  // color: theme.palette.text.secondary,
}));
const FormItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 400,
  // height: 80
  // color: theme.palette.text.secondary,
}));
const DivisionItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // border: "1px solid"
  // height: 80
  // color: theme.palette.text.secondary,
}));
const StandardItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid",

  // height: 80
  // color: theme.palette.text.secondary,
}));
const style = {
  position: "absolute",
  top: "80%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 150,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  // overflow: "scroll",
};
export default function BasicGrid() {
  // for textfield validation

  // *alert
  const [openAlert, setAlert] = React.useState(false);
  const [alertVal, setAlertVal] = React.useState("");
  const handleAlertOpen = () => setAlert(true);
  const handleAlertClose = () => setAlert(false);

  // *sucess
  const [openSucess, setSucess] = React.useState(false);
  const handleSucessOpen = () => setSucess(true);
  const handleSucessClose = () => setSucess(false);

  let navigate = useNavigate();
  const [Age, setAge] = React.useState();
  const [Prof, setProf] = React.useState();
  const [Proff, setProff] = React.useState();
  const [studentName, SetstudentName] = React.useState();
  const [previousSchool, studentPreviousSchool] = React.useState();
  const [phoneNo, setPhoneNo] = React.useState();
  const [mphoneNo, setmPhoneNo] = React.useState();
  const [mname, setmname] = React.useState();
  const [fname, setfname] = React.useState();
  const [lang, setlang] = React.useState();
  //for datefield
  const [nowDate, setDate] = React.useState(null);
  const [address, setaddress] = React.useState(null);
  const [fq, setfQ] = React.useState(null);
  const [mq, setmQ] = React.useState(null);
  const [religion, setReligion] = React.useState(null);
  const textInput = React.useRef("");
  const onlyCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^zA-Z ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  // const onlyCharatersNum = (e, callback) => {
  //   const value = e.target.value
  //     ? e.target.value.replace(/[^zA-Z0-9.@#,\ ]+/gi, "")
  //     : "";

  //   if (e.target.value !== value) {
  //     e.target.value = value;
  //   }

  //   if (typeof callback === "function") {
  //     return callback(value);
  //   }
  // };

  const onlySCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z. ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };
  const onlyNumbers = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9 ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };
  const [gender, setGender] = React.useState("");
  const handleChange = (event) => {
    setGender(event.target.value);
  };
  const [Standard, setStandard] = React.useState([]);
  React.useEffect(() => {
    onstandardsLoad();
    setTotal(0);
    setCapacity(0);
  }, []);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };

  const [Standardname, setStandardName] = React.useState(null);
  const [StandardID, setStandardID] = React.useState(null);
  const [DivisionName, setDivisionName] = React.useState(null);
  const [div, setDiv] = React.useState(null);
  const [total, setTotal] = React.useState();
  const [Division, setDivision] = React.useState([]);
  const [Capacity, setCapacity] = React.useState();
  const [nameErrorText, setNameErrorText] = React.useState("");

  const [previousSchoolError, setPreviousSchoolError] = React.useState("");
  const [phoneNoErrorText, setPhoneNoErrorText] = React.useState("");
  const [mphoneNoErrorText, setmPhoneNoErrorText] = React.useState("");
  const [totalDivStudent, setTotalDivStudent] = React.useState(0);

  async function onStandardSelect(e, index) {
    setDivisionName("");
    setTotal(0);
    setCapacity(0);
    e.preventDefault();
    setStandardName(e.target.textContent);
    setStandardID(e.target.id);
    C.c(e.currentTarget.id);
    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "isSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await StudentService.StandardDivisionGenderCount(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      C.c(response.data.data);
      setDivision(response.data.data);
    } catch (error) {
      alert(error.message);
    }

    // try {
    //   let response = await StandardDivision.StandardDivisionWiseStandard(
    //     e.currentTarget.id,
    //     data.yearName,
    //     data.yearID
    //   );
    //   C.c(response.data.data);

    //   let sortDown = true;
    //   let sorted = [];

    //   // function sort(){
    //   const copy = [...Division];
    //   if (sortDown) {
    //     sorted = copy.sort(function (a, b) {
    //       return b - a;
    //     });
    //   } else {
    //     sorted = copy.sort(function (a, b) {
    //       return a - b;
    //     });
    //   }
    //   sortDown = !sortDown;
    //   setDivision(sorted);
    //   console.log(copy);

    //   var MaxCap = 0;
    //   for (let i = 0; i < response.data.data.length; i++) {
    //     let max = response.data.data[i].maxNo;
    //     // C.c(max);
    //     MaxCap += max;
    //     C.c(MaxCap);
    //   }
    //   setCapacity(MaxCap);

    //   let dataarray = [];
    //   let totalDivCount = 0;
    //   response.data.data.forEach(async (r, i) => {
    //     let girlcount = 0;
    //     let boycount = 0;
    //     try {
    //       let resp = await StudentService.approveddivisionstudentListAll(
    //         data.yearName,
    //         r.divisionName,
    //         e.target.id
    //       );
    //       console.log(resp);
    //       resp.data.data.forEach((e) => {
    //         if (e.studentGender.toUpperCase() == "BOY") {
    //           boycount = boycount + 1;
    //           totalDivCount = totalDivCount + 1;
    //         }
    //         if (e.studentGender.toUpperCase() == "GIRL") {
    //           girlcount = girlcount + 1;
    //           totalDivCount = totalDivCount + 1;
    //         }
    //       });
    //     } catch (err) {
    //       console.log(err);
    //     }
    //     console.log(girlcount, boycount);
    //     dataarray.push({
    //       divisionID: r.divisionID,
    //       divisionName: r.divisionName,
    //       maxNo: r.maxNo,
    //       standardDivisionID: r.standardDivisionID,
    //       standardID: r.standardID,
    //       standardName: r.standardName,
    //       yearID: r.yearID,
    //       yearName: r.yearName,
    //       gCount: girlcount,
    //       bCount: boycount,
    //     });
    //     setTotalDivStudent(totalDivCount);
    //     console.log(totalDivCount);
    //   });

    //   setTimeout(() => {
    //     console.log(dataarray);
    //     setDivision(dataarray);
    //   }, 500);
    // } catch (error) {
    //   console.log(error);
    // }
  }

  const onDivisionSelect = (e, index) => {
    e.preventDefault();
    C.c(e.target.id);
    setDivisionName(e.target.id);
    let cap = Division.find((r)=> r.division == e.target.id);
    console.log(cap);
    console.log(cap.maxNo)
    console.log(cap.boy+cap.girls);
    setCapacity(cap.maxNo);
    setTotal(cap.boy+cap.girls);
    let highlightDivision = [...Division];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision }) => highlightDivision
    );
    setDivision(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index][
      "isSelected"
    ]
      ? false
      : true;

    //   console.log(div);
    // const cap = Division.find((res)=> res.division == div);
    // console.log(cap);
    // setCapacity(cap.maxNo);

    // let tot = cap.boy + cap.girls;
    // console.log(tot);
    // setTotal(tot);
 
    
     
  };

  const [studenAge, setStudentAge] = React.useState(0);
  // const [date, setDate] = React.useState();

  // const ageCalu = (dob) => {
  //   C.c(dob);
  //   const birthDate = new Date(dob);
  //   const difference = Date.now() - birthDate.getTime();
  //   const age = new Date(difference);

  //   setStudentAge(Math.abs(age.getUTCFullYear() - 1970));
  // };

  const ageCalu = (dob) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();

    if (birthDate > currentDate) {
      setStudentAge("Date of birth is in the future");
      return;
    }

    let yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();
    let monthsDiff = currentDate.getMonth() - birthDate.getMonth();

    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff = 12 + monthsDiff;
    }

    if (yearsDiff === 0 && monthsDiff === 0) {
      setStudentAge("Born today");
    } else if (yearsDiff === 0) {
      setStudentAge(`${monthsDiff} month${monthsDiff > 1 ? "s" : ""}`);
    } else if (monthsDiff === 0) {
      setStudentAge(`${yearsDiff} year${yearsDiff > 1 ? "s" : ""}`);
    } else {
      setStudentAge(
        `${yearsDiff} y${yearsDiff > 1 ? "" : ""} ${monthsDiff} m${
          monthsDiff > 1 ? "" : ""
        }`
      );
    }
  };

  // const [imageUrl, setImageUrl] = React.useState("");
  // const [imageSrc, setImageSrc] = React.useState();
  // const fileBrowseHandler = (event) => {
  //   let value = URL.createObjectURL(event.currentTarget.files[0]);
  //   setImageSrc(event.currentTarget.files[0]);
  //   C.c(value);
  //   // C.c(filevalue);
  //   setImageUrl(value);
  //   // setImageSrc(filevalue);
  //   C.c(imageSrc);
  // };

  const [imageUrl, setImageUrl] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const fileInputRef = React.useRef(null);
  const [openTypeModal, setOpenTypeModal] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenTypeModal = () => setOpenTypeModal(true);
  const handleCloseTypeModal = () => setOpenTypeModal(false);
  

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png'];
    
    if (file.size > 2 * 1024 * 1024) {
      handleOpen();
      e.target.value = null;
      return;
    }
    if (!allowedTypes.includes(file.type)) {
      handleOpenTypeModal();
      // Reset the file input value
      e.target.value = null;
      return;
    }
  
    const base64 = await ImageConverter.convertToBase64(file);
    // setPostImage({ ...postImage, myFile: base64 });
    console.log(base64);
    setImageUrl(base64);
    fileInputRef.current.value = "";
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onStudentAdd = async (e) => {
    e.preventDefault();

    if (!Standardname || !DivisionName) {
      // handleAlertOpen();
      setAlert(true);
      setAlertVal("Standard or Division is not selected");
      setTimeout(() => {
        setAlert(false);
      }, 2000);
      return;
    }

    handleSucessOpen();
    const data = new FormData(e.currentTarget);
    // const fileData = new FormData();
    // fileData.append("file", imageSrc);
    // C.c(fileData.get("file"));
    // let fileUrl;
    // try {
    //   let response = await UploadFileService.upLoadFile(fileData);
    //   fileUrl = response.data;
    //   C.c(response.data.data);
    //   setTimeout(() => {
    //     setSucess(false);
    //   }, 2000);
    // } catch (error) {
    //   setTimeout(() => {
    //     setSucess(false);
    //   }, 2000);
    //   // return;
    //   C.c(error.message);
    // }

    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    let studentOBJ = {
      divisionID: DivisionName,
      divisionName: DivisionName,
      fatherName: data.get("fatherName"),
      fatherProfession: data.get("fatherProfession"),
      fatherQualification: data.get("fatherQualification"),
      // fatherMob:data.get("phonenum"),
      motherName: data.get("motherName"),
      motherProfession: data.get("motherprofession"),
      motherQualification: data.get("motherQualification"),
      // MotherMob:data.get("phonenum"),
      standardID: StandardID,
      standardName: Standardname,
      studentPreviousSchool: data.get("previousSchool"),
      studentAddress: data.get("address"),
      studentAge: data.get("studentAge"),
      // studentBloodGroup: data.get(""),
      // studentDOB: data.get("studentDOB"),
      studentDOB: nowDate.toLocaleDateString("en-GB"),
      fatherMobNum: data.get("phoneNo"),
      studentFirstLanguage: data.get("firstLang"),
      studentGender: data.get("studentGender"),
      motherMobNum: data.get("mphoneNo"),
      studentName: data.get("studentName"),
      studentProfileUrl: imageUrl,
      studentReligion: data.get("religion"),
      approveStatus: false,
      yearID: yearData.yearID,
      yearName: yearData.yearName,
    };
    C.c(studentOBJ);

    try {
      let response = await StudentService.addStudent(studentOBJ);
      C.c(response.data.data);
    } catch (error) {
      C.c(error.message);
    }
    if (!studentName && studentName.length < 3) {
      setNameErrorText("Please enter name");
    } else {
      setNameErrorText("");
    }

    if (!previousSchool && previousSchool.length < 3) {
      setNameErrorText("Please enter schoolname");
    } else {
      setNameErrorText("");
    }

    if (!phoneNo && phoneNo.length !== 10) {
      setPhoneNoErrorText("*Enter valid phone number");
    } else {
      setPhoneNoErrorText("");
    }

    if (!mphoneNo && mphoneNo.length !== 10) {
      setmPhoneNoErrorText("Please enter phone number");
    } else {
      setmPhoneNoErrorText("");
    }
    textInput.current.value = "";
    SetstudentName("");
    setDate("");
    setGender("");
    studentPreviousSchool("");
    setPhoneNo("");
    setmPhoneNo("");
    setmname("");
    setfname("");
    setlang("");
    setProff("");
    setProf("");
    setAge("");
    setStudentAge("");
    setmQ("");
    setfQ("");
    setaddress("");
    setReligion("");
    setImageUrl("");
    // setTimeout(() => {
    // setSucess(false);
    // }, 2000);
    setSucess(false)
  };

  const validatephoneNo = (e) => {
    if (e.target.value.toString().length > 9) {
      e.preventDefault();
      return;
    }
  };
  const onApproval = () => {
    navigate("/students-list", { replace: true });
  };

  return (
    <div>
      {/* <LaunchpadTopBar id="dataentry" /> */}
      <Box sx={{ flexGrow: 1, m: 2, textAlign: "center" }}>
        <Box
          sx={{
            flexGrow: 1,
            m: 2,
            textAlign: "center",
            backgroundColor: "lightblue",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              // backgroundColor: "lightblue",
              // borderRadius: "30px",
              fontWeight: "bold",
              mt: 2,
            }}
          >
            ADD A STUDENT TO THE SCHOOL DATABASE / SELECT STANDARD, DIVISION AND
            ENTER DETAILS
          </Typography>
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <ItemBg
              sx={{
                background:
                  " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
              }}
            >
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item xs={4} md={2} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      value={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index);
                      }}
                      sx={{ fontWeight: "bold" }}
                      className={element.isSelected ? "Selected" : ""}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </ItemBg>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <DivisionItem>
              {/* <Stack direction="row"> */}
              <Grid container spacing={{}}>
                {Division.map((element, index) => (
                  <Grid item md={1} xs={3} sx={{ cursor: "pointer" }}>
                    <Box>
                      <Box
                        sx={{
                          backgroundColor: "lightblue",
                          border: "2px solid",
                          borderRadius: "10px",
                        }}
                      >
                        <Button
                          // variant="contained"
                          id={element.division}
                          value={element.division}
                          sx={{
                            backgroundColor: "#03dff9",
                            cursor: "pointer",
                            color: "black",
                            borderRadius: "10px",
                            fontWeight: "bold",
                            width: "120px",
                            "&:hover": {
                              backgroundColor: "#038da6",
                              color: "white",
                            },
                          }}
                          onClick={(e) => {
                            onDivisionSelect(e, index);
                          }}
                          className={element.isSelected ? "selected" : ""}
                        >
                          {element.division}
                        </Button>
                      </Box>
                      <Stack
                        // paddingLeft="10px"
                        direction="row"
                        border="2px solid"
                        borderRadius="10px"
                        justifyContent=" center"
                        divider={<Divider orientation="vertical" flexItem />}
                        sx={{ textAlign: "center", px: 1 }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "lightpink",
                          }}
                        >
                          <Typography
                            sx={{
                              m: 1,
                              p: "0px 2px",
                              backgroundColor: "lightpink",
                            }}
                          >
                            {element.girls > 0 ? element.girls : 0}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: "lightBlue",
                          }}
                        >
                          <Typography
                            sx={{
                              m: 1,
                              p: "0px 2px",
                              backgroundColor: "lightBlue",
                            }}
                          >
                            {element.boy > 0 ? element.boy : 0}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                ))}
              </Grid>

              {/* </Stack> */}
            </DivisionItem>
          </Grid>
        </Grid>
        <Grid container spacing={0} sx={{}}>
          <Grid item xs={12}>
            <Grid container spacing={{}}>
              <Grid item xs={6} sx={{ cursor: "pointer" }}>
                <Box
                  sx={{
                    border: "2px solid",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: 150,
                    backgroundColor: "lightpink",
                  }}
                >
                  <Typography>TOTAL ADDED</Typography>
                  <Typography sx={{ m: 1 }}>{total !== null ? total : "No Students"}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sx={{ cursor: "pointer" }}>
                <Box
                  sx={{
                    border: "2px solid",
                    borderLeft:"none",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "lightblue",
                  }}
                >
                  <Typography>MAX CAPACITY</Typography>
                  <Typography sx={{ m: 1 }}>{Capacity !== null ? Capacity : "NA"}</Typography>
                  {/* {Capacity.map((element)=>(
                  <Typography sx={{ m: 1 }}>{element.MaxCap}</Typography>
                  )} */}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: "lightblue",
          m: 2,
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold" textAlign="center">
          THE FOLLOWING STUDENT WILL BE ADDED TO {Standardname} – {DivisionName}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 2, m:2   }}>
        <Grid component="form" onSubmit={onStudentAdd} container spacing={{xs:0, md:2}} height={"auto"} backgroundColor="white">
          <Grid item xs={9.5} md={10}>
            <Box>
            <Grid container spacing={2} >
              <Grid item xs={4} sm={4} md={2}>
                  <TextField
                    fullWidth
                    required
                    // id="outlined-basic"
                    label="NAME AS IN BIRTH CERTIFICATE"
                    // sx={{
                    //   width: 450,
                    // }}
                    inputRef={textInput}
                    value={studentName}
                    // InputProps={{
                    //   inputProps: {
                    //     minLength: 2,
                    //   },
                    // }}
                    inputProps={{
                      style: {
                        minLength: 2,
                        textTransform: "uppercase",
                        height: "32px",
                      },
                    }}
                    // onChange={forName}
                    variant="filled"
                    size="small"
                    name="studentName"
                    error={!!nameErrorText}
                    helperText={nameErrorText}
                    onInput={(e) => onlyCharaters(e)}
                    onChange={(e) => SetstudentName(e.target.value.toUpperCase())}
                    type="string"
                  />
              </Grid>

              <Grid item xs={4} sm={4} md={2}>

                <LocalizationProvider required dateAdapter={AdapterDateFns}>
                  
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    format="dd/MM/yyyy"
                    label="DOB"
                    
                    value={nowDate}
                    onChange={(newDate) => {
                      console.log(newDate.toLocaleDateString("en-GB"));
                      // debugger;
                      setDate(newDate);
                      ageCalu(newDate);
                    }}
                    // fullWidth
                    name="studentDOB"
                    sx={{ backgroundColor: "#eeeeee", minWidth:"100%" }}
                    maxDate={new Date()}
                    renderInput={(params) => (
                      
                      <TextField
                        {...params}
                        fullWidth
                        required
                        variant="filled"
                        size="small"
                        name="studentDOB"
                       
                        
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <TextField
                  fullWidth
                  // id="outlined-basic"
                  label="AGE"
                  inputProps={{
                    // inputMode: "numeric",
                    maxLength: 2,
                    readOnly: true,
                    style: { height: "32px" },
                  }}
                  // sx={{
                  //   width: 200,
                  // }}
                  variant="filled"
                  size="small"
                  name="studentAge"
                  value={studenAge}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <FormControl
                fullWidth
                  variant="filled"
                  size="small"
                  // sx={{ m: 0, width: 180 }}
                  required
                >
                  <InputLabel id="gender">Gender</InputLabel>
                  <Select
                    // labelId=""
                    // id="demo-simple-select-fille"

                    value={gender}
                    onChange={handleChange}
                    name="studentGender"
                    sx={{ height: "57px" }}
                  >
                    <MenuItem value={"BOY"}>👦🏽 BOY</MenuItem>
                    <MenuItem value={"GIRL"}>👧🏽 GIRL</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <TextField
                  fullWidth
                  // id="outlined-basic"
                  label="RELIGION"
                  // sx={{
                  //   width: 200,
                  // }}
                  inputProps={{
                    style: { textTransform: "uppercase", height: "32px" },
                  }}
                  onInput={(e) => onlyCharaters(e)}
                  variant="filled"
                  size="small"
                  name="religion"
                  onChange={(e) => setReligion(e.target.value.toUpperCase())}
                  value={religion}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={2}>
                <TextField
                  // id="outlined-basic"
                  label="FIRST LANGUAGE"
                  sx={
                    {
                      // width: 200,
                    }
                  }
                  variant="filled"
                  inputProps={{
                    style: { textTransform: "uppercase", height: "32px" },
                  }}
                  onInput={(e) => onlyCharaters(e)}
                  onChange={(e) => {
                    setlang(e.target.value.toUpperCase());
                  }}
                  value={lang}
                  size="small"
                  name="firstLang"
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 0.5 }} spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth 
                    // id="outlined-basic"
                    // required
                    label="PREVIOUS SCHOOL "
                    inputRef={textInput}
                    value={previousSchool}
                    // InputProps={{
                    //   inputProps: {
                    //     minLength: 2,
                    //   },
                    // }}
                    inputProps={{
                      style: {
                        minLength: 2,
                        textTransform: "uppercase",
                      },
                    }}
                    variant="filled"
                    size="small"
                    name="previousSchool"
                    error={!!previousSchoolError}
                    onInput={(e) => onlyCharaters(e)}
                    onChange={(e) =>
                      studentPreviousSchool(e.target.value.toUpperCase())
                    }
                    helperText={previousSchoolError}
                    // fullWidth={true}
                    type="string"
                  />
              </Grid> 
            </Grid>

            <Grid container sx={{ mt: 0.5 }} spacing={2}>
              <Grid item xs={6} sm={6} md={3}>
                <TextField
                  fullWidth 
                  // id="outlined-basic"
                  label="FATHER’S NAME AS ON AADHAR"
                  // sx={{
                  //   width: 500,
                  //   // backgroundColor: 'light pink'
                  // }}
                  variant="filled"
                  size="small"
                  onChange={(e) => {
                    setfname(e.target.value.toUpperCase());
                  }}
                  value={fname}
                  name="fatherName"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onInput={(e) => onlyCharaters(e)}
                  error={!!nameErrorText}
                  helperText={nameErrorText}
                  inputRef={textInput}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <TextField
                  fullWidth 
                  name="phoneNo"
                  label="CONTACT NO "
                  // type="number"
                  inputProps={{
                    maxLength: 10,
                  }}
                  value={phoneNo}
                  // sx={{
                  //   width: 200,
                  // }}
                  variant="filled"
                  size="small"
                  error={!!phoneNoErrorText}
                  helperText={phoneNoErrorText}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  // onKeyPress={validatephoneNo}
                  onInput={(e) => onlyNumbers(e)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <TextField
                  fullWidth  
                  // id="outlined-basic"
                  name="fatherQualification"
                  label="QUALIFICATION "
                  // sx={{
                  //   width: 280,
                  // }}
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                  value={fq}
                  onChange={(e) => setfQ(e.target.value.toUpperCase())}
                  onInput={(e) => onlySCharaters(e)}
                  variant="filled"
                  size="small"
                  inputRef={textInput}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <TextField
                  fullWidth   
                  // id="outlined-basic"
                  name="fatherProfession"
                  label="PROFESSION"
                  // sx={{
                  //   width: 600,
                  // }}
                  variant="filled"
                  size="small"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onInput={(e) => onlyCharaters(e)}
                  onChange={(e) => setProf(e.target.value.toUpperCase())}
                  value={Prof}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 0.5}} spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  label="ADDRESS "
                  name="address"
                  sx={
                    {
                      // width: 1200,
                    }
                  }
                  value={address}
                  onChange={(e) => setaddress(e.target.value.toUpperCase())}
                  // onInput={(e) => onlyCharatersNum(e)}
                  variant="filled"
                  size="small"
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 0.5 }} spacing={2}>
              <Grid item xs={6} sm={6} md={3}>
                <TextField
                  fullWidth
                  // id="outlined-basic"
                  name="motherName"
                  label="MOTHER’S NAME AS ON AADHAR"
                  // sx={{
                  //   width: 500,
                  // }}
                  variant="filled"
                  size="small"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onInput={(e) => onlyCharaters(e)}
                  onChange={(e) => setmname(e.target.value.toUpperCase())}
                  value={mname}
                  inputRef={textInput}
                  error={!!nameErrorText}
                  helperText={nameErrorText}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <TextField
                  fullWidth
                  // id="outlined-basic"
                  size="small"
                  name="mphoneNo"
                  value={mphoneNo}
                  // maxLength="10"
                  // type="number"
                  label="CONTACT NO"
                  error={!!mphoneNoErrorText}
                  helperText={mphoneNoErrorText}
                  onChange={(e) => setmPhoneNo(e.target.value)}
                  // sx={{
                  //   width: 200,
                  // }}
                  // onKeyPress={validatephoneNo}
                  onInput={(e) => onlyNumbers(e)}
                  inputProps={{
                    maxLength: 10,
                  }}
                  inputRef={textInput}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <TextField
                  fullWidth
                  // id="outlined-basic"
                  name="motherQualification"
                  label="QUALIFICATION "
                  // sx={{
                  //   width: 280,
                  // }}
                  value={mq}
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                  onInput={(e) => onlySCharaters(e)}
                  onChange={(e) => setmQ(e.target.value.toUpperCase())}
                  variant="filled"
                  size="small"
                  inputRef={textInput}
                />
              </Grid>
              
              <Grid item xs={6} sm={6} md={3}>
                <TextField
                  fullWidth
                  // id="outlined-basic"
                  name="motherprofession"
                  label="PROFESSION"
                  onInput={(e) => onlyCharaters(e)}
                  // sx={{
                  //   width: 600,
                  // }}
                  variant="filled"
                  size="small"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={(e) => setProff(e.target.value.toUpperCase())}
                  value={Proff}
                  // fullWidth={true}
                  inputRef={textInput}
                />
              </Grid>
            </Grid>
              <Grid item xs={12} sx={{ mt: 2}}>
               <Box display={"flex"} justifyContent={"center"} paddingBottom={"25px"}>
                {/* <Button
                    variant="contained"
                    onClick={onApproval}
                    sx={{
                      borderRadius: "30px",
                      fontSize: 15,
                      fontWeight: "bold",
                      // ml: 15,
                      textAlign:"center",
                    }}
                  >
                    SUBMIT FOR APPROVAL
                </Button> */}
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{
                    borderRadius: "30px",
                    fontSize: 15,
                    fontWeight: "bold",
                    // mt:1,
                    ml:5,
                    p:1,
                    textAlign:"center",
                  }}
                >
                  SAVE & ADD NEXT
                </Button>
               </Box>

                <Modal
                  open={openSucess}
                  onClose={() => setSucess(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div sx={style}>
                    <SucessModal
                      text="Student are Added Successfully"
                      name="Student are Added Successfully"
                    />
                  </div>
                </Modal>
                <Modal
                  open={openAlert}
                  // onClose={handleAlertClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div sx={style}>
                    <AlertModal text={alertVal} />
                  </div>
                </Modal>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={2.5} md={2} >
            <Box>
              <Box 
              display={"flex"}
              justifyContent="center">
              <Avatar
                alt="A"
                src={imageUrl}
                sx={{ width: 100, height: 100, margin: 8, borderRadius: "5%" }}
                variant="square"
              />
              </Box>
              <Box 
              display={"flex"}
              justifyContent="center">
              <input
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                accept="image/*"
                name="studentImg"
                onChange={handleFileUpload}
                // onChange={fileBrowseHandler}
              />
              <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={style}>
              <Typography variant="h6" textAlign="center">
              <b>The file size exceeds the 2MB limit. Please upload less than 2MB file.</b>
              </Typography>
              <Box
                  sx={{ display: "flex", justifyContent: "space-evenly", mt: 3 }}
               >
             <Button 
             color="success"
             size="small"
             variant="contained"
             onClick={handleClose} 
          >
            Close
          </Button>
        </Box>
        </Box>
      </Modal>
      <Modal
      open={openTypeModal}
      onClose={handleCloseTypeModal}
      aria-labelledby="modal-type-title"
      aria-describedby="modal-type-description"
    >
      <Box sx={style}>
        <Typography variant="h6" textAlign="center">
          <b>The file must be in JPEG or PNG format. Please upload a valid file.</b>
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 3 }}>
          <Button
            color="success"
            size="small"
            variant="contained"
            onClick={handleCloseTypeModal}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
              <label htmlFor="contained-button-file">
                <Button
                  required
                  variant="contained"
                  color="primary"
                  component="span"
                  sx={{ ml: 0, borderRadius: "25px" }}
                >
                  Upload
                </Button>
              </label>
              </Box>
              {/* <Input components="input" type="file" name="filename" /> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
