import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TopBar from '../../MainTopbar/Appbar';
import Admission from './NewAdmission';
import Grant from './GrantAndReject';
import Accept from './AcceptRequestForTc';
import { useNavigate } from 'react-router-dom';

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height:"100%", width:"100%" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {

  React.useEffect(()=>{
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, null, window.location.href);
    };
  },[])
  const [value, setValue] = React.useState(0);
//   const [highlight, setHighlight] = React.useState(false);

  const Click = (newValue) => {
    setValue(newValue);
    // setHighlight(newValue);
    console.log(value);
    // console.log(highlight);
  };

  return (
    <div>
      {/* <TopBar/> */}
         <Box sx={{border:"1px solid black",mt:1
        //  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
        }}>
           <Grid container sx={{ mt: 1,mb:1 }}>
            {/* <ButtonGroup variant="outlined" aria-label="outlined button group"> */}
              <Grid item md={3.5} xs={5} marginLeft={{md:25,xs:"0"}}>
                <Button
                  
                  size="small"
                  sx={{
                    fontWeight: "bold",
                    variant: "outlined",
                    border: "1px solid black",
                    borderRadius: "1px",
                    // width: "555px",
                    ":hover": {
                      backgroundColor: "black",
                      color: "white",
                      fontWeight: "bold",
                    },
                    backgroundColor: value == 0 ? "black" : "",
                    color: value == 0 ? "white" : "black",
                  }}
                  onClick={()=> Click(0)}
                >
                  FILL NEW ADMISSION FORM FOR THE NEXT ACADEMIC YEAR{" "}
                </Button>
              </Grid>
              {/* ----------------------------------------------GRANT / REJECT--------------------------------------------------------*/}
              <Grid item md={3.5} xs={4.2} >
                <Button
                  
                  size="small"
                  sx={{
                    fontWeight: "bold",
                    variant: "outlined",
                    border: "1px solid black",
                    borderRadius: "1px",
                    // width: "410px",
                    ":hover": {
                      backgroundColor: "black",
                      color: "white",
                      fontWeight: "bold",
                    },
                    backgroundColor: value == 1 ? "black" : "",
                    color: value == 1 ? "white" : "black",
                  }}
                  onClick={()=> Click(1)}
                >
                  GRANT / REJECT ADMISSION FOR NEXT ACADEMIC YEAR{" "}
                </Button>
              </Grid>

              {/*--------------------------------------------------------- ACCEPT REQUEST FOR TC AND LC ------------------------------------------*/}
              <Grid item md={3} xs={2.8} >
                <Button
                  
                  size="small"
                  sx={{
                    fontWeight: "bold",
                    variant: "outlined",
                    border: "1px solid black",
                    borderRadius: "1px",
                    // width: "270px",
                    ":hover": {
                      backgroundColor: "black ",
                      color: "white",
                      fontWeight: "bold",
                    },
                    backgroundColor: value == 2 ? "black" : "",
                  color: value == 2 ? "white" : "black",
                  }}
                  onClick={()=> Click(2)}
                >
                  ACCEPT REQUEST FOR TC AND LC{" "}
                </Button>
              </Grid>
          </Grid>
        </Box>

      <CustomTabPanel value={value} index={0}>
      <Admission/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
      <Grant/>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
    <Accept/>
      </CustomTabPanel>
     
      </div>
  );
}
