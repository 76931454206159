import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  Typography,
  IconButton,
  Paper,
} from "@mui/material";
import * as React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
// ---------------------------------------------------------------------------
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Report } from "../../../services/ReportService";
import { StaffAttendenanceService } from "../../../services/StaffAttendanence";
import { StaffService } from "../../../services/StaffService";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

const Item1 = styled("div")(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: "1px solid",
  // borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
  // padding: theme.spacing(1),
  // padding:'3px',
  borderRadius: "4px",
  textAlign: "center",
  fontWeight: "bold",
}));

const Item = styled(Paper)(({ theme }) => ({
  // padding: theme.spacing(1),
  textAlign: "center",
  // border: "1px solid black",
}));

const style = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1350,
  height: 565,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

// const staffListAll = [{

// }]

export default function StaffInformation() {
  const [open, setOpen] = React.useState(false);
  const [staffListAll, setstaffListAll] = React.useState([]);
  const [staffList, setstaffList] = React.useState([]);
  const [staffSelected, setstaffSelected] = React.useState();
  const [staff, setStaff] = React.useState();
  const [month, setMonth] = React.useState();
  const [academicYear, setacademicYear] = React.useState(
    JSON.parse(localStorage.getItem("academicYear"))
  );
  const [schoolName, setSchoolName] = React.useState(
    JSON.parse(localStorage.getItem("schoolDetails"))
  );
  const handleOpen = () => setOpen(true);

  React.useEffect(() => {
    onstaffsLoad();
  }, []);

  const onstaffsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    const staffArry = [];
    try {
      let response = await StaffService.staffListAll();

      setstaffListAll(response.data.data);
      console.log(response.data.data);

      response.data.data.forEach((res) => {
        staffArry.push({
          label: res.staffName + " || " + res.ssid,
          id: res.ssid,
        });
      });
      setstaffList(staffArry);
      console.log(staffArry);
    } catch (error) {
      console.log(error);
    }
    ///////////////bank///////////////////////
    const date = new Date(); // 2009-11-10
    const month = date.toLocaleString("default", { month: "long" });
    setMonth(month);

    console.log(month);
    try {
      let response = await StaffService.bankDetails(month, data.yearName);
      console.log(response.data.data);
      setStaff(response.data.data);
    } catch (error) {
      console.log(error);
    }

  };

  const onStaffSelect = async (e, v) => {
    // console.log(staffListAll)
    const found = staffListAll.find((element) => element.ssid == v.id);
    const find = staff.find((element) => element.bankDetails.ssid == v.id);
    found.accountNo = find.bankDetails.accountNo;
    found.bank = find.bankDetails.bankName;
    found.bankBranch = find.bankDetails.branch;
    found.ifsc = find.bankDetails.ifscDetails;
    found.pan = find.bankDetails.panDetails;
    console.log(found);
    setstaffSelected(found);
    // setStaffBank(find.bankDetails);
    console.log(find.bankDetails);

    try {
      let data = JSON.parse(localStorage.getItem("academicYear"));
      let response = await StaffAttendenanceService.getStaffAttendance(
        found.ssid,
        month,
        found.staffName,
        data.yearName
      )

      console.log(response.data.data);
      const attend = response.data.data[0] ? Object.values(response.data.data[0]).flat().filter(value => value === 'P').length : 0;
      console.log(attend);
      found.attendance = attend;
      console.log(found);
      setstaffSelected(found);
      console.log(staffSelected);
    } catch (error) {
      alert(error);
    }
  };

  let [info, setInfo] = React.useState({});

  const onInfoSelect = (e, v) => {
    v.forEach((rec) => {
      let name = rec.id;
      info[name] = true;
    });

    console.log(info);
  };
  //   console.clear();
  // console.log(staffSelected);

  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  const generatePDF = () => {
    const doc = new jsPDF("p", "pt", "a4");
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(15);
    doc.text("STAFF INFO", 245, 90);

    const text = `${schoolName.schoolNameOnDocs.toUpperCase()}`;
    const fontSize = 18;
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFontSize(fontSize);
    doc.text(text, pageWidth / 2, 40, { align: 'center' });

    const img = `${schoolName.schoolLogoUrl}`;
    doc.addImage(img, 'JPEG', 20, 10, 80, 70);

    doc.setFontSize(12);
    doc.text(academicYear.yearName, 500, 35);

    doc.addImage(staffSelected.staffProfilePhoto, 'JPEG', 245, 105, 120, 105);

    const data = [
      ["STAFF ID:", info.ssid === true ? staffSelected.ssid : "------"],
      // ["PHOTO:", info.photo === true ? staffSelected.photo : ""],
      ["NAME:", info.name === true ? staffSelected.staffName : "------"],
      ["DATE OF BIRTH:", info.dob === true ? staffSelected.staffDOB : "------"],
      ["AADHAR NO.", info.aadhar === true ? staffSelected.staffAadharNo : "------"],
      ["PHONE NO.", info.phone_no === true ? staffSelected.staffMobileNo[0] : "------"],
      ["GENDER", info.gender === true ? staffSelected.staffGender : "------"],
      [
        "DESIGNATION",
        info.designation === true ? staffSelected.designation : "------",
      ],
      [
        "QUALIFICATION",
        info.qualification === true ? staffSelected.staffQualification : "------",
      ],
      ["DATE OF JOIN", info.doj === true ? staffSelected.stafDOJ : "------"],
      [
        "PRESENT ADDRESS",
        info.present_address === true ? staffSelected.staffAddress : "------",
      ],
      [
        "MARITAL STATUS",
        info.marital_status === true ? staffSelected.staffMaritals : "------",
      ],
      [
        "MAIN SUBJECT",
        info.main_subject === true ? staffSelected.staffSubjectList.map((subject, index) => (
          subject.subjectName.toUpperCase()
        )) : "------",
      ],
      ["CLASS TR", info.class_teacher === true ? staffSelected.staffClass : "------"],
      [
        "ATTENDANCE DAYS :",
        info.attendenance === true ? staffSelected.attendance : "------",
      ],
      [
        "FATHER's NAME :",
        info.father_name === true ? staffSelected.staffFatherName : "------",
      ],
      [
        "MOTHER's NAME :",
        info.mother_name === true ? staffSelected.staffMotherName : "------",
      ],
      [
        "BLOOD GROUP :",
        info.blood_group === true ? staffSelected.bloodGroup : "------",
      ],
      ["SALARY :", info.salary === true ? staffSelected.monthlySalary : "------"],
      ["PAN NO. :", info.pan_no === true ? staffSelected.pan : "------"],
      ["BANK :", info.bank === true ? staffSelected.bank : "------"],
      [
        "ACCOUNT NO. :",
        info.account_no === true ? staffSelected.accountNo : "------",
      ],
      ["IFSC CODE :", info.ifsc_code === true ? staffSelected.ifsc : "------"],
      ["EMAIL :", info.email === true ? staffSelected.staffEmailID : "------"],
      [
        "TEACHING/NON-TEACHING :",
        info.type === true ? staffSelected.staffType : "------",
      ],
    ];
    doc.setFontSize(10);
    // doc.text("Copyright: Technotharanga Solutions Pvt.ltd", 210, 800);
    doc.autoTable({
      startY: 240,
      // head: [[" ", ""]],
      body: data,
      headerStyles: {
        textAlign: "center",
        marginLeft: "50px",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        // fontSize:12,
        // cellWidth:"10px"
      },
      theme: "grid",
    });
    doc.save("Staff info -  " + `${staffSelected.ssid}` + ".pdf");
  };

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="reports" /> */}
      <Grid container spacing={0}>
        <Grid xs={12} sx={{ align: "center", mt: 2 }}>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              backgroundColor: "lightblue",
              fontWeight: "bold",
            }}
          >
            DOWNLOAD STAFF'S SELECTED INFORMATION FOR THE ACADEMIC YEAR{" "}
            {academicYear.yearName ? academicYear.yearName : "invalid year"}
          </Typography>
        </Grid>
      </Grid>
      {/* ------------------------------------------------------------------------------------------------------------------------------------------- */}
      <Grid container spacing={2}>
        <Grid item md={1} xs={0}></Grid>
        <Grid item md={2} xs={12} 
            sx={{ width: "100%" ,mt:"5%", height: "55%" }}>
            {/* <Box
            
            > */}
              {/* <Grid md={4} xs={8}> */}

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={staffList}
                  width={{ md: "550", xs: "350" }}
                  onChange={onStaffSelect}
                  name="selectedStaff"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Staff Here" />
                  )}
                />
                {/* </Item > */}
              {/* </Grid>
            </Box> */}
          </Grid>

        <Grid item md={5} xs={12}  sx={{ width: "100%",mt:"5%", height: "55%"}}>
         
            {/* <Grid md={10} xs={10}> */}
              <Autocomplete
                multiple
                id="tags-outlined"
                options={staffInfo}
                // sx={{ width: "400" }}

                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                onChange={onInfoSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Staffs Informations"
                    placeholder="Select Information Here"
                  />
                )}
              />
            {/* </Grid> */}
          {/* </Box> */}
        </Grid>
        <Grid item md={3} xs={0}></Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* <Box
          sx={{
            width: "100%",
            mt: "5%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        > */}
          {/* <Grid container spacing={2} > */}
            <Grid item md={6} xs={7}  
            sx={{mt: "3%",
              textAlign: "center",
              display: "flex",
              justifyContent: "end"
              }}>
              <Button
                variant="contained"
                size="large"
                color="success"

                sx={{
                  borderRadius: "30px",
                  fontWeight: "bold",
                  fontSize: 15,
                  // marginLeft:"50%"

                }}

                // disabled = {true}
                // disabled ={StudentListAll === [] ? false : true}
                onClick={handleOpen}
              >
                Generate Staff's Info
              </Button>
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                {staffSelected ? (
                  <Box sx={style}>

                    <Box sx={{ position: 'sticky', top: -33, zIndex: 1000, width: "100%" }}>

                      <Box sx={{ float: "right", width: "100%", backgroundColor: "lightblue" }}>
                        <Button variant="contained" color="error" sx={{ float: "right" }}
                          onClick={() => {
                            setOpen(false);
                            setInfo({});
                          }}
                        >
                          close</Button>
                        <Typography variant="h5" fontWeight="bold" textAlign={"center"} sx={{ ml: 8 }} >
                          SELECTED INFORMATION OF STAFF
                        </Typography>

                      </Box>
                    </Box>

                    <Box>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              mt: 2

                            }}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              display={info.ssid === true ? "block" : "None"}
                              src={staffSelected.staffProfilePhoto}
                              // id={staffSelected.staffID}
                              // name={staffSelected.staffName}
                              variant="square"
                              sx={{
                                width: 159,
                                height: 210,
                                mx: 5,
                              }}

                            />
                          </Box>
                          <Item sx={{ textAlign: "center", width: "100%" }}>
                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={info.name === true ? "block" : "None"}
                            >
                              <b>NAME :</b>
                              {info.name === true
                                ? staffSelected.staffName
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              //border="1px solid black"
                              sx={{ p: 2 }}
                              display={info.ssid === true ? "block" : "None"}
                            >
                              <b> SSID : </b>
                              {info.ssid === true
                                ? staffSelected.ssid
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={info.dob === true ? "block" : "None"}
                            >
                              <b>DATE OF BIRTH :</b>
                              {info.dob === true
                                ? staffSelected.staffDOB
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={info.aadhar === true ? "block" : "None"}
                            >
                              <b>AADHAR NO :</b>
                              {info.aadhar === true
                                ? staffSelected.staffAadharNo
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.phone_no === true ? "block" : "None"
                              }
                            >
                              <b>PHONE NO :</b>
                              {info.phone_no === true
                                ? staffSelected.staffMobileNo[0]
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={info.gender === true ? "block" : "None"}
                            >
                              <b>GENDER :</b>
                              {info.gender === true
                                ? staffSelected.staffGender
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.designation === true ? "block" : "None"
                              }
                            >
                              <b>DESIGNATION :</b>
                              {info.designation === true
                                ? staffSelected.designation
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.qualification === true ? "block" : "None"
                              }
                            >
                              <b>QUALIFICATION :</b>
                              {info.qualification === true
                                ? staffSelected.staffQualification
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={info.doj === true ? "block" : "None"}
                            >
                              <b>DATE OF JOIN :</b>
                              {info.doj === true
                                ? staffSelected.stafDOJ
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.present_address === true ? "block" : "None"
                              }
                            >
                              <b>PRESENT ADDRESS :</b>
                              {info.present_address === true
                                ? staffSelected.staffAddress
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.marital_status === true ? "block" : "None"
                              }
                            >
                              <b>MARITAL STATUS :</b>
                              {info.marital_status === true
                                ? staffSelected.staffMaritals
                                : "Not found"}
                            </Typography>

                            {/* <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.main_subject === true ? "block" : "None"
                              }
                            >
                              <b>MAIN SUBJECT :</b>
                              {info.main_subject === true
                                ? staffSelected.stafSubjectList
                                : "Not found"}
                            </Typography> */}
                            <Typography 
                              display={
                                    info.main_subject === true ? "block" : "None"
                                  }>
                              <b>MAIN SUBJECTS :</b>
                              {staffSelected.staffSubjectList.map((subject, index) => (
                                <Typography key={index} variant="body1"
                                  display={
                                    info.main_subject === true ? "block" : "None"
                                  }
                                >
                                  {info.main_subject === true ? subject.subjectName.toUpperCase() : "No Subjects"}
                                </Typography>
                              ))}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.class_teacher === true ? "block" : "None"
                              }
                            >
                              <b>CLASS TR :</b>
                              {info.class_teacher === true
                                ? staffSelected.staffClass
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.attendenance === true ? "block" : "None"
                              }
                            >
                              <b>ATTENDENCE DAYS :</b>
                              {info.attendenance === true
                                ? staffSelected.attendance
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.father_name === true ? "block" : "None"
                              }
                            >
                              <b>FATHER'S NAME :</b>
                              {info.father_name === true
                                ? staffSelected.staffFatherName
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.mother_name === true ? "block" : "None"
                              }
                            >
                              <b>MOTHER'S NAME :</b>
                              {info.mother_name === true
                                ? staffSelected.staffMotherName
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.blood_group === true ? "block" : "None"
                              }
                            >
                              <b>BLOOD GROUP :</b>
                              {info.blood_group === true
                                ? staffSelected.bloodGroup
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={info.salary === true ? "block" : "None"}
                            >
                              <b>SALARY :</b>
                              {info.salary === true
                                ? staffSelected.monthlySalary
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={info.pan_no === true ? "block" : "None"}
                            >
                              <b>PAN NO :</b>
                              {info.pan_no === true
                                ? staffSelected.pan
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={info.bank === true ? "block" : "None"}
                            >
                              <b>BANK :</b>
                              {info.bank === true
                                ? staffSelected.bank
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.account_no === true ? "block" : "None"
                              }
                            >
                              <b>ACCOUNT NO :</b>
                              {info.account_no === true
                                ? staffSelected.accountNo
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={
                                info.ifsc_code === true ? "block" : "None"
                              }
                            >
                              <b>IFSC CODE :</b>
                              {info.ifsc_code === true
                                ? staffSelected.ifsc
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={info.email === true ? "block" : "None"}
                            >
                              <b>EMAIL :</b>
                              {info.email === true
                                ? staffSelected.staffEmailID
                                : "Not found"}
                            </Typography>

                            <Typography
                              textAlign="center"
                              sx={{ p: 2 }}
                              display={info.type === true ? "block" : "None"}
                            >
                              <b>TEACHING/NON-TEACHING :</b>
                              {info.type === true
                                ? staffSelected.staffType
                                : "Not found"}
                            </Typography>
                          </Item>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={generatePDF}
                            sx={{
                              width: "180px",
                              float: "center",
                              borderRadius: "30px",
                              fontSize: 15,
                              fontWeight: "bold",
                              ml: 75,
                              mt: 2,
                            }}
                          >
                            pdf
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                ) : (
                  <h2>select the staff</h2>
                )}
              </Modal>
            </Grid>
            <Grid item md={6} xs={5} 
             sx={{mt: "3%",
             textAlign: "center",
             display: "flex",
             justifyContent: "start"
             }}>
              <Button
                variant="contained"
                onClick={onLaunchpad}

                sx={{
                  backgroundColor: "#ca424f",
                  fontSize: 15,
                  fontWeight: "bold",
                  borderRadius: "30px",
                  width: "120px",
                  // marginRight:"50%",


                  "&:hover": { backgroundColor: "#ca424f" },
                  // mx: "auto"
                }}
              >
                exit
              </Button>
            </Grid>
          {/* </Grid> */}
        {/* </Box> */}
      </Grid>
      {/* ------------------------------------------------------------------------------ */}
         </React.Fragment>
  );


}

const staffInfo = [
  { title: "NAME", id: "name" },
  { title: "SSID", id: "ssid" },
  {
    title: "DESIGNATION",
    id: "designation",
  },
  { title: "EMAIL", id: "email" },
  { title: "PHONE NUMBER", id: "phone_no" },
  { title: "TEACHING / NON-TEACHING", id: "type" },
  { title: "CLASS TEACHER", id: "class_teacher" },
  { title: "MAIN SUBJECT", id: "main_subject" },
  { title: "SALARY", id: "salary" },
  { title: "ATTENDANCE DAYS", id: "attendenance" },
  { title: "DATE OF JOIN ", id: "doj" },
  { title: "AADHAR NO", id: "aadhar" },
  { title: "GENDER", id: "gender" },
  { title: "BLOOD GROUP", id: "blood_group" },
  { title: "QUALIFICATION", id: "qualification" },
  { title: "DATE OF BIRTH", id: "dob" },
  { title: "FATHER'S NAME ", id: "father_name" },
  { title: "MOTHER'S NAME", id: "mother_name" },
  {
    title: "PRESENT ADDRESS",
    id: "present_address",
  },
  {
    title: "MARITAL STATUS",
    id: "marital_status",
  },

  {
    title: "ACCOUNT NUMBER",
    id: "account_no",
  },

  {
    title: "PAN CARD NUMBER",
    id: "pan_no",
  },
  { title: "IFSC CODE", id: "ifsc_code" },
  { title: "BANK", id: "bank" },


];
