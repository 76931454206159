import * as React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Stack,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import { StandardSubjectService } from "../../../services/StandardSubject";
import { StudentService } from "../../../services/studentService";
import { C } from "../../util/C";
import Img from "../../launchPad/student.jpg";
import { BuildSchoolExam } from "../../../services/examStandards";
// ----------------Student Lists Import---------------------------
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
// -----------------------------------------------------------------------
import { DataGrid } from "@mui/x-data-grid";
import Popover from "@mui/material/Popover";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import { HorizontalRule, Margin } from "@mui/icons-material";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import ClearIcon from "@mui/icons-material/Clear";
import { getValue } from "@testing-library/user-event/dist/utils";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Report } from "../../../services/ReportService";
import { useNavigate } from "react-router-dom";

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//     ...theme.typography.body2,
//     // padding: theme.spacing(1),
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//     fontWeight: "fontWeightBold"
//   }));
// ----------------Student Lists ---------------------------
const style = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1350,
  height: 565,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

//-----------------------------------------------------------
const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  // color: theme.palette.text.secondary,
  // fontWeight: "bold",
  // borderRadius:'1px'
  // backgroundColor: 'lightorange'
}));

const Item1 = styled(Paper)(({ theme }) => ({
  // padding: theme.spacing(1),
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  // borderRadius:'1px',
  // fontWeight:'bold',
}));
const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#03dff9",
  "&:hover": {
    background: "#038da6",
    color: "white",
  },
}));
const ItemE = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#5485e594",
  "&:hover": {
    background: "#3661b494",
  },
}));

const columns = [
  {
    field: "id",
    headerName:"NO.",
    flex:0.5,
    headerAlign:"center",
    align:"center",
    editable: false,
    sortable: false,
    align: "center",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"ROLL"}</strong>,
    headerClassName: "super-app-theme--header",
  },

  {
    field: "name",
    headerName:"NAME",
    flex:1,
    headerAlign:"center",
    align:"center",
    editable: false,
    sortable: false,
    align: "center",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
    headerClassName: "super-app-theme--header",
  },

  {
    field: "usn",
    headerName:"USID",
    flex:1,
    headerAlign:"center",
    align:"center",
    editable: false,
    sortable: false,
    align: "center",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"USID"}</strong>,
    headerClassName: "super-app-theme--header",
  }
]

export default function HallTicket() {
  const [checked, setChecked] = React.useState([1]);
  // const handleToggle = (value) => () => {
  //   const currentIndex = checked.indexOf(value);
  //   const newChecked = [...checked];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }

  //   setChecked(newChecked);
  // };
  const [Standard, setStandard] = React.useState([]);
  // const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      C.c(response.data.data);
      setStandard(response.data.data);
      C.c(Standard);
    } catch (error) {
      C.c(error);
    }
  };
  const [standardName, setstandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [DivisionName, setDivisionName] = React.useState();
  const [Subject, setSubject] = React.useState([]);
  const [Division, setDivision] = React.useState([]);
  const [standardTest, updateStandardTest] = React.useState([]);
  const [StudentListAll, SetStudentListAll] = React.useState([]);
  // const [rows, setRows] = React.useState([]);
  const [anchor, setAnchor] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [academicYear, setacademicYear] = React.useState(
    JSON.parse(localStorage.getItem("academicYear"))
  );
  const [schoolName, setschoolName] = React.useState(
    JSON.parse(localStorage.getItem("schoolDetails"))
  );
  const [testTitleName, settestTitleName] = React.useState();
  const [Examtimes, setExamtimes] = React.useState([]);

  async function onStandardSelect(e, index) {
    e.preventDefault();
    setDivisionName("");
    setTestName("");
    updateStandardTest([]);
    SetStudentListAll([]);
    setExamtimes([]);
    setstandardName(e.currentTarget.innerText);
    setStandardID(e.target.id);
    C.c(e.currentTarget.id);
    C.c(e.currentTarget.innerText);

    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "iSSelected"
    ]
      ? false
      : true;

    let stdId = e.currentTarget.id;
    let data = JSON.parse(localStorage.getItem("academicYear"));
    //  const schoolData = JSON.parse(localStorage.getItem("schoolDetails"));
    //  const academicData = ;
    //  setschoolName(
    //   schoolData ? (
    //     <b>{schoolData.schoolNameOnDocs}</b>
    //   ) : (
    //     "NEW INTERNATIONAL SCHOOL"
    //   )
    // );
    // setacademicYear(academicData.yearName);
    // console.log(academicData);
    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      C.c(response.data.data);
      setDivision(response.data.data);
    } catch (error) {
      C.c(error.message);
    }

    try {
      let response =
        await StandardSubjectService.findSubjectListByStandardIDYearID(
          data.yearName,
          stdId
        );
        console.log(response.data.data);

response.data.data.sort(function (a, b) {
        return a.subjectOrder - b.subjectOrder;
      });

      setSubject(response.data.data);
    } catch (error) {
      C.c(error.message);
    }

    try {
      let response = await BuildSchoolExam.showBuildSchoolStandardTest(
        e.target.id,
        data.yearName
      );
      console.log(response.data.data);

      response.data.data.sort(function (a, b) {
        return a.testOrder - b.testOrder;
      });

      updateStandardTest(response.data.data);
    } catch (error) {
      console.log(error);
    }

    // try{
    //   let response = await Report.getHallTicket(
    //     e.currentTarget.id,
    //     e.target.id
    //   )
    //   C.c(response.data.data);
    // }
    // catch(error){
    //   C.c(error.message);
    // }
  }

  const onTestNameSelect = async (e, index) => {
    setExamtimes([]);
    console.log(e.currentTarget.textContent);
    settestTitleName(e.currentTarget.textContent);

    let highlightTest = [...standardTest];
    highlightTest = highlightTest.map(
      ({ isSelected, ...highlightTest }) => highlightTest
    );
    updateStandardTest(highlightTest);
    highlightTest[index]["isSelected"] = highlightTest[index]["isSelected"]
      ? false
      : true;

    let examtiming = [];

    try {
      let response = await Report.getHallTicketData(
        standardName,
        e.currentTarget.textContent
      );
      C.c(response.data.data);
//       let sor = response.data.data;
//        sor.timingesDatas.sort((a,b)=>a.date.localeCompare(b.date));
// console.clear();
// console.log(sor);

      response.data.data.timingesDatas.forEach((rec) => {
        console.log(rec);
        rec.startTimeOnly = rec.startDate.split(",");
        rec.endTimeOnly = rec.endDate.split(",");
        examtiming.push(rec);
      });
      setExamtimes(examtiming);
    } catch (error) {
      C.c(error.message);
    }
  };

  const [testName, setTestName] = React.useState("");
  async function onStandardSelectTest(e) {
    setTestName(e.currentTarget.textContent);
  }

  async function onSelectDivision(e, index) {
     
    console.log(e.currentTarget.id);
    setDivisionName(e.currentTarget.id);

    let highlightDivision = [...Division];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision }) => highlightDivision
    );
    setDivision(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index][
      "isSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));
    let student = [];
    try {
      let response = await StudentService.approveddivisionstudentListAll(
        data.yearName,
        e.target.id,
        StandardID
      );
C.c(response.data.data);
      // response.data.data.forEach((rec, i) => {
      //   student.push({
      //     id: i + 1,
      //     name: rec.studentName,
      //     usn : rec.studentUSN
      //   });
      //   // C(rec)
      // });
      SetStudentListAll(response.data.data.sort((a,b)=> a.studentName.localeCompare(b.studentName)));
      // C.c(rows);
    } catch (error) {
      C.c(error.message);
    }
  }
  //  const openPopover = (event)=>{
  //    setAnchor(event.currentTarget);
  // }

  // console.log(StandardSubjectListAll);
  const handleData = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    console.log(data.get("class"));
  };

  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  const generatePDF = () => {
  const doc = new jsPDF("p","pt","a4");

  // Loop over the student list
  StudentListAll.forEach((student) => {
    // Generate exam table content
    const tableData = Examtimes.map((exam) => [
      exam.subject.toUpperCase(),
      `${exam.date}/${exam.day.toUpperCase()}`,
      `${exam.startTimeOnly[1]} - ${exam.endTimeOnly[1]}`
    ]);

    // Add the student's hall ticket content to the PDF
    doc.setFontSize(10);
    doc.text(` ${academicYear.yearName ? academicYear.yearName : 'Invalid year'}`, 500, 35);

    const text = `${schoolName.schoolNameOnDocs.toUpperCase()}`;
const fontSize = 18;
const pageWidth = doc.internal.pageSize.getWidth();
doc.setFontSize(fontSize);
doc.text(text, pageWidth / 2, 35, { align: 'center' });

const img = `${schoolName.schoolLogoUrl}`;
doc.addImage(img, 'JPEG', 20, 10, 80, 70);

doc.setFontSize(15);
doc.text("EXAM HALL TICKET", 210,65)


    doc.setFontSize(13);
    doc.text(`${testName.toUpperCase()}`,240,110)

    doc.setFontSize(15);
    doc.text(`${student.studentName}`,240,140)

    const stimg = student.studentProfileUrl;
    doc.addImage(stimg, 'JPEG',465,70,80,75 );
    // Add exam table to the PDF
    doc.autoTable({
      startY: 155,
      theme:"grid",
      head: [['SUBJECT', 'DATE', 'TIME',"Invigilator's Sign"]],
      body: tableData,
      headStyles: {
        valign: "middle",
        // backgroundColor: "lightgray",
        halign: "center",
        fontSize:12,
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        fontSize:10,
      },
    });
doc.setFontSize(9);
doc.setFont("helvetica", "bold");
doc.text("NO ENTRY WILL BE ALLOWED IN THE EXAM HALL WITHOUT THIS HALL TICKET", 130, 800);
    // Add a page break between each student's hall ticket
    doc.addPage();
  });

  const pageCount = doc.internal.getNumberOfPages();
  if (pageCount > 1) {
    doc.deletePage(pageCount);
  }

  // Save the PDF
  doc.save(standardName+ '-' + DivisionName + '-' + testName +'.pdf');
};

  

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="reports" /> */}
      <Box sx={{width:"100%", padding:"0 10px", boxSizing:"border-box"}}>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          mt: 2,
          fontWeight: "bold",
        }}
      >
        SELECT STANDARD, DIVISION, TEST TO GENERATE AND DOWNLOAD HALL TICKETS
      </Typography>
      {/* <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container spacing={1}>
          <Grid Item1 xs={8}>
            <Item1
              sx={{
                background:
                  " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
              }}
            >
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item xs={3.9} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      name={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </Item1>
          </Grid>

          <Grid Item1 xs={4}>
            <Item1
              sx={{
                background:
                  " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
              }}
            >
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Division.map((element, index) => (
                  <Grid item xs={3} sx={{ cursor: "pointer" }}>
                    <ItemD
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        onSelectDivision(e, index);
                      }}
                      className={element.isSelected ? "selected" : ""}
                      id={element.divisionName}
                    >
                      {" "}
                      {element.divisionName}
                    </ItemD>
                  </Grid>
                ))}
              </Grid>
            </Item1>
          </Grid>
        </Grid>
      </Box> */}
      <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item md={8} sm={8} xs={8}>
            <Item1
              sx={{ background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)"}}
            >
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item xs={3.9} md={1.9} sx={{ cursor: "pointer"}}marginLeft={{md:"8px",xs:"0"}}>
                    <ItemS
                      id={element.standardID}
                      name={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index)
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </Item1>
          </Grid>
          <Grid item xs={4}>
            <Item1
               sx={{ background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)"}}
            >
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Division.map((element, index) => (
                  <Grid item xs={3} sx={{ cursor: "pointer" }}>
                    <ItemD
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={(e)=> {
                        onSelectDivision(e, index)
                      }}
                      className={element.isSelected ? "selected" : ""} 
                      id={element.divisionName}
                    >
                      {" "}
                      {element.divisionName}
                    </ItemD>
                  </Grid>
                ))}
              </Grid>
            </Item1>
          </Grid>
        </Grid>
        <Grid
        container
        spacing={1}
        sx={{ width: "100%", mt: "10px", boxSizing: "border-box" }}
      >
        <Grid item xs={8.1} md={8.05}>
          <Item1  sx={{ background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)"}}>
            <Grid container spacing={1} sx={{ minHeight: 40 }}>
              {standardTest.map((element, index) => (
                <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                  <ItemE
                    sx={{ fontWeight: "bold" }}
                    onClick={(e) => {
                      onTestNameSelect(e, index, element.testTitleName);
                      setTestName(e.target.textContent);
                    }}
                    className={element.isSelected ? "selectedTest" : ""}
                  >
                    {element.testTitleName.toUpperCase()}
                  </ItemE>
                </Grid>
              ))}
            </Grid>
          </Item1>
        </Grid>
      </Grid>
     

      {/* ___________________________Student lists____________________________ */}
      <Grid container spacing={5}>
        {/* <Grid > */}
          <Grid item xs={12} md={4}
            sx={{
              // width: "32%",
              marginTop: "2%",
              ml:0,
              height: "55%",
              // border:"2px solid blue"
            }}
          >
            <Typography
              sx={{ border: "2px solid black",paddingBottom:"5px"}}
              variant="body1" 
              fontWeight="fontWeightBold"
              color="primary"
              backgroundColor="lightgray"
              textAlign="center"
             
            >
              STUDENTS LIST
            </Typography>
            <Item
              sx={{
                border: "1px solid black",
                minHeight:180,
                maxHeight:420,
                // width: 511,
                textAlign: "center",
                overflowY: "scroll",
              }}
            >

              <List
                dense
                sx={{
                  // width: 450,
                  bgcolor: "background.paper",
                  textAlign: "center",
                }}
              >
                {StudentListAll.map((value) => {

                  return (
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          alt={`Avatar n°${value + 1}`}
                      
                        />
                      </ListItemAvatar>

                      <ListItemText id={value.divisionName}>
                        <b>Student Name :</b>{" "}
                        {value.studentName ? value.studentName : "invalid name"}{" "}
                        || <b>Division :</b> {value.divisionName}
                      </ListItemText>
                    </ListItemButton>
                  );
                })}
              </List>

         
            </Item>
          </Grid>
        {/* </Grid> */}

{/* <Box
        sx={{
          height: 300,
          width: "35%",
          // padding: "0 15px",
          paddingLeft:"20px",
          mt:3,
          boxSizing: "border-box",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          hideFooter={true}
          // disableSelectionOnClick
          disableColumnMenu
        />
      </Box> */}



        {/* -----------------------------------------Hall Tickets--------------------------------------------- */}
        <Grid item xs={12} md={7}>
          <Box
            sx={{
                border: "1px solid black",
                minHeight:150,
                maxHeight:420,
                // width: 511,
                textAlign: "center",
                overflowY: "scroll",
                mt:4,
                // marginTop: "6%",
                

            }}
          >
            <Typography
              variant="subtitle1"
              component="div"
              fontWeight="bold"
              textAlign="center"
              sx={{ flexGrow: 1, color: "blue" }}
            >
              Academic Year :{" "}
              {academicYear.yearName ? academicYear.yearName : "invalid year"}
            </Typography>
            <Grid container spacing={0} p={2}  >
              <Grid item xs={8} >
                <Box  border={"2px solid black"}>
                  <Item >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1,textTransform:"uppercase" ,paddingBottom:"4px"}}
                      
                    >
                      <b>{schoolName.schoolNameOnDocs}</b>
                    </Typography>
                    {/* <Item> */}
                    <Typography
                      variant="body1"
                      fontWeight="fontWeightBold"
                      color="darkblue"
                      backgroundColor="#F9FBE7"
                      sx={{ textAlign: "center" }}
                    >
                      EXAM HALL ENTRANCE PASS
                    </Typography>
                    {/* </Item> */}
                  </Item>
                </Box>
                {/* <Item  >
              <Typography
                variant="body1"
                fontWeight="bold"
                color="staleblue"
                backgroundColor="#F9FBE7"
               
                sx={{ p:2}}
              >
                Student Name
              </Typography>
            </Item> */}
              </Grid>

              <Grid
                item
                xs={4}
                // border="1px solid black"
                // borderLeft="none"
                sx={{ backgroundColor: "#F9FBE7" }}
              >
                <Box >
                  <Stack spacing={{md:"",xs:4} }>
                    <Item sx={{ backgroundColor: "#F9FBE7",fontWeight:"bold"}}>
                      {testName}
                    </Item>
                    <Item sx={{ backgroundColor: "#F9FBE7",fontWeight:"bold" }}>
                      {standardName} - {DivisionName}
                    </Item>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <Grid container p={2}>
              {Examtimes.map((rex) => (
                <Grid item xs={4} >
                  <Stack spacing={0.5} sx={{textTransform:"uppercase"}}>
                    <Item sx={{ fontWeight: "bold", }}>{rex.subject}</Item>
                    <Item>
                      <Typography variant="subtitle2">
                        {rex.date}/{rex.day}
                      </Typography>
                    </Item>
                    <Item>
                      <Typography variant="subtitle2">
                        {rex.startTimeOnly[1]} - {rex.endTimeOnly[1]}
                      </Typography>
                    </Item>
                    {/* <Item>
                      <Typography></Typography>
                    </Item> */}
                  </Stack>
                </Grid>
              ))}
            </Grid>

            <Typography
              align="center"
              backgroundColor="#F9FBE7"
              fontWeight="bold"
              sx={{ py: 2 }}
            >
              NO ENTRY WILL BE ALLOWED IN THE EXAM HALL WITHOUT THIS HALL
              TICKET.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      
        <Box sx={{display:"flex", justifyContent:"space-evenly",mt:2,paddingBottom:"25px"}}>
          <Button
            variant="contained"
            size="large"
            color="success"
            sx={{
              borderRadius: "30px",
              fontSize: 15,
              fontWeight: "bold",
              
            }}
            onClick={handleOpen}
          >
            Generate HallTicket
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={onLaunchpad}
            sx={{
              backgroundColor: "#ca424f",
              fontSize: 15,
              fontWeight: "bold",
              borderRadius: "30px",
              width: "120px",
              "&:hover": { backgroundColor: "#ca424f" },
              // mt: 1,
            }}
          >
            exit
          </Button>
</Box>
<Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
            <Box sx={{position: 'sticky', top: -33, zIndex: 1000,width:"100%"}}>

<Box sx={{float:"right",width:"100%",backgroundColor:"lightblue"}}> 
<Button variant="contained" color="error" sx={{float:"right"}} 
onClick={()=>{
setOpen(false);
// setInfo({});
}}
>
close</Button>
<Typography variant="h5" fontWeight="bold" textAlign={"center"}sx={{ml:8}} >
Generated HallTicket
</Typography>            
  
</Box>
</Box>
              {/* <Typography variant="h4" textAlign="center">
                <b>Generated HallTicket</b>
              </Typography> */}
              {StudentListAll.map((rec) => (
                <Box
                  sx={{
                    flexGrow: 1,
                    marginLeft: "12%",
                    marginTop: "5%",
                    border: "1px solid black",
                    p: 1,
                  }}
                  width="70%"
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    fontWeight="bold"
                    textAlign="center"
                    sx={{ flexGrow: 1, color: "blue" }}
                  >
                    Academic Year :{" "}
                    {academicYear.yearName
                      ? academicYear.yearName
                      : "invalid year"}
                  </Typography>
                  <Grid container spacing={0}>
                    <Grid item xs={8} border="1px solid black">
                      <Box borderBottom="1px solid black">
                        <Item>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1,textTransform:"uppercase" }}
                          >
                            <b>{schoolName.schoolNameOnDocs}</b>
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight="fontWeightBold"
                            color="darkblue"
                            backgroundColor="#F9FBE7"
                            sx={{ textAlign: "center" }}
                          >
                            EXAM HALL ENTRANCE PASS
                          </Typography>
                        </Item>
                      </Box>
                      <Item>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="staleblue"
                          backgroundColor="#F9FBE7"
                          sx={{ p: 2 }}
                        >
                          {rec.studentName
                            ? rec.studentName
                            : "invalid student name"}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      border="1px solid black"
                      borderLeft="none"
                      sx={{ backgroundColor: "#F9FBE7" }}
                    >
                      <Stack spacing={2} sx={{fontWeight:"bold"}}>
                        <Item
                          sx={{
                            backgroundColor: "#F9FBE7",
                            pb: 1,
                            borderBottom: "2px solid black",
                          }}
                        >
                          {testName}
                        </Item>
                        <Item
                          sx={{
                            backgroundColor: "#F9FBE7",
                            borderBottom: "2px solid black",
                          }}
                        >
                          {standardName} - {DivisionName}
                        </Item>
                        <Item sx={{ backgroundColor: "#F9FBE7" }}>
                          {rec.studentUSN
                            ? rec.studentUSN
                            : "invalid studentUSN"}
                        </Item>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      border="1px solid black"
                      borderLeft="none"
                      backgroundColor="#F9FBE7"
                    >
                      <Avatar
                        alt="Remy Sharp"
                        // src={element.staffProfilePhoto}
                        src={rec.studentProfileUrl}
                        //   id={element.staffID}
                        variant="square"
                        sx={{
                          width: 160,
                          height: 150,
                          // mx: 4,
                          // mt: 1,
                          border: "1px solid skyblue",
                          borderRadius: "5%",
                        }}
                      />
                    </Grid>

                    {Examtimes.map((rex) => (
                      <Grid item xs={2.8} border="1px solid black">
                        <Stack spacing={0.5} sx={{textTransform:"uppercase"}}>
                          <Item sx={{ fontWeight: "bold" }}>{rex.subject}</Item>
                          <Item>
                            <Typography variant="subtitle2">
                              {rex.date}/{rex.day}
                            </Typography>
                          </Item>
                          <Item>
                            <Typography variant="subtitle2">
                              {rex.startTimeOnly[1]} - {rex.endTimeOnly[1]}
                            </Typography>
                          </Item>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>

                  <Typography
                    align="center"
                    backgroundColor="#F9FBE7"
                    fontWeight="bold"
                    sx={{ py: 2 }}
                  >
                    NO ENTRY WILL BE ALLOWED IN THE EXAM HALL WITHOUT THIS HALL
                    TICKET.
                  </Typography>
                </Box>
              ))}
              <Button
                variant="contained"
                size="large"
                color="error"
                onClick={generatePDF}
                sx={{
                  marginLeft: "28%",
                  borderRadius: "30px",
                  width: "120px",
                  marginTop: "5%",
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                PDF
              </Button>
            </Box>
          </Modal>
       </Box>
    </React.Fragment>
  );
}
