import * as React from "react";
import { useState } from 'react';

import Button from "@mui/material/Button";
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";
import { User } from "../../services/User";
import { SchoolMaster } from "../../services/SchoolMaster";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"By clicking Login you agree to the  "}
      <Link color="inherit" href="#">
        Terms and Conditions
      </Link>{" "}
      {"of the use of the Online School Management Software"}
      {"."}
    </Typography>
  );
}



export default function Login() {
  // * "========================Login Route===================================="
  
    const [showPassword, setShowPassword] = useState(false);
  
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

  let navigate = useNavigate();

  const launchPadLogin = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      let response = await User.UserLogin({
        userEmailID: data.get("userEmailID"),
        userPassword: data.get("passWord"),
      });
      if (response.data.success) {
        console.log("success");
        delete response.data.data.user.userPassword;
        response.data.data.user.token = 9272;
        localStorage.setItem(
          "userData",
          JSON.stringify(response.data.data.user)
        );
        let schoolArray;
        try {
          let schoolresp = await SchoolMaster.schoolList();
          schoolArray = schoolresp.data.data;

          const found = schoolArray.find(
            (element) => element.currentAcademicYearStatus == true
          );

          if (found) {
            console.log(found);
            let currentYear = {
              yearID: found.yearID,
              yearName: found.yearName,
            };
            localStorage.setItem("schoolDetails", JSON.stringify(found));
            localStorage.setItem("academicYear", JSON.stringify(currentYear));
          } else {
            // alert("Something went wrong please Logout");
          }
        } catch (error) {
          alert(error.message);
          console.log(error);
        }
        // alert("Successfully Login ");
        if (response.data.data.user.designation == "ADMIN") {
          navigate("/registerschool", { replace: true });
        } else {
          navigate("/launchpad", { replace: true });
        }
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.log("error");
      alert("Failure");
    }
  };


  React.useEffect(() => {
    // Window.resize(720, 720);
  });

 
  // * "========================Login Route===================================="

  return (
    <div>
      {/* <Box
        sx={{
          minHeight: 450,
        }}
      > */}
      <AppBar position="static" color="transparent">
        <Container maxWidth="xl">
          <Toolbar sx={{ height: 120 }} disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Typography
                variant="h3"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                MySchool Book
              </Typography>
              {/* <Tooltip sx={{}} title="Launchpad">
                <IconButton sx={{ p: 0 }}>
                  <Avatar
                    alt="Remy Sharp"
                    src="ms2.jpg"
                    variant="square"
                    sx={{
                      width: 145,
                      height: 80,
                    }}
                  />
                </IconButton>
              </Tooltip> */}
            </Box>

            {/* <Box>
              <Tooltip title="School">
                <Avatar
                  alt="Remy Sharp"
                  src="ms3.jpg"
                  variant="square"
                  sx={{
                    width: 165,
                    height: 80,
                  }}
                />
              </Tooltip>
            </Box> */}
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          minHeight: 427,
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            backgroundColor: "#dcffd1",
            borderColor: "2px solid green",
            borderRadius: "10px",
          }}
        >
          <Box
            component="form"
            onSubmit={launchPadLogin}
            noValidate
            sx={{
              marginTop: 15,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="userEmailID1"
              label="Login ID"
              name="userEmailID"
              // autoComplete="email"
              // defaultValue={"root"}
              // type="email"
              type="email"
              // autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="passWord"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              // onClick={BuildSchoolLogin}
            >
              LAUNCHPAD LOGIN
            </Button>
          </Box>
          <Copyright sx={{ mt: 1, mb: 4 }} />
        </Container>
      </Box>
      <Box sx={{ mt: 2 }}>
        <AppBar position="static">
          <Container maxWidth="md">
            {/* <Toolbar> */}
            <Typography
              variant="body1"
              color="inherit"
              sx={{ textAlign: "center" }}
            >
              All © Copyright 2030 TradeBook || Powered and Designed by
              Technotharanga
            </Typography>
            {/* </Toolbar> */}
          </Container>
        </AppBar>
      </Box>
    </div>
  );
}
