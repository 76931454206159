import {
  Avatar,
  Button,
  gridClasses,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import EditIcon from "@mui/icons-material/Edit";
import { C } from "../../util/C";
import styled from "@emotion/styled";
import { LibraryService } from "../../../services/libraryService";
import { BorderColor } from "@mui/icons-material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { UploadFileService } from "../../../services/FileUploadService";
import { useNavigate } from "react-router-dom";
import { ImageConverter } from "../../../programs/imgaeToBase";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
// import { randomInt, randomUserName } from "@mui/x-data-grid-generator";

const FormItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 180,
  // height: 80
  // color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "80%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 460,
  backgroundColor: "#fff",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styles = {
  position: "absolute",
  top: "80%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 150,
  backgroundColor: "#fff",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Books() {
  const [columns, setColumns] = React.useState([
    {
      field: "id",
      flex: 0.5,
      editable: true,
      headerAlign: "center",
      align: "center",
      headerName: "NO",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "bookTitle",
      flex: 1,
      editable: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      headerName: "BOOK TITLE",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BOOK TITLE"}</strong>
      ),
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bookType",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: true,
      sortable: false,
      headerName: "TYPE",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"TYPE"}</strong>,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
    },
    {
      field: "condition",
      flex: 0.8,
      editable: true,
      sortable: false,
      type: "string",
      headerAlign: "center",
      align: "center",
      headerName: "CONDITION",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"CONDITION"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
    },
    {
      field: "authorApplication",
      flex: 2,
      editable: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      headerName: "AUTHORPUBLICATION",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"AUTHOR/PUBLICATION"}</strong>
      ),
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <p style={{ textTransform: "uppercase" }}>{params.value}</p>
      ),
    },
    {
      field: "image",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      headerName: "Image",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"IMAGE"}</strong>,
      // renderCell: renderDetailsButton,
      // BaseButton: true,
      headerClassName: "super-app-theme--header",

      // disableClickEventBubbling: true,
      renderCell: (rec, value) => {
        console.log(rec);
        // setRowsData(rec);
        return (
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box>
              <FileUploadIcon
                fontSize="large"
                onClick={() => {
                  handleOpen();
                  rowDataSet(rec);
                }}
              />
            </Box>
          </Box>
        );
      },
    },
  ]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setImageUrl("");
  };
  const [rowsData, setRowsData] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [imageUrl, setImageUrl] = React.useState("");
  const [imageSrc, setImageSrc] = React.useState();
  const [openSucess, setOpenSucess] = React.useState(false);

  const rowDataSet = (rec) => {
    setRowsData(rec);
    setImageUrl(rec.row.imageURL);
  };

  const [sizeModalOpen, setSizeModalOpen] = React.useState(false);
  const [typeModalOpen, setTypeModalOpen] = React.useState(false);
  
  const handleCloseSizeModal = () => setSizeModalOpen(false);
  const handleCloseTypeModal = () => setTypeModalOpen(false);

  const fileBrowseHandler = async (e) => {
    const file = e.target.files[0];
    e.target.value = null;
    if (file.size > 2 * 1024 * 1024) {
      setSizeModalOpen(true); 
      return;
    }
   const validFormats = ['image/jpeg', 'image/png'];
    if (!validFormats.includes(file.type)) {
      setTypeModalOpen(true); 
      return;
    }
    const base64 = await ImageConverter.convertToBase64(file);
    // setPostImage({ ...postImage, myFile: base64 });
    console.log(base64);
    setImageUrl(base64);

    const found = rows.find((element) => element.id == rowsData.id);

    if (found) {
      console.log(found);
      found.image = base64;
      found.imageURL = base64;
    }
    setTimeout(() => {
      handleClose();
    }, 2000);
    C.c(rows);
  };

  React.useEffect(() => {
    // onBookLoad();
  }, []);

  const onBookLoad = async () => {
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await LibraryService.listAll();
      console.log(response.data.data);

      // setBook(response.data.data);
      let book_array = [];
      response.data.data.forEach(function (rec) {
        console.log(rec);
        book_array.push({
          id: rec.libraryID,
          no: rec.libraryID,
          bookNumber: rec.bookNumber,
          bookTitle: rec.bookTitle,
          bookType: rec.bookType,
          authorApplication: rec.bookAuthorPublication,
          condition: rec.bookCondition,
          status: "existing",
          // libraryID: rec.id,
        });
      });
      setRows(book_array);
      console.log(rows);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  const createRandomRow = () => {
    return {
      id: rows.length + 1,
      status: "new",
    };
  };

  const handleAddRow = () => {
    setRows((prevRows) => [...prevRows, createRandomRow()]);
  };

  const onRowC = (rec, v) => {
    console.log(rec);
    const found = rows.find((element) => element.id == rec.id);

    if (found.status === "new") {
      if (rec.field === "bookTitle") {
        found.bookTitle = rec.value;
      }

      if (rec.field === "bookType") {
        found.bookType = rec.value;
      }

      if (rec.field === "condition") {
        found.condition = rec.value;
      }

      if (rec.field === "authorApplication") {
        found.authorApplication = rec.value;
      }
    }
    if (found.status === "existing") {
      if (rec.field === "bookTitle") {
        found.bookTitle = rec.value;
        found.status = "new";
      }

      if (rec.field === "bookType") {
        found.bookType = rec.value;
        found.status = "new";
      }

      if (rec.field === "condition") {
        found.condition = rec.value;
        found.status = "new";
      }

      if (rec.field === "authorApplication") {
        found.authorApplication = rec.value;
        found.status = "new";
      }
    }
  };
  const onAddRoW = () => {
    let filter = rows.filter((item) => item.status === "new");
    console.log(filter);

    let bookobj = [];
    filter.forEach((rec) => {
      bookobj.push({
        bookAuthorPublication: rec.authorApplication.toUpperCase(),
        bookCondition: rec.condition.toUpperCase(),
        bookNumber: rec.bookNumber ? rec.bookNumber.toUpperCase() : "",
        bookTitle: rec.bookTitle.toUpperCase(),
        bookType: rec.bookType.toUpperCase(),
        bookImageCoverPath: rec.image,
      });
    });
    console.log(bookobj);
    // debugger;
    // setTimeout(() => {
    saveBook(bookobj);
    // }, 2000);
  };

  const saveBook = (book) => {
    try {
      book.forEach(async (rec) => {
        let response = await LibraryService.createLibraryBook(rec);
        console.log(response.data.data);
        setRows([]);
      });
      setOpenSucess(true);
      setTimeout(()=> {
        setOpenSucess(false);
      },1500);
    } catch (error) {
      console.log(error);
    }
  };

  let navigate = useNavigate();
  const onLaunchPad = () => {
    console.log("launchpad");
    navigate("/launchpad", { replace: true });
  };

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="library" /> */}
      <Box sx={{ width: "100%", padding: "0 10px", boxSizing: "border-box" }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            mt: 2,
            fontWeight: "Bold",
            backgroundColor: "lightblue",
          }}
        >
          LIBRARY BOOKS ENTRYS
        </Typography>
        {/* <img src={imageUrl} alt="iron" /> */}
      </Box>

      <Box
        sx={{
          width: "100%",
          mt: 2,
          padding: "0px 10px",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            height: 420,
            width: "100%",
            "& .super-app-theme--header": {
              backgroundColor: "#c0c0c0",
              //  color:'white',
              fontWeight: "bold",
              fontSize: "15px",
              border: "1px solid white",
            },
            overflowX: "auto",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            onCellEditCommit={onRowC}
            rowHeight={50}
            pageSize={100}
            sx={{minWidth:1000}}
            rowsPerPageOptions={[15]}
            disableColumnMenu
            // onRowEditStop={onRowS}
          />
        </Box>
        <Box sx={{display:"flex", justifyContent:"space-evenly",mt:2, paddingBottom:"25px"  }}>
          <Button
            color="success"
            variant="contained"
            onClick={handleAddRow}
            sx={{ width: "120px", borderRadius: "30px", fontWeight: "bold" }}
          >
            Add a row
          </Button>
          <Button
            sx={{
              backgroundColor: "#ca424f",
              width: "120px",
              borderRadius: "30px",
              fontWeight: "bold",
              fontSize: 15,
              "&:hover": { backgroundColor: "#ca424f" },
            }}
            variant="contained"
            onClick={onLaunchPad}
          >
            EXIT
          </Button>
          <Button
            variant="contained"
            onClick={onAddRoW}
            sx={{
              borderRadius: "30px",
              width: "130px",
              fontWeight: "bold",
              fontSize: 15,
            }}
          >
            Save Book
          </Button>
        </Box>
      </Box>
      <Box>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
            // sx={{
            //   border: "2px solid black",
            //   borderRadius: 5,
            //   mt: 3,
            //   backgroundColor: "#f9fbe7",
            //   width: "162px",
            //   height: "166px",
            // }}
            >
              <Avatar
                alt="A"
                src={imageUrl}
                sx={{ width: 400, height: 400, margin: 1, mt: 0.5, ml: 0 }}
                variant="square"
              />
              <input
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                accept="image/*"
                name="studentImg"
                onChange={fileBrowseHandler}
              />
              <Modal
                  open={sizeModalOpen}
                  onClose={handleCloseSizeModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styles}>
                    <Typography variant="h6" textAlign="center">
                      <b>The file size exceeds the 2MB limit. Please upload a file less than 2MB.</b>
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }}>
                      <Button color="success" size="small" variant="contained" onClick={handleCloseSizeModal}>
                        Close
                      </Button>
                    </Box>
                  </Box>
                </Modal>
                <Modal
                  open={typeModalOpen}
                  onClose={handleCloseTypeModal}
                  aria-labelledby="modal-type-title"
                  aria-describedby="modal-type-description"
                >
                  <Box sx={styles}>
                    <Typography variant="h6" textAlign="center">
                      <b>The file must be in JPEG or PNG format. Please upload a valid file.</b>
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }}>
                      <Button color="success" size="small" variant="contained" onClick={handleCloseTypeModal}>
                        Close
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              {/* ----------------------------------STAFF IMAGE--------------------------- */}
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  component="span"
                  sx={{
                    marginTop: 1,
                    borderRadius: 9,
                    border: "2px solid black",
                    fontWeight: 700,
                    color: "black",
                    backgroundColor: "white",
                  }}
                >
                  UPLOAD IMAGE
                </Button>
              </label>
            </Box>
          </Box>
        </Modal>


        <Modal
            open={openSucess}
            // onClose={() => setSucess(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div sx={style}>
              <SucessModal
                text="Books Saved Successfully"
                name="Books Saved Successfully"
              />
            </div>
          </Modal>

      </Box>
    </React.Fragment>
  );
}
