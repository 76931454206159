import React from "react";
import Box from "@mui/material/Box";
import { ButtonGroup, Divider, Grid, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { flexbox } from "@mui/system";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import { C } from "../../util/C";
import { styled } from "@mui/material/styles";
import { AddStandarsService } from "../../../services/addStandardsService";
import { AfterFinalExam } from "../../../services/AfterFinalExam";
import { useNavigate } from "react-router-dom";

const Item1 = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  // fontWeight: "bold",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color:"white"
  },
}));

const FormItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  height: 200,
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));

function GrantAndReject() {
  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  const columns1 = [
    { field: "id", headerName: "NO.",
    headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"NO."}</strong>,
    headerClassName: "super-app-theme--header",
    flex:0.4 ,
    editable:false,
    sortable: false
  },

    {
      field: "IssuedFormNo",
      headerName: "FORM NO.",
      flex:0.8,
      headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"FORM NO."}</strong>,
    headerClassName: "super-app-theme--header",
    editable:false,
    sortable: false
    },

    {
      field: "ChildName",
      headerName: "CHILD'S NAME",
      flex: 1.2,
      headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"CHILD'S NAME"}</strong>,
    headerClassName: "super-app-theme--header",
    editable:false,
    sortable: false
    },

    {
      field: "Gender",
      headerName: "GENDER",
      flex:0.8,
      headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"GENDER"}</strong>,
    headerClassName: "super-app-theme--header",
    editable:false,
    sortable: false
    },

    {
      field: "DateofBirth",
      headerName: "DATE OF BIRTH",
      flex:1,
      headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"DATE OF BIRTH"}</strong>,
    headerClassName: "super-app-theme--header",
    editable:false,
    sortable: false
    },

    {
      field: "Age",
      headerName: "AGE",
    flex:0.6,
      headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"AGE"}</strong>,
    headerClassName: "super-app-theme--header",
    editable:false,
    sortable: false
    },

    {
      field: "FatherName",
      headerName: "FATHER'S NAME",
      flex:1,
      headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"FATHER'S NAME"}</strong>,
    headerClassName: "super-app-theme--header",
    editable:false,
    sortable: false
    },
    {
      field: "MotherName",
      headerName: "MOTHER'S NAME",
     flex:1,
      headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"MOTHER'S NAME"}</strong>,
    headerClassName: "super-app-theme--header",
    editable:false,
    sortable: false
    },
    {
      field: "ContactNo",
      headerName: "CONTACT NO.",
     flex:1,
      headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"CONTACT NO."}</strong>,
    headerClassName: "super-app-theme--header",
    editable:false,
    sortable: false
    },

  
    {
      field: "Admission",
      headerName: "ADMISSION",
      // textAlign:"center",
      headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"ADMISSION"}</strong>,
    headerClassName: "super-app-theme--header",
      renderCell:(params)=>{
        return(
        <Box>
          <Button
          size="small"
          variant="contained"
          color="error"
          value="NO"
          onClick={()=> admission(params.row.id, "NO")}
          >
            NO
          </Button>
          <Button
          size="small"
          variant="contained"
          color="success"
          value="YES"
          onClick={()=> admission(params.row.id, "YES")}
          >
            YES
          </Button>
          </Box>
        )
      },
  flex:1,
  editable:false,
  sortable: false
    },

    {
      field: "admissionStatus",
      headerName: "ADMISSION STATUS",
  flex:1,
      headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"ADMISSION STATUS"}</strong>,
    headerClassName: "super-app-theme--header",
    editable:false,
    sortable: false
   
    },
    // {
    //   field: "USID (Unique School  Identity",
    //   headerName: "USID (Unique  School  Identity)",
    //   width: 105,
    //   headerAlign: "center",
    // align: "center",
    // renderHeader: () => <strong style={{fontSize:15}}>{"USID"}</strong>,
    // headerClassName: "super-app-theme--header",
    // },
  ];

  const columns2 = [
    { field: "id", headerName: "Class Strength",flex: 1, 
    headerAlign: "center",
    align: "center",
    renderHeader: () => <strong style={{fontSize:15}}>{"Class Strength"}</strong>,
    headerClassName: "super-app-theme--header",
  },
    {
      field: "New Forms",
      flex: 1,
      headerName: "New Forms Issues",
      headerAlign: "center",
    align: "center",
      editable: true,
      renderHeader: () => <strong style={{fontSize:15}}>{"New Forms Issues"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "New Admission Status",
      flex: 1,
      headerName: "New Admission Status",
      headerAlign: "center",
    align: "center",
      editable: true,
      renderHeader: () => <strong style={{fontSize:15}}>{"New Admission Status"}</strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Gender Status",
      flex: 1,
      headerName: "Gender Wise Status",
      headerAlign: "center",
    align: "center",
      editable: true,
      renderHeader: () => <strong style={{fontSize:15}}>{"Gender Wise Status"}</strong>,
      headerClassName: "super-app-theme--header",
    },
  ];

  function createData(classStrenght, formIssues, newAdmission) {
    return { classStrenght, formIssues, newAdmission };
  }

  const [rows, setRows] = React.useState([]);
  const [rows1, setRows1] = React.useState([]);

  let navigate = useNavigate();
  const grantAndReject = () => {
    setClicked1(true);

    console.log("grantAndReject");
    navigate("/grantandreject", { replace: true });
  };

  const requestForTc = () => {
    setClicked2(true);
    console.log("requestForTc");
    navigate("/acceptrequestfortc", { replace: true });
  };

  const fillNewForm = () => {
    setClicked(true);
    console.log("fillNewForm");
    navigate("/newadmission", { replace: true });
  };

  const [Standardname, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [Standard, setStandard] = React.useState([]);

  const [clicked, setClicked] = React.useState(false);
  const [clicked1, setClicked1] = React.useState(false);
  const [clicked2, setClicked2] = React.useState(false);
  const [success,setSuccess] = React.useState(false);
  // const[Rows,setRows] = React.useState([]);


  const onstandardsLoad = async (e) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    C.c(data);
    // let academicYear = "2024-2025";
    try {
      let response = await AfterFinalExam.getStandaredApplicationFormCount(
        data.yearName
      );

      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };


  const onStandardSelect = async (e, index) => {
    e.preventDefault();

    setStandardID(e.target.id);
    C.c(e.currentTarget.id);
    console.log(e.target.textContent);
    setStandardName(e.target.textContent);
    console.log(Standardname);

    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "isSelected"
    ]
      ? false
      : true;


    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let StudentObj = [];
      let response = await AfterFinalExam.getStandaredStudentInfornatation(
        e.target.textContent
      );
      console.log(response.data.data);
      StudentObj = response.data.data;
      console.log(StudentObj);

      let studentArr = [];
      StudentObj.forEach(function (rec,i) {
        studentArr.push({
          // id: rec.studentId,
          id: i+1,
          IssuedFormNo: rec.admissionFormNumber,
          ChildName: rec.childName,
          Gender: rec.gender,
          DateofBirth: rec.dob,
          Age: rec.age,
          FatherName: rec.fatherName,
          MotherName: rec.motherName,
          ContactNo: rec.fatherContactNumber,
        });
      });
      setRows(studentArr);
      console.log(studentArr);
      console.log(rows);
    } catch (error) {
      console.log(error);
    }
  };

  const [admit, setAdmit] = React.useState();
const admission=(id, value)=> {
  // setAdmit(e.target.value);
  const approve = rows.map((row)=>
  row.id === id ? {...row, admissionStatus: value} : row
  );
  setRows(approve);
}


//   const [searchTerm, setSearchTerm] = React.useState();
// const [filter, setFilter] = React.useState([rows]);
// const [searchTerm, setSearchTerm] = React.useState('');
// console.log(filter);

// const onSearch = (e) => {
//   const term = e.target.value;
//   console.log(term);
//   setSearchTerm(term);

//   const filtered = rows.filter((row)=>
  
//   row.fatherContactNumber.includes(term) || row.admissionFormNumber.includes(term)
//   );
//   setFilter(filtered);
// }


  // let navigate = useNavigate();
  const onLaunchPad = () => {
    console.log("launchpad")
    navigate("/launchpad", {replace: true})
  }

  return (
    <div>
      {/* <LaunchpadTopBar id="afterfinalexam" /> */}

      {/* <Grid container sx={{mt:1}}> */}

            {/* <Grid item xs={3.5} sx={{ml:14}}>
              <Button
              fullWidth
                size="small"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  variant: "outlined",
                  border: "1px solid black",
                  borderRadius: "1px",
                  // width: "555px",
                  ":hover": {
                    backgroundColor: "#1565c0",
                    color: "white",
                    boxShadow: "5px 5px 5px  black",
                  },
                  backgroundColor: clicked ? "yellow" : "white",
                }}
                onClick={fillNewForm}
              >
                FILL NEW ADMISSION FORM FOR THE NEXT ACADEMIC YEAR{" "}

              </Button>
              </Grid> */}
              {/* ----------------------------------------------GRANT / REJECT--------------------------------------------------------*/}
              {/* <Grid xs={3.5} >
              <Button
              fullWidth
                size="small"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  variant: "outlined",
                  border: "1px solid black",
                  borderRadius: "1px",
                  // width: "410px",
                  ":hover": {
                    backgroundColor: "#1565c0",
                    color: "white",
                    boxShadow: "5px 5px 5px  black",
                  },
                  backgroundColor: clicked1 ? "yellow" : "white",
                }}
                onClick={grantAndReject}
              >
                GRANT / REJECT ADMISSION FOR NEXT ACADEMIC YEAR{" "}
              </Button>
              </Grid> */}

              {/*--------------------------------------------------------- ACCEPT REQUEST FOR TC AND LC ------------------------------------------*/}
              {/* <Grid xs={3}>
              <Button
              fullWidth
                size="small"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  variant: "outlined",
                  border: "1px solid black",
                  borderRadius: "1px",
                  // width: "270px",
                  ":hover": {
                    backgroundColor: "#1565c0 ",
                    color: "white",
                    boxShadow: "5px 5px 5px  black",
                  },
                  backgroundColor: clicked2 ? "yellow" : "white",
                }}
                onClick={requestForTc}
              >
                ACCEPT REQUEST FOR TC AND LC{" "}
              </Button>
              </Grid> */}
            {/* </ButtonGroup> */}
          {/* </Grid> */}
      <Grid container spacing={2} sx={{mt:1}}>
        <Grid item md={7} xs={7} >
          <Item1
                >
            <Grid container spacing={1} sx={{ minHeight: 40 ,padding:"10px"}} >
              {Standard.map((element,index) => (
                <Grid item md={2}xs={4} sx={{ cursor: "pointer" }}>
                <ItemS
                  id={element.standardID}
                  value={element.standardName}
                  onClick={(e) => {
                    onStandardSelect(e, index)
                  }}
                  className={element.isSelected ? "Selected" : ""}
                  sx={{ fontWeight: "bold" }}
                >
                  {element.standardName}
                </ItemS>
              </Grid>
              ))}
            </Grid>
          </Item1>
        </Grid>
        <Grid item md={5} xs={5}>
          <Box
            sx={{
              // mt:1,
              height: 250,
              // ml: 5, 
              mr:1 ,
              "& .super-app-theme--header": {
                backgroundColor: "#c0c0c0",
                //  color:'white',
                fontWeight: "bold",
                fontSize: "15px",
                border: "1px solid white",
              },
            }}
          >
            {/* <Box sx={{ height: 270 }}> */}
              <DataGrid
              // sx={{border:"1px solid blue"}}
                rows={rows1}
                columns={columns2}
                rowsPerPageOptions={[7]}
                disableColumnMenu
              />
            {/* </Box> */}
          </Box>
        </Grid>
      </Grid>

      <Box
        // width="50%"
        sx={{
          width:"100%",
          textAlign: "center",
          my: 1,
          // height: "50px",
          mt: 2,   
          paddingTop:"0.5px",
          paddingBottom:"10px",
          backgroundColor: "lightblue"   
        }}
      >
        <Typography
          // variant="body1"
          sx={{mt:1, fontWeight:"bold",textDecorationLine:'underline'}}
        >
          ENTER FORM NO. OR COMPLETE PHONE NUMBER TO SEARCH A STUDENT
        </Typography>
        
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent:"center",
          // mt:2,
          height: 270,
          ml: 1, 
          mr:1 ,
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
        }}
      >
        {/* <Box sx={{ height: 270, width: "85%",ml:10}}> */}
          <DataGrid
            rows={rows}
            columns={columns1}
            pageSize={10}
            rowsPerPageOptions={[7]}
            // sx={{ border: "1px solid black" }}
            disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableDensitySelector
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            printOptions: { disableToolbarButton: true },
            csvOptions: { disableToolbarButton: true },
          },
        }}
          />
        {/* </Box> */}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" ,mr:2,mt:1}} paddingBottom={"25px"}>
      <Button
          variant="contained"
          sx={{
            backgroundColor:"#ca424f",
            borderRadius: "30px",
            fontSize: 15,
            fontWeight: "bold",
            width:120,
            "&:hover":{
              backgroundColor: "#ca424f",
            }
          }}
          onClick={onLaunchPad}
        >
          EXIT
        </Button>
      </Box>
    </div>
  );
}

export default GrantAndReject;
