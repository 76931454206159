import * as React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { styled } from "@mui/material/styles";
// import styled from "@emotion/styled";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import { BuildSchoolExam } from "../../../services/examStandards";
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";
import { Report } from "../../../services/ReportService";
import { C } from "../../util/C";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { ContactSupportOutlined } from "@mui/icons-material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";

// const Item = styled(Paper)(({ theme }) => ({
//   // padding: theme.spacing(1),
//   textAlign: "center",
//   border: "1px solid black",
// }));
const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "2px",
  width:"100%",
  // color: theme.palette.text.secondary
}));
const Item1 = styled(Paper)(({ theme }) => ({
 // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "2px",
  width:"100%",
  border:'2px solid black',
  borderRadius:'2px'
  // color: theme.palette.text.secondary
}));

const ItemBg = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));

const ItemE = styled(Paper)(({ theme }) => ({
  // padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  height:30,
  verticalAlign:"middle",
  background: "#5485e594",
  "&:hover": {
    background: "#3661b494",
  },
}));

export default function ExamResults() {
  const [Standard, setStandard] = React.useState([]);
  const [studUSN, setStudUSN] = React.useState([]);
  const [Division, setDivision] = React.useState([]);
  const [studentInfo, updateStudentInfo] = React.useState([]);
  const [standardTest, updateStandardTest] = React.useState([]);
  const [testName, setTestName] = React.useState([]);
  const [result, setResult] = React.useState([]);
  const [percentage, setPercentage] = React.useState();
  const [excel, setExcel] = React.useState([]);
  React.useEffect(() => {
    studentDetailsLoad();
  }, []);

  const studentDetailsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let studentUSN = localStorage.getItem("studentUSN");
    try {
      let response = await StudentInfoService.studentInfoShowStudentDetails(
        studentUSN,
        data.yearName
      );
      console.log(response.data.data);
      updateStudentInfo(response.data.data);
      setStandard(response.data.data.standardName);
      setStudUSN(response.data.data.studentUSN);
      testLoad(response.data.data.standardID);
      setDivision(response.data.data.divisionName);
      
      
    } catch (error) {
      console.log(error);
    }
  };

  const testLoad = async (id)=> {
    let year = JSON.parse(localStorage.getItem("academicYear"))
try {
  let response = await BuildSchoolExam.showBuildSchoolStandardTest(
    id,
    year.yearName
    );
    console.log(response.data.data);
    response.data.data.sort(function (a, b) {
      return a.testOrder - b.testOrder;
    });
    updateStandardTest(response.data.data);
    // resultLoad(response.data.data.testTitleName);
} catch(error) {
  C.c(error.message);
}
  }

  const onTestSelect = async(e) => {
    console.log(e.currentTarget.textContent);
    setTestName(e.currentTarget.textContent);
    let studentUSN = localStorage.getItem("studentUSN");
    console.log(studentUSN);
      let data =     JSON.parse(localStorage.getItem("academicYear"));
      try {
            // let response = await Report.examResult(
            //   Division,
            //   Standard,
            //   e.target.textContent,
            //   studUSN,
            //   data.yearName
            // );
            let response = await axios.get(`http://139.59.11.130:6561/api/marks/${studentInfo.studentUSN}/${e.currentTarget.textContent}/${data.yearName}`)
            console.log(response.data.studentResult);
            setResult(response.data.studentResult);

            let res=[];
            response.data.studentResult.forEach((rec,i)=> (
              res.push({
                NO: i+1,
                SUBJECT: rec.subjectName,
                MAX: rec.subjectMaxMarks,
                MIN: rec.subjectMinMarks,
                MARKS: rec.markSheet,
                GRADE: rec.grade
              })
            ))
            setExcel(res)
            const grandTotal = response.data.studentResult.reduce((rec, total) => rec + total.subjectMaxMarks , 0);

const totalMarks = response.data.studentResult.reduce((rec,total) => rec + total.markSheet, 0);
console.log(totalMarks);
console.log(grandTotal);
const totalPercentage = (totalMarks/grandTotal)* 100;
setPercentage(totalPercentage.toFixed(2));
console.log(totalPercentage);
          } catch(error) {
            
            console.log(error);
          }
  }
  // const resultLoad = async(div,std,tes) => {
  //   let studentUSN = localStorage.getItem("studentUSN");
  //   let data = JSON.parse(localStorage.getItem("academicYear"))
  //   try {
  //     let response = await Report.examResult(
  //       div,
  //       std,
  //       tes,
  //       studentUSN,
  //       data.yearName
  //     );
  //     console.log(response.data.data);
  //   } catch(error) {
  //     console.log(error);
  //   }
  // }
  const school = JSON.parse(localStorage.getItem("schoolDetails"));
  const academic = JSON.parse(localStorage.getItem("academicYear"));

  const genaratePDF = () => {
      const doc = new jsPDF("landscape");
  
      const sch = school.schoolNameOnDocs.toUpperCase();
      const acad = academic.yearName;
      const img = school.schoolLogoUrl;
      doc.setFont("helvetica", "bold");
      var fontSize = 18;
      var text = `${sch}`;
      var pageWidth = doc.internal.pageSize.getWidth();
      var textWidth =
        (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
      var centerX = (pageWidth - textWidth) / 2;
      doc.text(text, centerX, 15);
  
      doc.addImage(img, "JPEG", 15, 5, 27, 27);
      doc.setFontSize(10);
      doc.text(` ${acad}`, 250, 15);
  
  
  
      doc.setFontSize(15);
      doc.setFont("helvetica", "bold");
      doc.text("INDIVIDUAL MARKS REPORT", 90, 30);
  
      doc.setFontSize(14);
      doc.text(studentInfo.studentName + "--" + studentInfo.studentUSN, 110, 48);
      doc.addImage(studentInfo.studentProfileUrl, 'JPEG', 220, 25, 30, 36);
      // doc.text(studentInfo.studentUSN, 100,150);
      // doc.text(studentInfo.standardName + "-"+ studentInfo.divisionName, 150,150);
      // doc.text(studentInfo.studentMedicalAttentionDetail,240,150);
      // doc.text(studentInfo.studentBloodGroup,330,150);
      doc.autoTable({
        startY: 60,
        head: [["STD-DIV : " + studentInfo.standardName + "-" + studentInfo.divisionName,
        "MED :" + studentInfo.studentMedicalAttentionDetail,
        "BG :" + studentInfo.studentBloodGroup,
        "DOB :" + studentInfo.studentDOB, "AGE :" + studentInfo.studentAge
        ]],
        headStyles: {
          fillColor: "white",
          textColor: 0,
          fontSize: 12
        },
        theme: "grid",
      });
  
      const tableData = result.map((row,i) => [
        i+1, row.subjectName, row.subjectMaxMarks, row.subjectMinMarks,
        row.markSheet, row.grade
      ]);
  
      doc.autoTable({
        startY: 80,
        tableLineColor: [0, 0, 0],
        head: [["NO", "SUBJECTS", "MAX", "MIN", "MARKS", "GRADE"]],
        body: tableData,
        headStyles: {
          valign: "middle",
          halign: "center",
        },
        bodyStyles: {
          valign: "middle",
          halign: "center",
          lineColor: [0, 0, 0],
        },
        tableLineWidth: 0.4,
        theme: "grid",
      });

      doc.setLineWidth(0.9);
doc.setFillColor(192,192,192);
doc.roundedRect(100,150,70,23,1,1,'FD');
doc.text(`Gross Percentage : ${percentage}`+ " %" , 110, 160);

      doc.save(studentInfo.standardName + "-" + studentInfo.divisionName + "--" + studentInfo.studentUSN + "Marks Report.pdf");
  
    }

    const fileType = "csv";
      const exportToCSV = () => {
        const visible = XLSX.utils.json_to_sheet(excel);
        const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        filesaver.saveAs(data, studentInfo.studentUSN + "-" + testName + "--"  + "Marks Report.csv");
      };
  
  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="studentinfo" /> */}
      <Box sx={{ flexGrow: 1,mt:2 }}>
        
      <Box sx={{ width: "100%", paddingLeft: "30px", boxSizing: "border-box", mt: 2}}>
          <Grid container>
            <Grid item md={10} xs={8}>
              <Box
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}


              >
                <Stack direction="column" sx={{ width: "100%",  boxSizing: "border-box", mt: 2 }}>
                  <Item >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      STUDENT NAME
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      {studentInfo ? studentInfo.studentName : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
              <Box
                sx={{ width: "100%", boxSizing: "border-box", my: "1%" }}
              >
                <Grid  container spacing={{ xs: 1, sm: 1, md: 2 }} sx={{ width: "100%", boxSizing: "border-box", mt: 2 }}>
                  <Grid item md={1} xs={3} >
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        USN
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentUSN : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        STANDARD | DIVISION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.standardName +
                          " | " +
                          studentInfo.divisionID
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={1} xs={3}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        CLASS TR
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {"--"}
                        {/* {studentInfo ? studentInfo.classteacher : "--"} */}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        MEDICAL ATTENTION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.studentMedicalAttentionDetail
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        BLOOD GROUP
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentBloodGroup : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        DOB
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentDOB : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  {/* <Typography
              variant="body1"
              width="250px"
              sx={{ textAlign: "center" }}
            >
              {studentInfo.studentAge + " Age"}
            </Typography> */}
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item>
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        AGE
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentAge : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={2} xs={4}>
              <Box display={"flex"} justifyContent={"center"}  sx={{mt:"2%"}}>
                <Avatar
                  alt="Remy Sharp"
                  src={studentInfo ? studentInfo.studentProfileUrl : ""}
                  //   id={element.staffID}
                  variant="square"
                  sx={{
                    width: 150,
                    height: 190,
                    mx: 2,
                    // mt: 1,
                    border: "3px solid skyblue",
                    borderRadius: "5%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
      </Box>
       
      <Grid
        container
        spacing={1}
        sx={{ width: "100%", padding: "0 10px", boxSizing: "border-box", display:"flex", justifyContent:"center" }}
      >
        <Grid item md={9} xs={8}>
          <ItemBg>
            <Grid container spacing={1} sx={{ minHeight: 40 }}>
              {standardTest.map((element, index) => (
                <Grid item md={2} xs={6} sx={{ cursor: "pointer" }}>
                  <ItemE
                    sx={{ fontWeight: "bold", paddingTop: "5px" }}
                    id={element.standardTestID}
                    
                    onClick={(e) => {
                      onTestSelect(e, index);
                      setTestName(e.currentTarget.textContent);
                    }}
                    className={element.isSelected ? "selectedTest" : ""}
                  >
                    {element.testTitleName.toUpperCase()}
                  </ItemE>
                </Grid>
              ))}
            </Grid>
          </ItemBg>
        </Grid>
      </Grid>

        {/* <Grid  container spacing={{ xs: 1, sm: 1, md: 2 }} sx={{ width: "100%", boxSizing: "border-box", mt: 2 }}> */}
                <Box sx={{ boxSizing:'border-box', display:'flex',mt:2,justifyContent:'center'}}>
                 {result.map((element) => (
                   <Grid item  xs={3} m={1}>
                   {/* STAFF DESIGNATION */}
                   <Item1 sx={{width:140,}}>
                     <Typography
                       variant="h6"
                       // gutterBottom
                       sx={{
                         fontWeight: "bold",
                         fontSize: "15px",
                         backgroundColor: "lightgray",
                         alignItems:'center',
                         textAlign: 'center',
                         alignContent:"center",
                         height:60
                       }}
                     >
                       {element.subjectName}
                     </Typography>
                    <Stack direction="row">
                    <Typography
                       variant="subtitle1"
                       gutterBottom
                       sx={{
                         textAlign: "center",
                         fontWeight: "bold",
                         padding:"3px 0px",
                         width:"100%",
                         backgroundColor: "green",
                         color:'white'
                         // fontSize: "18px",
                       }}
                     >
                       {element.subjectMaxMarks}
                     </Typography>
                     <Typography
                       variant="subtitle1"
                       gutterBottom
                       sx={{
                         textAlign: "center",
                         fontWeight: "bold",
                         padding:"3px 0px",
                         width:"100%",
                         backgroundColor: "orange",
                         color:'white'
                         // fontSize: "18px",
                       }}
                     >
                       {element.subjectMinMarks}
                     </Typography>
                    </Stack>
                    <Typography
                       variant="subtitle1"
                       gutterBottom
                       sx={{
                         textAlign: "center",
                         fontWeight: "bold",
                         padding:"3px 0px",
                         width:"100%",
                         backgroundColor:'skyblue'
                         // fontSize: "18px",
                       }}
                     >
                       {element.markSheet}
                     </Typography>
                     <Typography
                       variant="subtitle1"
                       gutterBottom
                       sx={{
                         textAlign: "center",
                         fontWeight: "bold",
                         padding:"3px 0px",
                         width:"100%",
                         backgroundColor:'lightblue'
                         // fontSize: "18px",
                       }}
                     >
                       {element.grade}
                     </Typography>
                     <Typography
                       variant="subtitle1"
                       gutterBottom
                       sx={{
                         textAlign: "center",
                         fontWeight: "bold",
                         padding:"3px 0px",
                         width:"100%",
                         backgroundColor:'darkgreen',
                         color:'white'
                         // fontSize: "18px",
                       }}
                     >
                       {element.percentage+" %"}
                     </Typography>
                     <Typography
                       variant="subtitle1"
                       gutterBottom
                       sx={{
                         textAlign: "center",
                         fontWeight: "bold",
                         padding:"3px 0px",
                         width:"100%",
                         backgroundColor: element.result == "PASS" ? "green" : "red",
                         color:'white'
                         // fontSize: "18px",
                       }}
                     >
                       {element.result}
                     </Typography>
                   </Item1>
                 </Grid>
                 ))}   
               
                </Box>
                {/* </Grid>  */}

                <Box sx={{display:'flex', justifyContent:'center'}}>
                <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            border: "2px solid #39335e",
            borderRadius: "5px",
            backgroundColor: "#39335e",
            color: "white",
            fontWeight:'bold',
            width:'fit-content'
          }}
        >
     GROSS PERCENTAGE : {percentage == undefined || null ? "---" : percentage+" %"}
        </Typography>
                </Box>
       
                <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 2 }}>
        <Button
          variant="contained"
          size="large"
          color="error"
          onClick={genaratePDF}
          sx={{
            borderRadius: "30px",
            width: "140px",
            marginBottom: 2,
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          PDF
        </Button>
        <Button
          variant="contained"
          size="large"
          color="success"
          onClick={exportToCSV}
          sx={{
            borderRadius: "30px",
            width: "140px",
            marginBottom: 2,
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          excel
        </Button>

        <Button
          variant="contained"
          size="large"
          onClick={() => { window.print() }}
          sx={{
            borderRadius: "30px",
            backgroundColor: "black",
            color: "white",
            width: "140px",
            marginBottom: 2,
            fontSize: 15,
            fontWeight: "bold",
            "&:hover": { backgroundColor: "black", color: "white" }
          }}
        >
          print
        </Button>

        <Button
          variant="contained"
          size="large"
          // onClick={onLaunchPad}
          sx={{
            borderRadius: "30px",
            backgroundColor: "#ca424f",
            color: "white",
            width: "140px",
            marginBottom: 2,
            fontSize: 15,
            fontWeight: "bold",
            "&:hover": { backgroundColor: "#ca424f", color: "white" }
          }}
        >
          exit
        </Button>
      </Box>

      </Box>
    </React.Fragment>
  );
}
