import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Typography, IconButton, Stack, Modal } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import { Checkbox } from "@mui/material";
import { yearService } from "../../../services/yearService";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardSubjectService } from "../../../services/StandardSubject";
import { BuildSchoolExam } from "../../../services/examStandards";
import { useNavigate } from "react-router-dom"; //for browser back button navigation handling
import Topbar from "../../MainTopbar/Topbar";
import SucessModal from "./popup/sucessPopUp";
import AlertModal from "./popup/alertPopUp";
import DeleteModal from "./popup/deletePopUp";
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  background: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 400,
  // width: "100%",
  border: "2px solid yellow",
  color: theme.palette.text.secondary,
  overflowY: "scroll",
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

export default function Exam() {
  //browser back button redirection to dashboard
  const Navigate = useNavigate();
  const [finishStatus, setfinishStatus] = React.useState(false);
  const textInput = React.useRef("");

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back to Dashboard?")) {
        setfinishStatus(true);
        // your logic
        Navigate("/dashborad");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  React.useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  // back button logic ends

  React.useEffect(() => {
    // onYearLoad();
    onStandardLoad();
    onTestLoad();
    onLoadBuildSchool();
    // onSubjectLoad();
    // onStandardSubjectLoad();
  }, []);

  const [six, setSix] = React.useState();
  const [sixId, setSixId] = React.useState();
  const [seven, setSeven] = React.useState();
  const [sevenId, setSevenId] = React.useState();
  const [status, setStatus] = React.useState();

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      // console.log(response.data.data[0]);
      setStatus(response.data.data[5].buildSchoolStatus);
      setSixId(response.data.data[5].buildSchoolID);
      setSevenId(response.data.data[6].buildSchoolID);
    } catch (error) {
      console.error(error);
    }
  };
  // * =======================window Pop=====================================
  // *delete
  const [openDelete, setDelete] = React.useState(false);
  const [delVal, setDelVal] = React.useState("");
  const handleDeleteOpen = () => setDelete(true);
  const handleDeleteClose = () => setDelete(false);

  // *alert
  const [openAlert, setAlert] = React.useState(false);
  const [alertVal, setAlertVal] = React.useState("");
  const handleAlertOpen = () => setAlert(true);
  const handleAlertClose = () => setAlert(false);

  // *sucess
  const [openSucess, setSucess] = React.useState(false);
  const [sucessVal, setSucessVal] = React.useState("");
  const handleSucessOpen = () => setSucess(true);
  const handleSucessClose = () => setSucess(false);

  // * =======================window Popup end=====================================

  const [examStandardList, updateExamStandardList] = React.useState([]);
  const [examSubjectList, updateExamSubjectList] = React.useState([]);
  const [examTestList, updateExamTestList] = React.useState([]);

  // console.log(standardListAll);
  const [standardListAll, updateStandardListAll] = React.useState([]);

  const [standardListAllCopy, updateStandardListAllCopy] = React.useState([]);
  let [initialStandards, updateInitialStandards] = React.useState([]);
  const onStandardLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    // console.log("hello standard");
    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      console.log(response.data.data);
      // updateStandardListAll(response.data.data);
      updateStandardListAllCopy(response.data.data);
      updateInitialStandards(response.data.data);

      // console.log(initialStandards);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  const [examSelectedSub, updateExamSelectedSub] = React.useState([]);
  const [examSelectedSubID, updateExamSelectedSubID] =
    React.useState("Standard");
  const [loop, onLoop] = React.useState([]);

  const [yearList, updateYearList] = React.useState([]);
  let _standardList = [...standardListAll];

  function change(event, index) {
    // event.target.classList.toggle("standardSelected");
    // event.target.toggleAttribute("toggler");
    if (examSelectedSubID == "Standard") {
      setAlertVal();
      handleAlertOpen("Please Select the Standard");
      // alert("Please Select the Standard");
      return;
    }
    _standardList[index]["isSelected"] = standardListAll[index]["isSelected"]
      ? false
      : true;
    console.log(standardListAll);
    console.log(_standardList);

    updateStandardListAll(_standardList);
  }

  const onYearLoad = async () => {
    console.log("onStandardSubjectLoad");
    try {
      let response = await yearService.yearListAll();
      console.log(response.data.data);

      updateYearList(response.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  const Demo = async (e) => {
    console.log(e.currentTarget.id);
  };

  const onTestSelect = async (e) => {
    console.log(e.currentTarget.name);
    console.log(loop.indexOf(e.currentTarget.name));

    if (examSelectedSubID == "Standard") {
      setAlertVal("Please Select the Standard");
      handleAlertOpen();
      setTimeout(() => {
        handleAlertClose();
      }, 2000);
      // alert("Please Select the Standard!");
      return;
    }

    if (
      loop.filter((x) => x.testTitleName === e.currentTarget.name).length > 0
    ) {
      setAlertVal("Test Already exists!");
      handleAlertOpen();
      setTimeout(() => {
        handleAlertClose();
      }, 2000);
      // alert("Test Already exists!");
      return;
    }
    e.preventDefault();
    console.log(e.currentTarget.name);
    const obj = { id: e.currentTarget.id, testTitleName: e.currentTarget.name };
    onLoop([...loop, obj]);
    // console.log(loop);
  };

  async function onStandardSelect(e, index) {
    e.preventDefault();
    if (standardListAllCopy[index]["isSelected"]) {
      return;
    }
    // else {
    //   if(loop.length > 0){
    //     if(window.confirm("Changes will be lost if you proceed without saving!\nDo you still want to proceed?")){
    //     } else {
    //       return
    //     }
    //   }
    // }
    // console.log(initialStandards);
    // console.log(e.currentTarget.value, e.currentTarget.name);
    updateExamSelectedSub(e.currentTarget.value);
    updateExamSelectedSubID(e.currentTarget.name);
    // console.log(examSelectedSubID);
    // console.log(examSelectedSub);

    let highlightedStandardList = [...standardListAllCopy];
    highlightedStandardList = highlightedStandardList.map(
      ({ isSelected, ...highlightedStandardList }) => highlightedStandardList
    );
    highlightedStandardList[index]["isSelected"] = highlightedStandardList[
      index
    ]["isSelected"]
      ? false
      : true;

    // console.log(initialStandards);

    let x = initialStandards;
    console.log(x);
    x = x.filter((element, index) => {
      return element.standardID != e.currentTarget.value;
    });

    updateStandardListAll(x);

    let data = JSON.parse(localStorage.getItem("academicYear"));

    updateStandardListAllCopy(highlightedStandardList);
    let response = await BuildSchoolExam.showBuildSchoolStandardTest(
      e.currentTarget.value,
      data.yearName
    );
    // console.log("@@@@@@@@@@@@@@@@@")
    console.log(response.data.data);
    response.data.data.sort(function(a,b){
      return a.testOrder-b.testOrder;
    });
    onLoop(response.data.data);
  }

  const testTitleAdd = async (event) => {
    // console.log(event);
    event.preventDefault();
    updateTestname("");

    const data = new FormData(event.currentTarget);
    console.log(data.get("testName"));
    console.log(examTestList);
    // return
    const found = examTestList.find(
      (element) => element.testTitleName == data.get("testName")
    );
    if (data.get("testName") == "" || data.get("testName").trim().length < 3) {
      setAlertVal("Please enter the valid Test Name!");
      handleAlertOpen();
      // alert("Please enter the valid Test Name!");
      return;
    }
    if (found) {
      setAlertVal("Already Test Name Entered!");
      handleAlertOpen();
    }

    let x = examTestList.filter(function (test) {
      console.log(
        test.testTitleName,
        data.get("testName").trim().toLowerCase()
      );
      return test.testTitleName == data.get("testName").trim().toLowerCase();
    });
    // console.log(x);
    if (x.indexOf(data.get("testName").trim().toLowerCase()) > -1) {
      setAlertVal();
      handleAlertOpen("test exists!");
      // alert("test exists!");
      return;
    }
    let yeardata = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolExam.addBuildSchoolStandardsTest({
        testTitleName: data.get("testName"),
        yearID: yeardata.yearID,
        yearName: yeardata.yearName,
      });

      console.log(response);

      let nObj = {
        testTitleName: response.data.data.testTitleName,
      };

      updateExamTestList([...examTestList, nObj]);

      // if (response.data.success) {
      //   console.log("success");
      //   console.log("Designation Added Successfully");
      // } else {
      //   alert(response.data.message);
      // }
      // updateTestName("");
    } catch (error) {
      console.log("error");
      // alert("Failure");
    }
    textInput.current.value = "";

    setTimeout(() => {
      handleAlertClose();
    }, 2000);
  };

  const onTestRemove = async (e) => {
    // console.log(loop);
    console.log(e.currentTarget.accessKey);

    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolExam.deleteBuildSchoolStandardTest(
        e.currentTarget.accessKey,
        data.yearID,
        data.yearName
      );
      // alert(JSON.stringify(response));
      if (!response.data.success) {
        alert("Error!");
        return;
      }
      let examTestAfterDelete = examTestList.filter(
        (item) => item.testTitleName !== response.data.data.testTitleName
      );
      updateExamTestList(examTestAfterDelete);

      let examTestForSelectedStandardAfterDelete = loop.filter(
        (item) => item.testTitleName !== response.data.data.testTitleName
      );
      updateExamTestList(examTestAfterDelete);
      onLoop(examTestForSelectedStandardAfterDelete);
      setDelVal("deleted");
      handleDeleteOpen();
      setTimeout(() => {
        handleDeleteClose();
      }, 2000);
    } catch (error) {
      console.log(error);
      alert("Failure");
    }
  };

  const onTestLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolExam.listBuildSchoolStandardTest(
        data.yearName
      );
      console.log(response.data.data);

      updateExamTestList(response.data.data);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };

  const onStandardTestSave = async (e) => {
    e.preventDefault();
    let data = JSON.parse(localStorage.getItem("academicYear"));

    console.log(loop);
    // return
    if (loop.length == 0) {
      setAlertVal();
      handleAlertOpen("Please Select the Exams to Save!");
      setTimeout(() => {
        handleAlertClose();
      }, 2000);
      // alert("Please Select the Exams to Save!");
      return;
    }

    let standardNameID = document.getElementById("selectedSubject").value;
    let standardName = document.getElementById("selectedSubject").name;

    console.log(standardName);
    console.log(standardNameID);

    var standardTestList = [];

    for (let i = 0; i < loop.length; i++) {
      console.log(loop[i]);

      let standardTestObj = {
        testTitleName: loop[i].testTitleName,
        standardID: standardName,
        standardName: standardNameID,
        standardTestValueID: loop[i].id,
        standardTestValue: loop[i].testTitleName,
        testOrder: i,
        yearID: data.yearID,
        yearName: data.yearName,
      };

      // console.log(standardTestList[i].testTitleName);

      // console.log(loop.filter(e => e.testTitleName !== standardTestList[i].testTitleName));
      // return;
      // if (standardTestList.filter(e => e.testTitleName !== loop[i].testTitleName).length == -1) {
      //   alert("fresh");
      standardTestList.push(standardTestObj);
      // } else {
      //   alert("alread existing!");
      // }
    }

    try {
      let response = await BuildSchoolExam.saveBuildSchoolStandardTest(
        standardTestList
      );

      console.log(response);

      if (response.data.success) {
        console.log("success");
        setAlertVal("Exams Added Successfully to " + standardNameID);
        handleAlertOpen();
        setTimeout(() => {
          handleAlertClose();
        }, 2000);
        // alert("Exams Added Successfully to " + standardNameID);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.log("error");
      // alert("Failure");
    }
  };

  const onStandardSelectToCopy = async (e) => {
    console.log(e.currentTarget.value);
    // setShow(!show);
  };

  let navigate = useNavigate();

  let [testName, updateTestname] = React.useState("");

  const clear = (e) => {
    updateTestname("");
  };

  const updatedTestName = (e) => {
    updateTestname(e.currentTarget.value);
  };

  const exams = async () => {
    // alert("Redirect to next page");
    navigate("/examadding", { replace: true });

    // alert("Accepted the Page");
    // console.log(exams);
  };

  const copyExams = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    let x = _standardList.filter((element, index) => {
      return element.isSelected == true;
    });

    console.log(x);
    console.log(loop);

    let standardTestList = [];
    for (let o = 0; o < x.length; o++) {
      console.log(x[o]);

      for (let i = 0; i < loop.length; i++) {
        let standardTestObj = {
          testTitleName: loop[i].testTitleName,
          standardID: x[o].standardID,
          standardName: x[o].standardName,
          // standardTestValueID: loop[i].id,
          // standardTestValue: loop[i].testTitleName,
          standardTestValueID: loop[i].id,
          standardTestValue: loop[i].testTitleName,
          testOrder: i,
          yearID: data.yearID,
          yearName: data.yearName,
        };

        // console.log(standardTestObj);

        standardTestList.push(standardTestObj);
      }
      console.log(standardTestList);

      // return
      try {
        let response = await BuildSchoolExam.saveBuildSchoolStandardTest(
          standardTestList
        );
        console.log(response);
        if (response.data.success) {
          console.log("success");
          console.log("test Added Successfully");
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.log("error" + JSON.stringify(error));
        // alert("Please ");
      }

      standardTestList = [];
    }
  };

  const onExamDelete = async (testName, exam, standardId) => {
    console.log(testName, exam, standardId);
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolExam.deleteExamTitleFromForStandard(
        testName,
        standardId,
        data.yearName
      );

      if (response.data.success) {
        console.log("Test Deleted Succesfully!");
        setAlertVal("Test Deleted Succesfully!");
        handleAlertOpen();
        setTimeout(() => {
          handleAlertClose();
        }, 2000);
        let examTestAfterDeleteForStandard = loop.filter(
          (item) => item.testTitleName !== testName
        );
        console.log(examTestAfterDeleteForStandard);
        onLoop(examTestAfterDeleteForStandard);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.log("error" + JSON.stringify(error));
    }
  };

  let [copySection, updateCopySection] = React.useState(true);

  const checkToggle = (event) => {
    if (event.target.checked) {
      updateCopySection(false);
    } else {
      updateCopySection(true);
    }
  };
  const onlyCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9a-zA-Z ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };
  return (
    <div>
      <Topbar
        text={
          "Kindly Proceed to Step 6 Exam marks creation has two steps 1. Information about exams 2. Information about min max marks and grades."
        }
      />
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={6} md={2}>
            <Item sx={{
              //  color: "black", 
              //  height: "auto"
              height:550,
              overflow: "auto",
              background: "transparent",
                }}>
              {" "}
              {standardListAllCopy.map((element, index) => (
                <Button
                  size="small"
                  value={element.standardID}
                  name={element.standardName}
                  sx={{
                    position: "relative",
                    border: "2px solid #aaecac",
                    // minWidth: "90%",
                    width:"100%",
                    height: 30,
                    color: "Black",
                    fontWeight:"bold",
                    // marginBottom: 0.2,
                    mt:0.5,
                    backgroundColor:"#aaecac",
                    "&hover:":{background:"#035e06",colo:"white"}
                  }}
                  className={element.isSelected ? "standardSelected" : ""}
                  onClick={(event) => {
                    onStandardSelect(event, index);
                  }}
                >
                  {element.standardName}
                </Button>
              ))}
            </Item>
          </Grid>

          <Grid item xs={6} md={4}  sx={{textAlign:"center"}} >
            <Box border={"2px solid yellow"}>
              <Item
                sx={{
                  // border:"2px solid red",
                  // position: "relative",
                  // marginLeft: 2,
                  // float: "left",
                  height:490,
                  overflowY: "auto",
                  border:"none"
                  // width: "94%",
                }}
              >
                <Button
                  size="small"
                  sx={{
                    position: "relative",
                    border: "2px solid black",
                    width: "99%",
                    height: 30,
                    color: "Black",
                    fontWeight: "bold",
                    marginBottom: 0.2,
                  }}
                  id="selectedSubject"
                  name={examSelectedSub}
                  value={examSelectedSubID}
                  onChange={Demo}
                  className="standardSelected"
                >
                  {examSelectedSubID}
                </Button>

                {/* <Stack direction="column"> */}
                  {loop.map((element) => (
                  <div>
                    <Button
                      size="small"
                      sx={{
                        position: "relative",
                        border: "2px solid black",
                        minWidth: "100%",
                        minHeight:"100%",
                        borderRadius: 2,
                        height: 30,
                        marginTop: 0.5,
                        color: "Black",
                        marginBottom: 0.2,
                        background: "#5485e594",
                        "&:hover": {
                        background: "#3661b494",
                        },
                      }}
                    >
                      <Grid container spacing={0}>
                        <Grid item xs={10}>
                          <Typography sx={{ mx: 2 }}>
                            {element.testTitleName}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          {/* Maths */}
                          <IconButton
                            aria-label="delete"
                            id={element.testTitleName}
                            onClick={(event) => {
                              onExamDelete(
                                element.testTitleName,
                                element,
                                element.standardID
                              );
                            }}
                            size="small"
                            color="error"
                            disabled={status == "true" ? true : false}
                            // sx={{ marginLeft: 5 }}
                          >
                            <CancelIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                        
                      </Grid>
                    </Button>
                  </div>
                  ))}
                  
                {/* </Stack> */}

                {/* <Button
                  size="small"
                  sx={{
                    position: "relative",
                    border: "2px solid blue",
                    minWidth: "50%",
                    borderRadius: 2,
                    height: 55,
                    color: "Black",
                    marginTop: 1,
                    marginLeft: 0.2,
                  }}
                >
                  {" "}
                  CLICK ON TEST TITLE TO UNSELECT{" "}
                </Button> */}
                
              </Item>
              <Button
                fullWidth
                // size="large"
                variant="contained"
                sx={{
                  // alignItems:"center",
                  // px: 10,
                  // marginX: 15,
                  borderRadius: "25px",
                  height: 40,
                  fontWeight:"bold",
                  mt:2,
                  marginBottom:0.5,
                  // marginY: 2,
                }}
                color="success"
                onClick={onStandardTestSave}
                disabled={status == "true" ? true : false}
              >
                Save Exams
              </Button>
            </Box>
            {/* <Grid item md={10} xs={8} marginLeft={"40px"}  display={"flex"} justifyContent={"center"}> */}
           
            {/* </Grid> */}
          </Grid>

          <Grid item xs={12} md={6} sx={{height:580}}  marginLeft={{xs:1, md:"0"}}>
           {/* <Item sx={{height:550}}> */}
           <Item
              sx={{
                position: "relative",
                // float: "left",
                width: "auto",
                height: "40%",
                marginRight:1,
                fontWeight: 800,
                overflowY: "scroll",
              }}
            >
              CLICK SELECT TEST TITLE TO BE ADDED TO THE SELECTED STANDARD
              <Grid container spacing={2} marginTop={"4px"}>
                {examTestList.map((element) => (
                  <Grid item md={4} xs={6}>
                    <Box
                      key={element.standardTestID}
                      sx={{
                        position: "relative",
                        border: "2px solid black",
                        minWidth: "20%",
                        borderRadius: 2,
                        height: 30,
                        color: "Black",
                        fontWeight:"bold",
                        marginBottom: 0.2,
                        background: "#5485e594",
                        "&:hover": {
                        background: "#3661b494",
                        },
                      }}
                    >
                      {/* <Box component="form"> */}
                      <Grid container spacing={0}>
                        <Grid item xs={10}>
                          <Button
                            size="small"
                            variant="text"
                            sx={{color:"black",fontWeight:"bold",background: "transparent",
                            "&:hover:": {
                             background:"#3661b494",color:"white"
                            },
                          }}
                            // sx={{
                            //   position: "relative",
                            //   border: "2px solid blue",
                            //   minWidth: "20%",
                            //   borderRadius: 2,
                            //   height: 30,
                            //   color: "Black",
                            //   marginBottom: 0.2,
                            // }}
                            onClick={onTestSelect}
                            name={element.testTitleName}
                          >
                            {element.testTitleName}
                          </Button>
                        </Grid>
                        <Grid item xs={2} >
                          <IconButton
                            aria-label="delete"
                            accessKey={element.testTitleName}
                            // onClick={onTestSelect}
                            size="small"
                            color="error"
                            onClick={onTestRemove}
                            disabled={status == "true" ? true : false}
                          >
                            <CancelIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </Grid>
                      {/* </Box> */}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Item>

            {/* <Item>  </Item> */}
            <Item
              component="form"
              sx={{
                mt: 1,
                position: "relative",
                // float: "left",
                // minWidth: "20%",
                marginRight:1,
                width: "auto",
                height: "15%",
                fontWeight: 800,
              }}
              onSubmit={testTitleAdd}
            >
              <Grid container spacing={2} marginTop={"10px"}>
              {/* <Box display={"flex"} justifyContent={"center"}> */}
              <Grid item md={6} xs={6}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="ADD NEW TEST"
                  variant="outlined"
                  size="small"
                  sx={{ borderRadius: 0}}
                  name="testName"
                  inputRef={textInput}
                  // value={testName}
                  onInput={(e) => onlyCharaters(e)}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  onChange={updatedTestName}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ borderRadius: "25px" }}
                  disabled={status == "true" ? true : false}
                >
                  ADD EXAM
                </Button>
              </Grid>
              {/* </Box> */}
              </Grid>
            </Item>
            <Item
              sx={{
                mt: 1,
                position: "relative",
                // float: "left",
                marginRight:1,
                height: 180,
                fontWeight: 799,
              }}
            >
              TO COPY SELECTED STANDARD’S EXAM TITLE TO OTHER STANDARD :
              <Checkbox onChange={checkToggle} color="primary" />
              <div className={copySection ? "copySection" : ""}>
                <Button
                  size="small"
                  sx={{
                    position: "relative",
                    border: "2px solid black",
                    minWidth: "20%",
                    borderRadius: 2,
                    height: 30,
                    color: "Black",
                    fontSize:12,
                    fontWeight:"bold",
                    marginBottom: 0.2,
                    backgroundColor:"#aaecac",
                    "&hover:":{background:"#035e06",color:"white"}
                  }}
                  onClick={copyExams}
                  disabled={status == "true" ? true : false}
                >
                  {" "}
                  {examSelectedSubID ? examSelectedSubID + " --> " : ""}COPY TO
                </Button>

                <Box
                // sx={{
                //   display: "flex",
                //   alignContent: "center",
                //   justifyContent: "center",
                //   textAlign: "center",
                //   width: "100%",
                //   // ml: 8,
                // }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={11.5}>
                      <Item sx={{ height: 80 }}>
                        <Grid container spacing={0.5}>
                        {standardListAll.map((element, index) => (
                          <Grid item md={4} xs={4}>
                          <Button
                            fullWidth
                            key={element.standardID}
                            className={
                              element.isSelected ? "standardSelected" : ""
                            }
                            id={`outlined-basic-${index}`}
                            // label="Outlined"
                            // variant="outlined"
                            size="small"
                            sx={{
                              // width: "100px",
                              // height: "50%",
                              fontSize: 12,
                              color: "black",
                              fontWeight:"bold",
                              border: "1px solid black",
                              ml: 1,
                              backgroundColor:"#aaecac",
                    "&hover:":{background:"#035e06",color:"white"}
                            }}
                            value={element.standardID}
                            name={element.standardName}
                            onClick={(event) => {
                              change(event, index);
                            }}
                          >
                            {" "}
                            {element.standardName}{" "}
                          </Button>
                          </Grid>
                        ))}
                        </Grid>
                        
                      </Item>
                    </Grid>
                    {/* <Button
                      size="small"
                      sx={{
                        position: "relative",
                        border: "2px solid blue",
                        minWidth: "40%",
                        borderRadius: 2,
                        height: 40,
                        color: "Black",
                        marginTop: 3,
                        marginLeft: 0.2,
                      }}
                      onClick={exams}
                    >
                      {" "}
                      CLICK HERE TO GO TO NEXT STEP{" "}
                    </Button> */}
                  </Grid>
                </Box>
              </div>
            </Item>
           {/* </Item> */}
           <Grid container spacing={2} paddingBottom={"25px"}>
            <Grid item md={6} xs={5}>
              <Button
                fullWidth
                size="small"
                variant="contained"
                sx={{
                  position: "relative",
                  // border: "2px solid",
                  minWidth: "40%",
                  borderRadius: 2,
                  fontWeight:"bold",
                  height: 40,
                  mt:2,
                  // color: "white",
                  // backgroundColor: "black",
                  // marginTop: 3,
                  borderRadius: "25px",
                  // marginLeft: 3,
                }}
                onClick={exams}
              >
                {" "}
                NEXT STEP{" "}
              </Button>
            </Grid>
            <Grid item md={6} xs={7}>
              <Button
                fullWidth
                variant="contained"
                color="warning"
                // onClick={onPause}
                sx={{
                  borderRadius: "25px",
                  fontWeight:"bold",
                  height: 40,
                  mt:2,
                }}
              >
                Pause and Continue Setup Later
              </Button>
            </Grid>
           </Grid>
          </Grid>
        </Grid>
      </Box>
      
      
    </div>
  );
}
