import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import {
  Button,
  Grid,
  Input,
  Paper,
  Stack,
  TextField,
  Typography,
  Fade,
  Modal,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import { StaffService } from "../../../services/StaffService";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  borderColor: "transparent",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

export default function DataGridDemo() {
  React.useEffect(() => {
    onStaffLoad();
    // onBankLoad();
    // onMonthLoad();
  }, []);

  const [columns, setColumns] = React.useState([
    {
      field: "ssid",
      headerName: "SSID",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"SSID"} </strong>,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "staffName",
      headerName: "STAFF NAME",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"STAFF NAME"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "designation",
      headerName: "DESIGNATION",
      flex: 1,
      align: "center",
      sortable: false,
      headerAlign: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"DESIGNATION"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "monthlySalary",
      headerName: "MONTHLY SALARY",
      flex: 1,
      align: "center",
      sortable: false,
      headerAlign: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"MONTHLY SALARY"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "paySalaries",
      headerName: "PAY SALARY",
      flex: 1,
      // type: "number",
      editable: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"PAY SALARY"} </strong>
      ),
      headerClassName: "super-app-theme--header",
    },
  ]);
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [excel, setExcel] = React.useState([]);
  const [monthTotal, setMonthTotal] = React.useState([]);
  const [bank, setBank] = React.useState([]);
  const [monthlySalary, setMonthlySalary] = React.useState([]);
  const [monthlyName, setmonthlyName] = React.useState();
  const [total, setTotal] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  

  const handleClose=()=>{
    setOpen(false);
  }

  const onStaffLoad = async () => {
    setLoading(true);
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    console.log(yearData);
    try {
      // let response = await StaffService.salariesDetailsList(yearData.yearName);
      let response = await StaffService.staffSalaryDetailsList(
        yearData.yearName
      );

      // let response = await StaffService.salariesDetailsList();
      // debugger
      setMonthTotal(response.data.data.paySalariesDetailsTotallist);
      let staff_array = [];
      response.data.data.salariesDetailsList.forEach(function (data) {
        console.log(data);
        staff_array.push({
          designation: data.designation,
          monthlySalary: data.monthlysalary,
          // month:data.month,
          paySalaries: data.paySalaries,
          ssid: data.ssid,
          staffName: data.staffName,
          // id: data.staffID,
          id: data.ssid,
          yearID: yearData.yearID,
          yearName: yearData.yearName,
        });
      });
      setRows(staff_array);
      setMonthlySalary(response.data.data.paySalariesDetailsTotallist);
      console.log(response.data.data.paySalariesDetailsTotallist);

      // let totalSal = response.data.data.paySalariesDetailsTotallist.reduce((sum,r)=>sum + parseInt(r.totalPay,10));
      // setTotal(totalSal);
      let arry = response.data.data.paySalariesDetailsTotallist;
      let pay = arry.map((r)=>({
        ...r,
        // totalPay: Number(r.totalPay !==null ? r.totalPay.replace(/ ,/g, '') : 0),
        totalPay : (r.totalPay !== null ? r.totalPay.replace(",", "") : 0) ,
      }));
// console.clear();
console.log(pay);

let total = 0;
for(let i=0; i<pay.length; i++) {
  let s = parseInt(pay[i].totalPay);
  total +=s;
}
console.log(total);
setTotal(total);


      console.log(staff_array);
      console.log(rows);
      setLoading(false);

      let paysal = [];
      response.data.data.paySalariesDetailsTotallist.forEach(function (data) {
        paysal.push({
          SSID: data.ssid,
          STAFFNAME: data.staffName,
          DESIGNATION: data.designation,
          MONTHLYSALARY: data.monthlysalary,
          PAYSALARY: data.paySalaries,
        });
      });
      setExcel(paysal);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(monthlyName);
  // const onBankLoad = async () => {
  //   let yearData = JSON.parse(localStorage.getItem("academicYear"));
  //   try {
  //     let response = await StaffService.bankDetails(yearData.yearName);
  //     console.log(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const onMonthLoad = async () => {
  //   let yearData = JSON.parse(localStorage.getItem("academicYear"));

  //   try {
  //     let response = await StaffService.bankDetails(yearData.yearName);
  //     console.log(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const onPaySalaryUpdate =(e)=>{
  //   const found = rows.find(
  //     (element) =>
  //     element.id == e.id
  //     );

  //     console.log(found)
  //     found.paySalary = e.value;
  //     console.log(rows)
  // }
  const [payTotal, setPayTotal] = React.useState(0);

  const update = (e, v) => {
    // console.log(e, v);
    let found = rows.find((r) => r.id == e.id);
    if (monthlyName) {
      let found = rows.find((r) => r.id == e.id);
      found.paySalaries = e.value;
      setPayTotal(payTotal + parseInt(e.value));
    } else {
      found.paySalaries = 0;
      alert("Please select the Month first");
    }
  };

  // let payTotal = 0;
  // function payMonthyTotal() {
  //   rows.forEach((rec) => {
  //     // debugger;
  //     if (!isNaN(rec.paySalaries)) {
  //       console.log(parseInt(rec.paySalaries));
  //     }
  //     // setPayTotal(
  //     // );
  //   });
  // }
  const [highlightedButton, setHighlightedButton] = React.useState();
  const [monthlyID, setmonthlyID] = React.useState();
  const [freeze, setFreeze] = React.useState(false);
  
  const onMonthSelect = async(e) => {
   
    setmonthlyID(e.target.value);
    setmonthlyName(e.target.id);
    setHighlightedButton(e.target.id);

    // let arr = [...rows];
    // arr.forEach((rec) => {
    //   rec.paySalaries = 0;
    // });
    // console.log(arr);
    // setRows(arr);
    // setPayTotal(0);

    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    let arr = [...rows];
    try{
      let response = await StaffService.getStaffSalarypaymonthList(e.target.id, yearData.yearName);
      console.log(response.data.data);

      let payMonth = [];
      response.data.data.forEach(function(data){
        console.log(data);
        payMonth.push({
          id: data.ssid,
          ssid: data.ssid,
          staffName: data.staffName,
          designation: data.designation,
          monthlySalary: data.monthlysalary,
          paySalaries: data.paySalaries
        });
      });
      // setRows(payMonth);
      if(response.data.data.length === 0){
        // onStaffLoad();
         let arr = [...rows];
    arr.forEach((rec) => {
      rec.paySalaries = 0;
    });
    console.log(arr);
    setFreeze(false);
    setRows(arr);
    setPayTotal(0);
      }else{
        setFreeze(true);
        setRows(payMonth);
      }
    }
    catch(error){
      console.error(error);
    }
  };

  const onSalaryUpdate = async (e) => {
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    setmonthlyName(e.currentTarget.value);

    let salaryArr = [];
    rows.forEach((rec) => {
      salaryArr.push({
        ssid: rec.ssid,
        monthlysalary: rec.monthlySalary,
        paySalaries: rec.paySalaries.toString(),
        designation: rec.designation,
        staffName: rec.staffName,
        month: monthlyName,
        year: yearData.yearName,
      });
    });
    console.log(salaryArr);
    setTimeout(() => {
      try {
        let response = StaffService.updatesalariesMonthlyDetails(salaryArr);
        // console.log(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }, 1000);

    monthlySalary.forEach((rec) => {
      console.log(rec);
      console.log(parseInt(rec.totalPay), payTotal);
      if (rec.paySalariesDetailsID == monthlyID) {
        let total;
        if (rec.totalPay == null) {
          total = payTotal;
        } else {
          total = parseInt(rec.totalPay) + payTotal;
        }
        rec.totalPay = total.toLocaleString();
      }
    });
    setTimeout(() => {
      try {
        let response = StaffService.paySalariesDetailsmonth(monthlySalary);
        // 0console.log(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }, 1000);
  
    handleClose();
    setFreeze(true);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false)
    }, 2000);
    // setFreeze(true);

    console.log(monthlySalary);

  };


  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };

  const [academicYear, setacademicYear] = React.useState(
    JSON.parse(localStorage.getItem("academicYear"))
  );
  const [schoolName, setschoolName] = React.useState(
    JSON.parse(localStorage.getItem("schoolDetails"))
  );

  const generatePDF = () => {
    const doc = new jsPDF("p", "pt", "a4");
    const sch = schoolName.schoolNameOnDocs.toUpperCase();
    const acad = academicYear.yearName;
    const img = schoolName.schoolLogoUrl;
    doc.setFont("helvetica", "bold");
    var fontSize = 18;
    var text = `${sch}`;
    // var pageWidth = doc.internal.pageSize.getWidth();
    // var textWidth =
    //   (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    // var centerX = (pageWidth - textWidth) / 2;
    doc.text(text, 170, 35);

    doc.addImage(img, "JPEG", 20, 10, 80, 70);
    doc.setFontSize(10);
    doc.text(` ${acad}`, 500, 35);
    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    doc.text("PAY SALARIES", 250, 75);

    const tableData = rows.map((row) => [
      row.ssid,
      row.staffName,
      row.designation,
      row.monthlySalary,
      row.paySalaries,
    ]);

    doc.autoTable({
      startY: 90,
      head: [
        ["SSID", "STAFF NAME", "DESIGNATION", "MONTHLY SALARY", "PAY SALARY"],
      ],
      body: tableData,
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
      },
      theme: "grid",
    });
    doc.setFontSize(10);
    // doc.text("Copyright: Technotharanga Solutions Pvt.ltd", 190, 800);
    doc.save(`Staff pay salary ${monthlyName} .pdf`);
  };

  const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(excel);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, `Staff pay salary ${monthlyName} .csv`);
  };
  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="staff" /> */}
      {/* MONTHLY SALARY DETAILS */}
      {loading ? (
        <div
          style={{
            padding: "180px 0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
      ) : (
        <Box>
          <Box sx={{ mt: 2 }}>
            <Grid container sx={{ flexGrow: 1 }}>
              {/* <Stack direction="row"> */}
              {/* <Grid xs={12} sx={{ ml: 5, width: "80%", display: "flex" }}> */}
              {monthlySalary.map((rec, e) => (
                // <Grid
                //   item
                //   xs={1}
                //   sx={{ border: "1px solid black", width: "100px" }}
                // >
                <Grid item md={0.87} xs={2.9} marginTop={{xs:1, md:""}} >
                    <Item sx={{ ml: 2.5, border: "2px solid black" }}>
                      <Button
                        variant="contained"
                        // color="primary"
                        // component="button"
                        value={rec.paySalariesDetailsID}
                        id={rec.month}
                        sx={{
                          borderRadius: "1px",
                          width: "93px",
                          backgroundColor:
                            highlightedButton === rec.month
                              ? "primary"
                              : "lightblue",
                          color:
                            highlightedButton === rec.month ? "white" : "black",
                          height: "30px",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        onClick={onMonthSelect}
                      >
                        {rec.month}
                      </Button>
                      <Typography sx={{ height: "30px", textAlign: "center" }}>
                        {rec.totalPay ? rec.totalPay : "No Salary"}
                      </Typography>
                    </Item>
                 </Grid>
              ))}
              {/* </Grid> */}
              <Grid item md={1.23} xs={12}  marginLeft={{xs:2, md:1}}marginTop={{xs:1, md:1}}>
                <Item sx={{width:"96%",border: "2px solid black", }}>
                  <Typography
                    sx={{
                      backgroundColor: "lightgrey",
                      color: "black",
                      height: "30px",
                      fontWeight: "bold",
                      paddingTop:0.9,
                      textAlign: "center",
                    }}
                  >
                    TOTAL FOR YEAR
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>Rs {total}</Typography>
                </Item>

                {/* <Grid xs={1.2} sx={{ border: "1px solid black", overflow: "scroll" }}>
            <Typography
              sx={{
                backgroundColor: "lightgrey",
                color: "black",
                height: "30px",
                fontWeight: "bold",
              }}
            >
              TOTAL FOR YEAR
            </Typography>
            <Typography sx={{ textAlign: "center" }}>xxxxxx</Typography>
          </Grid> */}
              </Grid>
              {/* </Stack> */}
            </Grid>
          </Box>
          {/*END OF MONTHLY SALARY DETAILS */}

          <Box
          sx={{
            height: 423,
            padding: "0 16px",
            boxSizing: "border-box",
            mt: "1%",
            width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#c0c0c0",
                // color:'white',
                fontWeight: "bold",
                fontSize: "15px",
                border: "1px solid white",
              },
              overflowX:"auto",
          }}
        >
            {/* .........PAY SALARY DATA TABLE..........*/}
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              disableColumnMenu
              sx={{minWidth:800}}
              onCellEditCommit={update}
              
              // experimentalFeatures={{ newEditingApi: true }}
              // onCellEditCommit={onPaySalaryUpdate}
            />
          </Box>

          <Box sx={{display: "flex", justifyContent:"flex-end", marginRight:3, marginBottom:2, marginTop:2}}>
            <Typography variant="h6" fontWeight="bold" sx={{display: freeze ? 'none': 'contents'}}>
                Total Pay Salary : {payTotal}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 2, paddingBottom:"25px" }}>
            <Button
              variant="contained"
              color="error"
              size="large"
              sx={{
                color: "white",
                borderRadius: "30px",
                // padding: "10px 40px 10px 40px",
                fontSize: 15,
                width: "140px",
                fontWeight: "bold",
              }}
              onClick={generatePDF}
            >
              PDF
            </Button>

            <Button
              size="large"
              variant="contained"
              color="success"
              onClick={exportToCSV}
              
              sx={{
                color: "white",
                borderRadius: "30px",
                // padding: "10px 40px 10px 40px",
                fontWeight: "bold",
                fontSize: 15,
                width: "140px",
              }}
            >
              EXCEL
            </Button>

            <Button
              size="large"
              variant="contained"
              sx={{
                color: "white",
                borderRadius: "30px",
                // padding: "10px 40px 10px 40px",
                
                fontWeight: "bold",
                fontSize: 15,
                width: "140px",
              }}
              // type="submit"
              // onClick={handlesubmit}
              // disabled={freeze}
              disabled = {freeze}
              onClick={()=>setOpen(true)}
              // onClick={onSalaryUpdate}
            >
              pay
            </Button>

            <Button
              size="large"  
              onClick={onLogout}
              variant="contained"
              sx={{
                backgroundColor: "#ca424f",
                color: "white",
                borderRadius: " 30px",
                // padding: "10px 40px 10px 40px",
                fontWeight: "bold",
                fontSize: 15,
                width: "140px",
                "&:hover": { backgroundColor: "#ca424f" },
              }}
            >
              EXIT
            </Button>

            

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              // onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id="transition-modal-title"
                    variant="body1"
                    component="h2"
                  >
                    You have clicked on
                  </Typography>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    FREEZ INFORMATION
                  </Typography>
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    We request you to kindly review the information once again
                    and then freeze the data, as once its FREEZE can’t be
                    changed. Thank you
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={onSalaryUpdate}
                    >
                      FREEZE
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Review
                    </Button>
                  </Stack>
                </Box>
              </Fade>
            </Modal>

            <Modal
                open={success}
                // onClose={() => setSucess(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div sx={style}>
                  <SucessModal
                    text={"Salary paid successfully"}
                    name={"Salary paid successfully"}
                  />
                </div>
              </Modal>

          </Box>
        </Box>



      )}
    </React.Fragment>
  );
}
