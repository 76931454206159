import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { C } from "../../util/C";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardDivision } from "../../../services/StandardDIvision";
import {
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { StandardSubjectService } from "../../../services/StandardSubject";
import { BuildSchoolExam } from "../../../services/examStandards";
import { StudentService } from "../../../services/studentService";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import { ExamMarksService } from "../../../services/examMarksService";
import jsPDF from "jspdf";
import { autoTable } from "jspdf-autotable";
import axios from "axios";

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#03dff9",
  "&:hover": {
    background: "#038da6",
    color: "white",
  },
}));
const ItemSb = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#37bc98",
  "&:hover": {
    background: "#03946d",
  },
  color: "white",
}));
const ItemE = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  // background: "rgb(255,148,121)",
  background: "#5485e594",
  "&:hover": {
    background: "#3661b494",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));
const ItemBg = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));
let Item1 = {
  // borderRight: "1px solid black",
  textAlign: "center",
  width: "200px",
  variant: "body1",
  backgroundColor: "#B5E2F4",
  fontWeight: "bold",
  // height: '40px',
  // py: 2,
};
let button = {
  fontSize: "20px",
  borderRadius: "30px ",
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

const columns = [
  {
    field: "id",
    headerName: "NO",
    flex: 1,
    headerAlign: "center",
    editable: false,
    sortable: false,
    align: "center",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "name",
    headerName: " NAME",
    flex: 1,
    headerAlign: "center",
    editable: false,
    sortable: false,
    align: "center",
    valueFormatter: (params) => params.value.toUpperCase(),
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "marks",
    headerName: " MARKS",
    flex: 1,
    headerAlign: "center",
    align: "center",
    type: "number",
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"MARKS"}</strong>,
    headerClassName: "super-app-theme--header",
    editable: true,
    sortable: false,
  },
  {
    field: "grade",
    headerName: " GRADE",
    flex: 1,
    editable: true,
    headerAlign: "center",
    align: "center",
    sortable: false,
    // valueFormatter: (params) => params.value.toUpperCase(),
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"GRADE"}</strong>,
    headerClassName: "super-app-theme--header",
  },
];

export default function DataGridDemo() {
  const [Standard, setStandard] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rows1, setRows1] = React.useState([]);
  const [freeze, setFreeze] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      console.log(response)
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };
  const [StandardName, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [DivisionName, setDivisionName] = React.useState();
  const [Division, setDivision] = React.useState([]);
  const [StandardSubjectListAll, updateStandardSubject] = React.useState([]);
  const [standardTest, updateStandardTest] = React.useState([]);
  const [subjectName, setSubjectName] = React.useState();
  const [subjectID, setSubjectID] = React.useState();
  const [testName, setTestName] = React.useState();
  const [testID, setTestID] = React.useState();

  async function onStandardSelect(e, index) {
    e.preventDefault();
    setRows("");
    setTestName("");
    setMaxValue("");
    setMinValue("");
    setStandardName(e.target.textContent);
    setStandardID(e.target.id);
    C.c(e.currentTarget.id);
    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "iSSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      C.c(response.data.data);
      setDivision(response.data.data);
    } catch (error) {
      C.c(error.message);
    }
    try {
      let response =
        await StandardSubjectService.findSubjectListByStandardIDYearID(
          data.yearName,
          e.target.id
        );
      console.log(response.data.data);
      response.data.data.sort(function (a, b) {
        return a.subjectOrder - b.subjectOrder;
      });
      updateStandardSubject(response.data.data);
    } catch (error) {
      console.log(error);
    }
    console.log(StandardSubjectListAll);

    try {
      let response = await BuildSchoolExam.showBuildSchoolStandardTest(
        e.target.id,
        data.yearName
      );
      console.log(response.data.data);

      response.data.data.sort(function (a, b) {
        return a.testOrder - b.testOrder;
      });
      updateStandardTest(response.data.data);
    } catch (error) {
      console.log(error);
    }
    console.log(StandardSubjectListAll);
  }
  async function onDivisionSelect(e, index) {
    C.c(e.target.id);
    C.c(StandardID);
    setDivisionName(e.target.id);
    let highlightDivision = [...Division];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision }) => highlightDivision
    );
    setDivision(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index][
      "isSelected"
    ]
      ? false
      : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));

    let students = [];
    try {
      let response = await StudentService.approveddivisionstudentListAll(
        data.yearName,
        e.target.id,
        StandardID
      );

      response.data.data.sort((a, b) => a.studentName.localeCompare(b.studentName));
      response.data.data.forEach((rec, i) => {
        students.push({
          id: i + 1,
          name: rec.studentName,
          marks: "",
          grade: "",
          usn: rec.studentUSN,
        });
        // C(rec)
      });
      setRows(students);
      setRows1(students);
      // C.c(rows);
      console.log(students);
    } catch (error) {
      C.c(error.message);
    }
  }

  async function onSubjectSelect(e, index) {
    C.c(e.target.textContent);
    let highlightSubject = [...StandardSubjectListAll];
    highlightSubject = highlightSubject.map(
      ({ isSelected, ...highlightSubject }) => highlightSubject
    );
    updateStandardSubject(highlightSubject);
    highlightSubject[index]["isSelected"] = highlightSubject[index][
      "isSelected"
    ]
      ? false
      : true;
  }

  async function onTestSelect(e, index, et) {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    C.c(e.target.textContent);
    let highlightTest = [...standardTest];
    highlightTest = highlightTest.map(
      ({ isSelected, ...highlightTest }) => highlightTest
    );
    updateStandardTest(highlightTest);
    highlightTest[index]["isSelected"] = highlightTest[index]["isSelected"]
      ? false
      : true;
    ontestValues(et);

    console.log(rows);

    console.log(StandardID);
    console.log(DivisionName);
    console.log(subjectID);
    console.log(e.target.id);
    setTestID(e.target.id);
    // console.log(e.target.value)
    let students = [];

    try {
      // let response = await ExamMarksService.marksList(
      //   DivisionName,
      //   StandardID,
      //   e.target.id,
      //   subjectID,
      //   data.yearName
      // );
      let response = await axios.get(`http://139.59.11.130:6561/api/marks/${StandardID}/${DivisionName}/${e.target.textContent}/${subjectID}/${data.yearName}`)

      console.log(response.data.marksList);
      console.log(response.data.marksList.length);
      if (response.data.marksList.length != 0) {
        response.data.marksList.forEach((rec, i) => {
          students.push({
            id: i + 1,
            name: rec.studentName,
            marks: rec.markSheet,
            grade: rec.grade,
            usn: rec.studentUSN,
          });

        });
        setRows(students);
        setFreeze(true);
        console.log("if con");
      } else {
        console.log("else con");
        setRows(rows1);
        setFreeze(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  let [testvalue, setTestValue] = React.useState([]);
  let [maxvalue, setMaxValue] = React.useState([]);
  let [minvalue, setMinValue] = React.useState([]);

  async function ontestValues(testName) {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    // console.log(selectedStandardID);
    // console.log(selectedSubjectID);
    console.log(testName);
    try {
      let response = await BuildSchoolExam.getStandardSubjectTestValue(
        StandardID,
        subjectID,
        testName,
        data.yearName
      );
      console.log(response.data.data);
      setTestValue(response.data.data);
      if (response.data.data) {
        const MaxFound = response.data.data.find(
          (element) => element.standardTestkey == "MAX"
        );
        setMaxValue(MaxFound.standardTestvalue);
        const MinFound = response.data.data.find(
          (element) => element.standardTestkey == "MIN"
        );
        setMinValue(MinFound.standardTestvalue);
      }
    } catch (error) {
      console.log("error: " + error);
    }
  }

  function onMarksEntry(v, e) {
    // let maxMark = testvalue.shift();
    // let minMark = testvalue.shift();
    // console.log(v);
    // console.log(maxMark);
    // console.log(minMark);
    // console.log(testvalue);
    // console.log(rows);
    let re = false;
    let r = [];
    testvalue.forEach((r) => {
      if (r.standardTestkey != "MAX") {
        if (r.standardTestkey != "MIN") {
          if (re == false) {
            // debugger;
            if (r.standardTestvalue >= v.value) {
              // if(r.standardTestvalue == v.value){
              // console.log(r);
              let recdata = rows.find((element) => element.id == v.id);
              console.log(r.standardTestvalue);
              console.log(v.value);
              console.log(recdata);
              recdata.marks = v.value;
              recdata.grade = r.standardTestkey;
              re = true;
              // console.log(recdata);
              // }
            }
          } else {
            return;
          }
        }
      }
    });

    // setRows(rows);
    setTimeout(() => {
      setRows(rows.map((row) => ({ ...row, rows })));
    }, 1000);

    console.log(rows);
  }

  const marksSave = async (e) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    const currentDate = new Date();
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);

    let marksSheet = [];
    rows.forEach((rec) => {
      marksSheet.push({
        divisionID: DivisionName,
        divisionName: DivisionName,
        grade: rec.grade,
        markSheet: rec.marks,
        monthName: new Date().getMonth(),
        standardID: StandardID,
        standardName: StandardName,
        standardTestID: testName,
        studentName: rec.name,
        subjectID: subjectID,
        subjectName: subjectName,
        subjectMaxMarks:maxvalue,
        subjectMinMarks:minvalue,
        studentUSN: rec.usn,
        testTitleName: testName,
        yearID: data.yearID,
        yearName: data.yearName,
      });
      console.log(marksSheet);
    });

  //   try {
  //     let response = await ExamMarksService.createMarks(marksSheet);
  //     debugger
  //     let response = await axios.post("http://139.59.35.56:6561/api/marks",marksSheet);
  //     setFreeze(true);
  //     console.log(response.data.marks);
  //     handleClose();
  //     setSuccess(true);
  //     setTimeout(() => {
  //       setSuccess(false);
  //     }, 2000);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  let response = axios.post("http://139.59.35.56:6561/api/marks",marksSheet);
  //     setFreeze(true);
      console.log(response.data);
  };
  

  const generatePDF = () => {
    const academic = JSON.parse(localStorage.getItem("academicYear"));
    const school = JSON.parse(localStorage.getItem("schoolDetails"));

    const doc = new jsPDF("p", "pt", "a4");
    doc.setFontSize(10);
    doc.text(`${academic.yearName}`, 500, 35);

    const text = `${school.schoolNameOnDocs.toUpperCase()}`;
    const fontSize = 18;
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFontSize(fontSize);
    doc.setFont("Helvetica", "bold");
    doc.text(text, pageWidth / 2, 35, { align: "center" });

    const img = `${school.schoolLogoUrl}`;
    doc.addImage(img, "JPEG", 20, 10, 80, 70);

    const tableData = rows.map((row) => [
      row.id,
      row.name,
      row.marks,
      row.grade,
    ]);

    const std = StandardName;
    const div = DivisionName;
    const sub = subjectName;
    const tes = testName;
    const min = minvalue.toString();
    const max = maxvalue.toString();
    doc.setFontSize(13);
    doc.setFont("Times-Roman", "bold");
    doc.text(std, 105, 105);
    doc.text(div, 220, 105);
    doc.text(tes, 300, 105);
    doc.text(sub, 450, 105);
    doc.text("MAX : " + max, 120, 135);
    doc.text("MIN : " + min, 400, 135);
    doc.autoTable({
      startY: 150,
      tableLineColor: [0, 0, 0],
      head: [["NO.", "NAME", "MARKS", "GRADE"]],
      body: tableData,
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
      },
      tableLineWidth: 0.95,
      theme: "grid",
    });
    doc.setFontSize(10);
    // doc.text("Copyright: Technotharanga Solutions Pvt.ltd", 190, 800);
    doc.save("Marks Sheet -  " + StandardName + " - " + DivisionName + " - " + testName + " - " + subjectName + " .pdf")
  };

  let navigate = useNavigate();
  const onLaunchpad = () => {
    navigate("/launchpad", { replace: true });
  };

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="dataentry" /> */}
      <Typography
        variant="h6"
        sx={{
          backgroundColor: "lightblue",
          width: "100&",
          boxSizing: "border-box",
          margin: "0 10px",
          mt: 2,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        SELECT STANDARD, DIVISION, EXAM AND SUBJECT - PRINT OR DOWNLOAD BLANK
        FORM
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          mt: 1,
          width: "100%",
          padding: "0 10px",
          boxSizing: "border-box",
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={8} xs={8}>
            <ItemBg>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      value={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </ItemBg>
          </Grid>
          <Grid item md={4} xs={4}>
            <ItemBg>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Division.map((element, index) => (
                  <Grid item md={1.5} xs={4} sx={{ cursor: "pointer" }}>
                    <ItemD
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        onDivisionSelect(e, index);
                      }}
                      id={element.divisionName}
                      className={element.isSelected ? "selected" : ""}
                    >
                      {" "}
                      {element.divisionName}
                    </ItemD>
                  </Grid>
                ))}
              </Grid>
            </ItemBg>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        spacing={1}
        sx={{ width: "100%", mt: "10px", padding: "0 10px", boxSizing: "border-box" }}
      >
        <Grid item xs={8}>
          <ItemBg>
            <Grid container spacing={1} sx={{ minHeight: 40 }}>
              {StandardSubjectListAll.map((element, index) => (
                <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                  <ItemSb
                    sx={{ fontWeight: "bold" }}
                    id={element.standardSubjectID}
                    value={element.subjectName}
                    onClick={(e) => {
                      onSubjectSelect(e, index);
                      setSubjectName(e.target.textContent);
                      setSubjectID(e.target.id);
                    }}
                    className={element.isSelected ? "selectSubject" : ""}
                  >
                    {element.subjectName.toUpperCase()}
                  </ItemSb>
                </Grid>
              ))}
            </Grid>
          </ItemBg>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{ width: "100%", mt: "10px", padding: "0 10px", boxSizing: "border-box" }}
      >
        <Grid item xs={8}>
          <ItemBg>
            <Grid container spacing={1} sx={{ minHeight: 40 }}>
              {standardTest.map((element, index) => (
                <Grid item md={2} xs={6} sx={{ cursor: "pointer" }}>
                  <ItemE
                    sx={{ fontWeight: "bold" }}
                    id={element.standardTestID}
                    onClick={(e) => {
                      onTestSelect(e, index, element.testTitleName);
                      setTestName(e.target.textContent);
                    }}
                    className={element.isSelected ? "selectedTest" : ""}
                  >
                    {element.testTitleName.toUpperCase()}
                  </ItemE>
                </Grid>
              ))}
            </Grid>
          </ItemBg>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "75%",
          backgroundColor: "lightblue",
          marginLeft: "10%",
          my: 1,
          border: "1px solid black",
          // borderRight: "none",
          // borderLeft: "none",
          padding: "2px"

        }}
      >
        <Stack direction="row">
          <Typography style={Item1} sx={{ borderRight: "1px solid black" }}>
            {!StandardName ? "STANDARD" : StandardName}
          </Typography>
          <Typography style={Item1} sx={{ borderRight: "1px solid black" }}>
            {!DivisionName ? "DIVISION" : DivisionName}
          </Typography>
          <Typography style={Item1} sx={{ borderRight: "1px solid black" }}>
            {!subjectName ? "SUBJECT" : subjectName}
          </Typography>
          <Typography style={Item1} sx={{ borderRight: "1px solid black" }}>
            {!testName ? "TEST" : testName}</Typography>

          <Typography style={Item1} sx={{ borderRight: "1px solid black" }}>
            MAX : {maxvalue}</Typography>
          <Typography style={Item1}>
            MIN : {minvalue}
          </Typography>
        </Stack>
      </Box>

      <Box
        sx={{
          height: 500,
          mt: 2,
          width: "100%",
          padding: "0 10px",
          boxSizing: "border-box",
          "& .super-app-theme--header": {
            backgroundColor: "#c0c0c0",
            //  color:'white',
            fontWeight: "bold",
            fontSize: "15px",
            border: "1px solid white",
          },
          // overflowX:"auto",
        }}
      >
        <DataGrid
          showColumnRightBorder={true}
          showCellRightBorder={true}
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[10]}
          onCellEditCommit={onMarksEntry}
          // sx={{minWidth:800}}
          disableColumnMenu
          disableSelectionOnClick
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} sx={{ mt: 2 }}>
          <Grid item xs={5} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{
                fontSize: 15,
                borderRadius: "30px",
                fontWeight: "bold",
                mb: "10px",

                // ml: 65,
              }}
              onClick={() => {
                setOpen(true);
              }}
              disabled={freeze}
            >
              Review and Freeze
            </Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              // onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id="transition-modal-title"
                    variant="body1"
                    component="h2"
                  >
                    You have clicked on
                  </Typography>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    FREEZ INFORMATION
                  </Typography>
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    We request you to kindly review the information once again
                    and then freeze the data, as once its FREEZE can’t be
                    changed. Thank you
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={marksSave}
                    >
                      FREEZE
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Review
                    </Button>
                  </Stack>
                </Box>
              </Fade>
            </Modal>


            <Modal
              open={success}
              // onClose={() => setSucess(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div sx={style}>
                <SucessModal
                  text="Marks Uploaded Successfully"
                  name="Marks Uploaded Successfully"
                />
              </div>
            </Modal>
            {/* <Button variant="outlined">SKIP Creating Time for now</Button> */}
            {/* <Button
              variant="contained"
              color="warning"
              // onClick={onPause}
              sx={{
                fontSize: 15,
                fontWeight: "bold",
                borderRadius: "30px",
                ml: 3,
              }}
            >
              Pause and Continue Setup Later
            </Button> */}
          </Grid>

          <Grid item xs={3.5} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="error"
              sx={{
                color: "white",
                borderRadius: "30px",
                fontSize: 15,
                width: 120,
                fontWeight: "bold",
                mb: "10px",
              }}
              onClick={generatePDF}
            >
              pdf
            </Button>
          </Grid>

          <Grid item xs={3.5} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="error"
              sx={{
                fontSize: 15,
                fontWeight: "bold",
                borderRadius: "30px",
                // ml: 5,
                width: "120px",
                backgroundColor: "#ca424f",
                "&:hover": { backgroundColor: "#ca424f" },
              }}
              onClick={onLaunchpad}
            >
              EXIT{" "}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
