import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SchoolMaster } from "../../services/SchoolMaster";
import { yearService } from "../../services/yearService";
import { BuildSchoolControll } from "../../services/buildSchoolControllService";
import { AddStandarsService } from "../../services/addStandardsService";
import { SubjectService } from "../../services/subjectService";
import { DesignationService } from "../../services/DesignationService";
import { FeeStructure } from "../../services/FeeStructure";
import { BuildSchoolExam } from "../../services/examStandards";
import { StandardSubjectService } from "../../services/StandardSubject";
import { getUniqueDomId } from "@fullcalendar/core";
import axios from "axios";

const AcadmicYear = () => {
  const [school, setSchool] = useState();
  const [yearList, setYear] = useState([]);
  const [progress, setProgress] = useState(30);
  const [openLoad, setOpenLoad] = useState(false);
  const [standard,setStandard] = useState([]);

  useEffect(() => {
    loadSchool();
    loadYear();
  }, []);

  async function loadYear() {
    let pageNo = 0;
    try {
      let response = await yearService.yearListAll(pageNo);
      setYear(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadSchool() {
    try {
      let response = await SchoolMaster.schoolList();
      let yearData = JSON.parse(localStorage.getItem('academicYear'))
      let filtschool = response.data.data.filter(r => r.yearName == yearData.yearName);
      console.log(filtschool);
      setSchool(filtschool);
      console.log(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let year;
    // setOpenLoad(true);
    const data = new FormData(event.currentTarget);
    year = data.get("year");

    if (!year) {
      alert("Please enters new year");
      return;
    }

    let ExsitingYear = yearList.find((rec) => rec.yearName === year);
    // console.log(ExsitingYear);
    if (ExsitingYear) {
      alert("Entered year already exists");
    } else {
      console.log(data.get("year"));
      try {
        let response = await yearService.createYear({ yearName: year });
        // onSchoolMigrate(yearName,yearID);
        onCreateBuildschool(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  async function onCreateBuildschool(newYear) {
    let { yearName, yearID } = newYear;

    // let nextSch = [];
    // nextSch.push({
    //   currentAcademicYearStatus: school[0].currentAcademicYearStatus,
    //   migrationStatus: school[0].migrationStatus,
    //   schoolAddress: school[0].schoolAddress,
    //   schoolAuthorizedPersonMobile: school[0].schoolAuthorizedPersonMobile,
    //   schoolAuthorizedPersonOneEmail: school[0].schoolAuthorizedPersonOneEmail,
    //   schoolAuthorizedPersonOneName: school[0].schoolAuthorizedPersonOneName,
    //   schoolAuthorizedPersonTwoEmail: school[0].schoolAuthorizedPersonTwoEmail,
    //   schoolAuthorizedPersonTwoMobile: school[0].schoolAuthorizedPersonTwoMobile,
    //   schoolAuthorizedPersonTwoName: school[0].schoolAuthorizedPersonTwoName,
    //   schoolLogoUrl: school[0].schoolLogoUrl,
    //   schoolName: school[0].schoolName,
    //   schoolNameonDocs: school[0].schoolNameonDocs,
    //   schoolOfficialEmailID: school[0].schoolOfficialEmailID,
    //   schoolOfficialMobileOne: school[0].schoolOfficialMobileOne,
    //   schoolOfficialMobileTwo: school[0].schoolOfficialMobileTwo,
    //   schoolTrustName: school[0].schoolTrustName,
    //   yearID: yearID,
    //   yearName: yearName
    // })
    //  console.log(nextSch)

    let standard = [
      "standard",
      "subject",
      "designation",
      "timetable",
      "feestructure",
      "exam",
      "buildschoolcomplete",
    ];

    let step = ["one", "two", "three", "four", "five", "six", "seven"];

    for (let i = 0; i <= 6; i++) {
      console.log("onBuildSchool" + i);
      if (i == 0) {
        console.log("if");

        try {
          let response = await BuildSchoolControll.createBuildSchoolStatus({
            activeStatus: "active",
            buildSchoolName: standard[i],
            buildSchoolStatus: "false",
            buildSchoolStep: step[i],
            buildSchoolYear: yearName,
            yearID: yearID,
            yearName: yearName,
          });
        } catch (error) {
          alert("Error creating buildSchool " + error);
        }
      } else {
        console.log("else");

        try {
          let response = await BuildSchoolControll.createBuildSchoolStatus({
            activeStatus: "active",
            buildSchoolName: standard[i],
            buildSchoolStatus: "false",
            buildSchoolStep: step[i],
            buildSchoolYear: yearName,
            yearID: yearID,
            yearName: yearName,
          });
        } catch (error) {
          alert("Error creating buildSchool " + error);
        }
      }
    }


    console.log("Standard ");
    console.log(school);
  let nextSch = [];
nextSch.push({
  currentAcademicYearStatus: school[0].currentAcademicYearStatus,
  deleteStatus: true,
  migrationStatus: school[0].migrationStatus,
  schoolAddress: school[0].schoolAddress,
  schoolAuthorizedPersonMobile: school[0].schoolAuthorizedPersonMobile,
  schoolAuthorizedPersonOneEmail: school[0].schoolAuthorizedPersonOneEmail,
  schoolAuthorizedPersonOneName: school[0].schoolAuthorizedPersonOneName,
  schoolAuthorizedPersonTwoEmail: school[0].schoolAuthorizedPersonTwoEmail,
  schoolAuthorizedPersonTwoMobile: school[0].schoolAuthorizedPersonTwoMobile,
  schoolAuthorizedPersonTwoName: school[0].schoolAuthorizedPersonTwoName,
  schoolLogoUrl: school[0].schoolLogoUrl,
  schoolName: school[0].schoolName,
  schoolNameonDocs: school[0].schoolNameonDocs,
  schoolOfficialEmailID: school[0].schoolOfficialEmailID,
  schoolOfficialMobileOne: school[0].schoolOfficialMobileOne,
  schoolOfficialMobileTwo: school[0].schoolOfficialMobileTwo,
  schoolTrustName: school[0].schoolTrustName,
  yearID: yearID,
  yearName: yearName
})
 console.log(nextSch);
//  try{
//   let response = await SchoolMaster.createSchool(nextSch[0])
// console.log(response)
//  } catch(error) {
//   console.log(error)
//  }
try{
  let response = await axios.post("http://139.59.11.130:6561/api/school",nextSch)
  console.log(response)
} catch(error) {
  console.log(error)
}


    onStandardsMigration(yearName, yearID);
    // onStdSubMigration(yearName,yearID);

    //   const timer = setInterval(() => {
    //     setProgress((prevProgress) =>
    //       prevProgress >= 100 ? 0 : prevProgress + 10
    //     );
    //   }, 800);
    //   return () => {
    //     clearInterval(timer);
    //   };
    //   navigate("/dashborad", { replace: true });
  }

  async function onStandardsMigration(yearName, yearID) {
    let prevStandardData = [];
    let nextStandardData = [];
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      console.log(response.data.data);
      prevStandardData = response.data.data;
      setStandard(response.data.data)
    } catch (error) {
      console.log(error);
    }
    
    if (prevStandardData) {
      prevStandardData.forEach((rec) => {
        nextStandardData.push({
          standardName: rec.standardName,
          yearID: yearID,
          yearName: yearName,
        });
      });
      console.log(nextStandardData);

      if (nextStandardData) {
        try {
          nextStandardData.forEach(async (rec) => {
            let response = await AddStandarsService.createAddStandards(rec);
          });
        } catch (error) {
          console.log(error);
          console.log("standard migration stoped");
        }
      }
    } else {
      console.log("standard migration stoped");
    }

    // ---------------------standard subjects migration-------------------




// -------------------subjects migration----------------
    let prevSubjects = [];
    let nextSubjects=[];

    try{
      let response = await SubjectService.subjectListAll(data.yearName)
      console.log(response.data.data);
      prevSubjects = response.data.data
    }catch(error){
      console.log(error)
    }

    if(prevSubjects) {
      prevSubjects.forEach((r)=>{
        nextSubjects.push({
          subjectName:r.subjectName,
          yearID: yearID,
          yearName: yearName
        })
      })
      console.log(nextSubjects);

      if(nextSubjects){
        try{
          nextSubjects.forEach(async(r)=>{
            let response = await SubjectService.createSubject(r)
          })
        }catch(error){
          console.log(error)
        }
      }
    }else{
      console.log("error occured")
    }

    // -----------Designation migration-------------
    let prevDesignation=[];
    let nextDesignation=[];
    try{
      let response = await DesignationService.designationListAll(data.yearName)
prevDesignation = response.data.data;
    }catch(error){
      console.log(error)
    }

    if(prevDesignation){
      prevDesignation.forEach((d)=>{
        nextDesignation.push({
          designation: d.designation,
  yearID: yearID,
  yearName: yearName
        })
      })
      console.log(nextDesignation)

      if(nextDesignation){
       try{
        nextDesignation.forEach(async(d)=>{
          let response = await DesignationService.createDesignation(d)
        })
       }catch(error){
        console.log(error);
       }
      }
    } else{
      console.log("designation error")
    }

    // -----------Fees migration------------
    let prevFee=[]
    let nextFee=[]

    try{
      let response = await FeeStructure.feeListAll(data.yearName)
      prevFee = response.data.data
      console.log(prevFee);
   }catch(error){
    console.log(error)
   }

   if(prevFee){
    prevFee.forEach((f)=>{
      console.log(f);
      nextFee.push({
        feesTitle:f.feestitleName,
        yearID: yearID,
        yearName: yearName
      })
    })
    console.log(nextFee);

    if(nextFee){
      try{
        nextFee.forEach(async(f)=>{
          console.log(f);
          // let response = await FeeStructure.createFee(f)
          let response = await axios.post("http://139.59.11.130:6561/api/fee",f)
        })
      }catch(error){
        console.log(error)
      }
    }
   } else{
    console.log("Fee migrate error")
   }


  //  ---------------school master migration-----------------


//   let nextSch = [];
// nextSch.push({
//   currentAcademicYearStatus: school[0].currentAcademicYearStatus,
//   migrationStatus: school[0].migrationStatus,
//   schoolAddress: school[0].schoolAddress,
//   schoolAuthorizedPersonMobile: school[0].schoolAuthorizedPersonMobile,
//   schoolAuthorizedPersonOneEmail: school[0].schoolAuthorizedPersonOneEmail,
//   schoolAuthorizedPersonOneName: school[0].schoolAuthorizedPersonOneName,
//   schoolAuthorizedPersonTwoEmail: school[0].schoolAuthorizedPersonTwoEmail,
//   schoolAuthorizedPersonTwoMobile: school[0].schoolAuthorizedPersonTwoMobile,
//   schoolAuthorizedPersonTwoName: school[0].schoolAuthorizedPersonTwoName,
//   schoolLogoUrl: school[0].schoolLogoUrl,
//   schoolName: school[0].schoolName,
//   schoolNameonDocs: school[0].schoolNameonDocs,
//   schoolOfficialEmailID: school[0].schoolOfficialEmailID,
//   schoolOfficialMobileOne: school[0].schoolOfficialMobileOne,
//   schoolOfficialMobileTwo: school[0].schoolOfficialMobileTwo,
//   schoolTrustName: school[0].schoolTrustName,
//   yearID: yearID,
//   yearName: yearName
// })
//  console.log(nextSch)

//  try{
//   let response = await SchoolMaster.createSchool(nextSch)
//  } catch(error) {
//   console.log(error)
//  }
// onSchoolMigrate(yearName, yearID);

    // setOpenLoad(false);
  }

//   async function onSchoolMigrate(yearName, yearID) {

//   let nextSch = [];
// nextSch.push({
//   currentAcademicYearStatus: school[0].currentAcademicYearStatus,
//   migrationStatus: school[0].migrationStatus,
//   schoolAddress: school[0].schoolAddress,
//   schoolAuthorizedPersonMobile: school[0].schoolAuthorizedPersonMobile,
//   schoolAuthorizedPersonOneEmail: school[0].schoolAuthorizedPersonOneEmail,
//   schoolAuthorizedPersonOneName: school[0].schoolAuthorizedPersonOneName,
//   schoolAuthorizedPersonTwoEmail: school[0].schoolAuthorizedPersonTwoEmail,
//   schoolAuthorizedPersonTwoMobile: school[0].schoolAuthorizedPersonTwoMobile,
//   schoolAuthorizedPersonTwoName: school[0].schoolAuthorizedPersonTwoName,
//   schoolLogoUrl: school[0].schoolLogoUrl,
//   schoolName: school[0].schoolName,
//   schoolNameonDocs: school[0].schoolNameonDocs,
//   schoolOfficialEmailID: school[0].schoolOfficialEmailID,
//   schoolOfficialMobileOne: school[0].schoolOfficialMobileOne,
//   schoolOfficialMobileTwo: school[0].schoolOfficialMobileTwo,
//   schoolTrustName: school[0].schoolTrustName,
//   yearID: yearID,
//   yearName: yearName
// })
//  console.log(nextSch)

//  try{
//   let response = await SchoolMaster.createSchool(nextSch)
//  } catch(error) {
//   console.log(error)
//  }

//   }
  
  return (
    <Container
      component="main"
      // maxWidth="xs"
      sx={{
        backgroundColor: "lightgrey",
        borderColor: "2px solid green",
        borderRadius: "5px",
      }}
    >
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openLoad}
        // onClick={handleClose}
      >
        <CircularProgress variant="determinate" value={progress} />
      </Backdrop>
      <Box
        sx={{
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 1, minWidth: 100 }}
        >
          <h1>{school ? school[0].schoolName : "School Name"}</h1>
          <TextField
            margin="normal"
            required
            fullWidth
            id="year"
            label="Enter Year"
            name="year"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create Academic Year
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
export default AcadmicYear;