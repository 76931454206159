import { Grid, IconButton, Modal, Tab, Tabs } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import Box from "@mui/material/Box";
import { ImageService } from "../../../services/ImageService";
import {
  Alert,
  Avatar,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
// import { useHistory } from 'react-router-dom';
import { Link, useNavigate,  } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import { StandardDivision } from "../../../services/StandardDIvision";
import { AddStandarsService } from "../../../services/addStandardsService";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { C } from "../../util/C";
import { AfterFinalExam } from "../../../services/AfterFinalExam";
import { ImageConverter } from "../../../programs/imgaeToBase";

const FormItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  // height: 200,
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
}));

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));

const Item1 = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  width: "60px",
  // fontWeight: "bold",
  // width: "236px",
}));

const Item2 = styled(Paper)(({ theme }) => ({
  // padding: theme.spacing(1),
  textAlign: "center",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",

  // fontWeight:'bold',
}));

function NewAdmission(props) {
  const handleOpen = () => setOpen(true);
  const [alertVal, setAlertVal] = React.useState("");

  const onNext = () => {
    console.log("onNewAddmisson");
    navigate("/newadmission", { replace: true });
  };

  const grantAndReject = () => {
    setClicked1(true);
    console.log("grantAndReject");
    navigate("/grantandreject", { replace: true });
  };

  const requestForTc = () => {
    setClicked2(true);
    console.log("requestForTc");
    navigate("/acceptrequestfortc", { replace: true });
  };

  const fillNewForm = () => {
    setClicked(true);
    console.log("fillNewForm");
    navigate("/newadmission", { replace: true });
  };

  const [clicked, setClicked] = React.useState(false);
  const [clicked1, setClicked1] = React.useState(false);
  const [clicked2, setClicked2] = React.useState(false);

  const [Standardname, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [Capacity, setCapacity] = React.useState(0);
  const [Division, setDivision] = React.useState([]);
  const [Standard, setStandard] = React.useState([]);
  const [childname, setChildName] = React.useState("");
  const [nameErrorText, setNameErrorText] = React.useState("");
  const [dob, setDob] = React.useState(null);
  const [Age, setAge] = React.useState();
  const [Prof, setProf] = React.useState();
  const [Proff, setProff] = React.useState();
  const [studentName, SetstudentName] = React.useState();
  const [phoneNo, setPhoneNo] = React.useState();
  const [mname, setmname] = React.useState();
  const [fname, setfname] = React.useState();
  const [lang, setlang] = React.useState();
  // const [open, setOpen] = React.useState(false);
  const [nowDate, setDate] = React.useState(null);
  const [studenAge, setStudentAge] = React.useState(0);
  const [gender, setGender] = React.useState("");
  const [phoneNoErrorText, setPhoneNoErrorText] = React.useState("");
  const [siblings, setSiblings] = React.useState("");
  const [fatherQualification, setFatherQualification] = React.useState("");
  const [fatherQualificationErrorText, setFatherQualificationErrorText] =
    React.useState("");
  const [motherQualification, setMotherQualification] = React.useState("");

  const [formNo, setFormNo] = React.useState("");

  const [open, setOpen] = React.useState(false);

  const [academicYear, setacademicYear] = React.useState(
    JSON.parse(localStorage.getItem("academicYear"))
  );

  const style = {
    position: "absolute",
    top: "80%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    height: 150,
    bgcolor: "white",
    // border: "2px solid yellow",
    boxShadow: 24,
    borderRadius:"15px",
    p: 4,
    // color: "darkblue",
    // overflow:"scroll"
  };

  React.useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, null, window.location.href);
    };
    onstandardsLoad();
   
    
  }, []);
  // const history = useHistory();
  // React.useEffect(() => {
  //   const handleBlockedNavigation = (event) => {
  //     const { action } = event;
  //     if (action === 'POP') {
  //       // You can add additional conditions if needed
  //       history.push('/'); // Redirect to a different page or handle the back action
  //     }
  //   };

  //   window.addEventListener('popstate', handleBlockedNavigation);

  //   return () => {
  //     window.removeEventListener('popstate', handleBlockedNavigation);
  //   };
  // }, [history]);

  const updateCount = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AfterFinalExam.getStandaredApplicationFormCount(
        data.yearName
      );
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    C.c(data);
    // let academicYear = "2024-2025";
    try {
      let response = await AfterFinalExam.getStandaredApplicationFormCount(
        data.yearName
      );
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }

    try {
      let response = await AfterFinalExam.getApplicationFormNumber(
        data.yearName
      );
      C.c(response.data.data);
      setFormNo(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };

  async function onStandardSelect(e, index) {
    // debugger
    e.preventDefault();

    setStandardID(e.target.id);
    C.c(e.currentTarget.id);
    console.log(e.target.textContent);
    setStandardName(e.target.textContent);
    console.log(Standardname);
    // let data = JSON.parse(localStorage.getItem("academicYear"));
    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index][
      "isSelected"
    ]
      ? false
      : true;

  }

  const textInput = React.useRef("");
  const onlyCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^zA-Z ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  // const ageCalu = (dob) => {
  //   C.c(dob);
  //   const birthDate = new Date(dob);
  //   const difference = Date.now() - birthDate.getTime();
  //   const age = new Date(difference);
  //   setStudentAge(Math.abs(age.getUTCFullYear() - 1970));
  // };

  const ageCalu = (dob) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();

    if (birthDate > currentDate) {
      setStudentAge("Date of birth is in the future");
      return;
    }

    let yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();
    let monthsDiff = currentDate.getMonth() - birthDate.getMonth();

    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff = 12 + monthsDiff;
    }

    if (yearsDiff === 0 && monthsDiff === 0) {
      setStudentAge("Born today");
    } else if (yearsDiff === 0) {
      setStudentAge(`${monthsDiff} month${monthsDiff > 1 ? "s" : ""}`);
    } else if (monthsDiff === 0) {
      setStudentAge(`${yearsDiff} y ${yearsDiff > 1 ? "s" : ""}`);
    } else {
      setStudentAge(
        `${yearsDiff} y ${yearsDiff > 1 ? "" : ""} ${monthsDiff} m ${
          monthsDiff > 1 ? "" : ""
        }`
      );
    }
  };

  const validatephoneNo = (e) => {
    if (e.target.value.toString().length > 9) {
      e.preventDefault();
      return;
    }
  };

  const [imageUrl, setImageUrl] = React.useState("");
  const [imageSrc, setImageSrc] = React.useState();
  // const fileBrowseHandler = (event) => {
  //   let value = URL.createObjectURL(event.currentTarget.files[0]);
  //   setImageSrc(event.currentTarget.files[0]);
  //   C.c(value);
  //   // C.c(filevalue);
  //   setImageUrl(value);
  //   // setImageSrc(filevalue);
  //   C.c(imageSrc);
  // };

  const handleChange = (event) => {
    setGender(event.target.value);
  };

  const Characters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9 ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };
  const onlySpecialCharaters = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^zA-Z. ]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };

  const [sizeModalOpen, setSizeModalOpen] = React.useState(false);
  const [typeModalOpen, setTypeModalOpen] = React.useState(false);
  
  const handleCloseSizeModal = () => setSizeModalOpen(false);
  const handleCloseTypeModal = () => setTypeModalOpen(false);

  const handleFileUpload = async(e) => {
    const file = e.target.files[0];
    e.target.value = null;
    if (file.size > 2 * 1024 * 1024) {
      setSizeModalOpen(true); 
      return;
    }
   const validFormats = ['image/jpeg', 'image/png'];
    if (!validFormats.includes(file.type)) {
      setTypeModalOpen(true); 
      return;
    }
    const base64 = await ImageConverter.convertToBase64(file);
    console.log(base64);
    setImageUrl(base64);
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    // C.c(data);
    // const fileData = new FormData();
    // fileData.append("file", imageSrc);
    // C.c(fileData.get("file"));

    // let fileUrl;

    // try {
    //   let response = await ImageService.ImageUrl(fileData);
    //   fileUrl = response.data.data.fileUrl;
    //   C.c(response.data.data);
    // } catch (error) {
    //   C.c(error.message);
    // }

    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    console.log(yearData);
    // debugger
    let newForm = {
      address: data.get("address"),
      // admissionFormNumber:data.get("newAdmission"),
      admissionFormNumber: formNo.newAdmissionFormNumber,
      age: (data.get("studentAge")),
      childName: data.get("studentName"),
      // dob:  data.get("studentDOB"),
      dob: nowDate.toLocaleDateString("en-GB"),
      fatherContactNumber: data.get("phoneNo1"),
      fatherName: data.get("fatherName"),
      fatherProfession: data.get("fatherProfession"),
      fatherQualification: data.get("fatherQualification"),
      firstLanguage: data.get("firstLang"),
      gender: data.get("studentGender"),
      motherContactNumber: data.get("phoneNo2"),
      motherName: data.get("motherName"),
      motherProfession: data.get("motherprofession"),
      motherQualification: data.get("motherQualification"),
      previousSchool: data.get("previousSchool"),
      religion: data.get("religion"),
      siblings: parseInt(data.get("siblings")),
      studentProfileUrl: imageUrl,
      standared: Standardname,
      standaredId: StandardID,
      yearName: yearData.yearName,
      yearID: yearData.yearID,
    };
    console.log(newForm);

    try {
      let response = await AfterFinalExam.createNewStudentApplicationForm(
        newForm
      );
      C.c(response.data.data);
      setOpen(true);
     SetstudentName("");
     setDate("");
     setStudentAge("");
     setProf("");
     setProff("");
     setPhoneNo("");
     setmname("");
     setfname("");
     setlang("");
     setGender("");
     setSiblings("");
     setFatherQualification("");
     setMotherQualification("");
     setImageUrl("");
      onstandardsLoad();
    } catch (error) {
      C.c(error.message);
    }

    try {
      let response = await AfterFinalExam.getApplicationFormNumber(
        yearData.yearName
      );
      C.c(response.data.data);
    } catch (error) {
      C.c(error.message);
    }
  };

  // const updateForm = async() =>{
  //   let yearData = JSON.parse(localStorage.getItem("academicYear"));

  //   try {
  //     let response = await AfterFinalExam.getApplicationFormNumber(yearData.yearName)
  //     C.c(response.data.data);
  //   } catch (error) {
  //     C.c(error.message);
  //   }

  //   try {
  //     let response = await AfterFinalExam.getStandaredApplicationFormCount(
  //       yearData.yearName
  //     );
  //     C.c(response.data.data);
  //     setStandard(response.data.data);
  //   } catch (error) {
  //     C.c(error);
  //   }

  // }

  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };

  return (
    <>
      <div>
        {/* <LaunchpadTopBar id="afterfinalexam" /> */}
        <Box component="form" onSubmit={onFormSubmit}>
          {/* <Grid container sx={{ mt: 1 }}> */}
            {/* <Grid item xs={3.5} sx={{ ml: 14 }}>
              <Button
                fullWidth
                size="small"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  variant: "outlined",
                  border: "1px solid black",
                  borderRadius: "1px",
                  // width: "555px",
                  ":hover": {
                    backgroundColor: "#1565c0",
                    color: "white",
                    boxShadow: "5px 5px 5px  black",
                  },
                  backgroundColor: clicked ? "yellow" : "white",
                }}
                onClick={fillNewForm}
              >
                FILL NEW ADMISSION FORM FOR THE NEXT ACADEMIC YEAR{" "}
              </Button>
            </Grid> */}
            {/* ----------------------------------------------GRANT / REJECT--------------------------------------------------------*/}
            {/* <Grid xs={3.5}>
              <Button
                fullWidth
                size="small"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  variant: "outlined",
                  border: "1px solid black",
                  borderRadius: "1px",
                  // width: "410px",
                  ":hover": {
                    backgroundColor: "#1565c0",
                    color: "white",
                    boxShadow: "5px 5px 5px  black",
                  },
                  backgroundColor: clicked1 ? "yellow" : "white",
                }}
                onClick={grantAndReject}
              >
                GRANT / REJECT ADMISSION FOR NEXT ACADEMIC YEAR{" "}
              </Button>
            </Grid> */}

            {/*--------------------------------------------------------- ACCEPT REQUEST FOR TC AND LC ------------------------------------------*/}
            {/* <Grid xs={3}>
              <Button
                fullWidth
                size="small"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  variant: "outlined",
                  border: "1px solid black",
                  borderRadius: "1px",
                  // width: "270px",
                  ":hover": {
                    backgroundColor: "#1565c0 ",
                    color: "white",
                    boxShadow: "5px 5px 5px  black",
                  },
                  backgroundColor: clicked2 ? "yellow" : "white",
                }}
                onClick={requestForTc}
              >
                ACCEPT REQUEST FOR TC AND LC{" "}
              </Button>
            </Grid> */}
          {/* </Grid> */}

          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5px",
            padding: "5px",
          }}
        > */}
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              backgroundColor: "lightblue",
              my: 1,
              fontWeight: "bold",
            }}
          >
            FORMS ACCEPTED FOR THE NEW ADMISSION{" "}
            {academicYear.yearName ? academicYear.yearName : "No year selected"}
          </Typography>
          {/* </Box> */}

          <Grid container spacing={2} padding={"5px"}>
            <Grid item md={8} xs={12} >
              <Item2>
                <Grid container spacing={0} sx={{ minHeight: 40 }}>
                  {Standard.map((element, index) => (
                    <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                      <ItemS
                        id={element.standardID}
                        name={element.standardName}
                        onClick={(e)=> {
                          onStandardSelect(e, index)
                        }}
                        sx={{ fontWeight: "bold" }}
                        className={element.isSelected ? "Selected" : ""}
                      >
                        {element.standardName}
                        {/* sx={{ fontWeight: "bold" }} */}
                      </ItemS>

                      <Typography
                        size="large"
                        sx={{
                          // width: "120px",
                          border: "1px solid black",
                          height: 30,
                          borderRadius: 1,
                          //marginLeft:3
                        }}
                        inputProps={{
                          style: {
                            fontSize: 25,
                            height: 30,
                            fontWeight: "bold",
                          },
                        }}
                      >
                        {element.issuedFormCount}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Item2>
            </Grid>
            <Grid item md={0.5} ></Grid>
            <Grid item md={3.5} xs={12}>
              <Typography
                sx={{
                  // width: "160px",
                  border: "1px solid black",
                  //marginRight: 3,
                  fontWeight: "bold",
                  fontSize: 15,
                  textAlign: "center",
                  height: 33,
                  // marginRight:'100px',
                  alignContent: "center",
                  // ml: 18,
                  backgroundColor: "lightgrey",
                }}
              >
                NEW ADMISSION FORM NO.
              </Typography>
              <Typography
                size="small"
                // name="newAdmissionForm"
                sx={{
                  border: "1px solid black",
                  // ml: 18,
                  textAlign: "center",
                  height: 32.5,
                  fontWeight: "bold",
                }}
                inputProps={{
                  style: {
                    fontSize: 20,
                    height: 43,
                    fontWeight: "bold",
                    backgroundColor: "yellow",
                    mb: 2,
                    textAlign: "center",
                  },
                }}
              >
                {formNo.newAdmissionFormNumber}
              </Typography>
              <TextField
                sx={{ display: "none" }}
                value={formNo.newAdmissionForm}
                name="newAdmission"
              >
                {formNo.newAdmissionFormNumber}
              </TextField>
            </Grid>
            {/* </Box> */}
          </Grid>
          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        > */}
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              backgroundColor: "lightblue",
              my: 1,
              fontWeight: "bold",
            }}
          >
            {" "}
            ENTER INFORMATION AND ACCEPT THE NEW ADMISSION FORM FOR{" "}
            {Standardname}
          </Typography>
          {/* </Box> */}

          <Box sx={{ flexGrow: 2 }}>
            <Grid container spacing={2} sx={{ mt: 1,  backgroundColor: "white" }}>
              <Grid item md={10} xs={9}>
                {/* <FormItem > */}
                  <Grid container direction="row">
                        <Grid item md={3} xs={6}>
                        <TextField
                          required
                          fullWidth
                          // id="outlined-basic"
                          label="NAME OF THE CHILD AS ON BIRTH CERTIFICATE"
                          // sx={{
                          //   width: 450,
                          // }}
                          inputProps={{ style: { height:30 } }}
                          value={studentName}
                          variant="filled"
                          size="small"
                          name="studentName"
                          error={!!nameErrorText}
                          helperText={nameErrorText}
                          onInput={(e) => onlyCharaters(e)}
                          onChange={(e) => SetstudentName(e.target.value)}
                          type="string"
                        />

                        </Grid>
                      <Grid item md={1.5} xs={3}>
                      <LocalizationProvider required dateAdapter={AdapterDateFns}>
                          <DatePicker
                        inputFormat="dd/MM/yyyy"
                        format="dd/MM/yyyy"
                            label="DOB"
                            value={nowDate}
                            onChange={(newDate) => {
                              console.log(nowDate);
                              console.clear();
                              console.log(newDate);
                              setDate(newDate);
                              ageCalu(newDate);
                            }}
                            // name="studentDOB"
                            sx={{backgroundColor:"#eeeeee"}}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                size="small"
                                name="studentDOB"
                              
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={1.5} xs={3}>
                      <TextField
                          label="AGE"
                          inputProps={{
                            maxLength: 2,
                            readOnly: true,
                          style:{height:30}
                          }}
                          // sx={{
                          //   width: 180,
                          // }}
                          variant="filled"
                          size="small"
                          name="studentAge"
                          value={studenAge}
                        />
                      </Grid>
                      <Grid item md={1.5} xs={2.5}>
                      <FormControl
                        fullWidth
                          variant="filled"
                          size="small"
                          // sx={{ m: 0, width: 180 }}
                          inputProps={{ style: { height:57} }}
                        >
                          <InputLabel id="gender">Gender</InputLabel>
                          <Select
                            value={gender}
                            onChange={handleChange}
                            name="studentGender"
                            //inputProps={{style:{backgroundColor:'white'}}}
                            sx={{
                            height:55
                            }}
                          >
                            <MenuItem value={"Male"}>👦🏽 Male</MenuItem>
                            <MenuItem value={"Female"}>👧🏽 Female</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={1.5} xs={3}>
                      <TextField
                      fullWidth
                          inputProps={{ style: { height:30 } }}
                          label="RELIGION"
                          // sx={{
                          //   width: 200,
                          // }}
                          onInput={(e) => onlyCharaters(e)}
                          variant="filled"
                          size="small"
                          name="religion"
                        />
                      </Grid>
                      <Grid item md={2} xs={4}>
                      <TextField
                        fullWidth
                          inputProps={{ style: {height:30} }}
                          label="FIRST LANGUAGE"
                          variant="filled"
                          onInput={(e) => onlyCharaters(e)}
                          value={lang}
                          size="small"
                          name="firstLang"
                        />
                      </Grid>
                      <Grid item md={1} xs={2.5}>
                      <TextField
                          inputProps={{ style: { height:30 } }}
                          label="SIBLINGS"
                          variant="filled"
                          // onInput={(e) => onlyCharaters(e)}
                          value={lang}
                          size="small"
                          name="siblings"
                        />
                      </Grid>
                   
                  </Grid>
                  
                  <Stack direction="row" sx={{ mt: 2 }}>
                    <TextField
                      // inputProps={{ style: { backgroundColor: "white" } }}
                      label="PREVIOUS SCHOOL "
                      variant="filled"
                      size="small"
                      name="previousSchool"
                      onInput={(e) => onlyCharaters(e)}
                      fullWidth={true}
                    />
                  </Stack>

                  <Grid container sx={{ mt: 2 }}>
                    <Grid item md={3} xs={6}>
                      <TextField
                        // inputProps={{ style: { backgroundColor: "white" } }}
                        // id="outlined-basic"
                        fullWidth
                        label="FATHER’S NAME AS ON AADHAR"
                        // sx={{
                        //   width: 500,
                        //   // backgroundColor: 'light pink'
                        // }}
                        variant="filled"
                        size="small"
                        // onChange={forfName}
                        value={fname}
                        name="fatherName"
                        onInput={(e) => onlyCharaters(e)}
                        error={!!nameErrorText}
                        helperText={nameErrorText}
                      />
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <TextField
                      fullWidth
                        name="phoneNo1"
                        label="CONTACT NO "
                        type="number"
                        maxLength="8"
                        value={phoneNo}
                        inputProps={{
                          maxLength: 10,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          // style: { backgroundColor: "white" },
                        }}
                        // sx={{
                        //   width: 200,
                        // }}
                        variant="filled"
                        size="small"
                        error={!!phoneNoErrorText}
                        helperText={phoneNoErrorText}
                        onChange={(e) => setPhoneNo(e.target.value)}
                        onKeyPress={validatephoneNo}
                      />
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <TextField
                      fullWidth
                        name="fatherQualification"
                        value={fatherQualification}
                        inputRef={textInput}
                        onInput={(e) => onlySpecialCharaters(e)}
                        InputProps={{
                          inputProps: {
                            minLength: 2,
                          },
                          // style: { backgroundColor: "white" },
                        }}
                        error={!!fatherQualificationErrorText}
                        helperText={fatherQualificationErrorText}
                        onChange={(e) => setFatherQualification(e.target.value)}
                        label="QUALIFICATION "
                        // sx={{
                        //   width: 280,
                        // }}
                        variant="filled"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <TextField
                      fullWidth
                        name="fatherProfession"
                        label="PROFESSION"
                        // sx={{
                        //   width: 600,
                        // }}
                        // inputProps={{ style: { backgroundColor: "white" } }}
                        variant="filled"
                        size="small"
                        onInput={(e) => onlyCharaters(e)}
                        value={Prof}
                      />
                    </Grid>
                  </Grid>

                  <Stack direction="row" sx={{ mt: 2 }}>
                    <TextField
                      name="address"
                      label="ADDRESS "
                      variant="filled"
                      size="small"
                      fullWidth={true}
                      // inputProps={{ style: { backgroundColor: "white" } }}
                    />
                  </Stack>

                  <Grid container direction="row" sx={{ mt: 2 }}>
                   <Grid item md={3} xs={6}>
                   <TextField
                   fullWidth
                      // id="outlined-basic"
                      name="motherName"
                      label="MOTHER’S NAME AS ON AADHAR"
                      // sx={{
                      //   width: 500,
                      // }}
                      // inputProps={{ style: { backgroundColor: "white" } }}
                      variant="filled"
                      size="small"
                      onInput={(e) => onlyCharaters(e)}
                      // onChange={formName}
                      value={mname}
                      error={!!nameErrorText}
                      helperText={nameErrorText}
                    />
                   </Grid>
                   <Grid item md={3} xs={6}>
                   <TextField
                   fullWidth
                      // id="outlined-basic"
                      type="number  "
                      name="phoneNo2"
                      label="CONTACT NO "
                      // sx={{
                      //   width: 200,
                      // }}
                      onKeyPress={validatephoneNo}
                      inputProps={{
                        maxLength: 10,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        // style: { backgroundColor: "white" },
                      }}
                      variant="filled"
                      size="small"
                      error={!!phoneNoErrorText}
                      helperText={phoneNoErrorText}
                    />
                   </Grid>

                   <Grid item md={3} xs={6}>
                   <TextField
                   fullWidth
                      // id="outlined-basic"
                      name="motherQualification"
                      label="QUALIFICATION "
                      // sx={{
                      //   width: 280,
                      // }}
                      onInput={(e) => onlySpecialCharaters(e)}
                      inputRef={textInput}
                      InputProps={{
                        inputProps: {
                          minLength: 2,
                          // style: { backgroundColor: "white" },
                        },
                      }}
                      //     error={!!motherQualificationErrorText}
                      // helperText={motherQualificationErrorText}
                      // onChange={(e) => setMotherQualification(e.target.value)}
                      variant="filled"
                      size="small"
                    />
                   </Grid>
                    <Grid item md={3} xs={6}>
                    <TextField
                    fullWidth
                      // id="outlined-basic"
                      name="motherprofession"
                      label="PROFESSION"
                      onInput={(e) => onlyCharaters(e)}
                      // sx={{
                      //   width: 600,
                      // }}
                      // inputProps={{ style: { backgroundColor: "white" } }}
                      variant="filled"
                      size="small"
                      // onChange={forProff}
                      value={Proff}
                      // fullWidth={true}
                    />
                    </Grid>
                  </Grid>
                {/* </FormItem> */}
                <Stack spacing={3} direction="row" sx={{ mt: 2, ml: 5 }}>
                  {/* </ButtonGroup> */}
                </Stack>
              </Grid>
              <Grid item md={2} xs={3}>
              <Box marginTop={"90px"} >
             <Box display={"flex"} justifyContent={"center"}>
              <Avatar
                  alt="A"
                  src={imageUrl}
                  sx={{ width: 100, height: 100,borderRadius: "5%" }}
                  variant="square"
                />
             </Box>
             <Box display={"flex"} justifyContent={"center"} marginTop={2}>
              <input
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  accept="image/*"
                  name="studentImg"
                  onChange={handleFileUpload}
                  // onChange={fileBrowseHandler}
                />
                <Modal
                  open={sizeModalOpen}
                  onClose={handleCloseSizeModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography variant="h6" textAlign="center">
                      <b>The file size exceeds the 2MB limit. Please upload a file less than 2MB.</b>
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }}>
                      <Button color="success" size="small" variant="contained" onClick={handleCloseSizeModal}>
                        Close
                      </Button>
                    </Box>
                  </Box>
                </Modal>
                <Modal
                  open={typeModalOpen}
                  onClose={handleCloseTypeModal}
                  aria-labelledby="modal-type-title"
                  aria-describedby="modal-type-description"
                >
                  <Box sx={style}>
                    <Typography variant="h6" textAlign="center">
                      <b>The file must be in JPEG or PNG format. Please upload a valid file.</b>
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }}>
                      <Button color="success" size="small" variant="contained" onClick={handleCloseTypeModal}>
                        Close
                      </Button>
                    </Box>
                  </Box>
                </Modal>
                <label htmlFor="contained-button-file">
                  <Button
                    required
                    variant="contained"
                    color="primary"
                    component="span"
                    sx={{borderRadius: "25px" }}
                  >
                    Upload
                  </Button>
                </label>
             </Box>
              
              {/* <Input components="input" type="file" name="filename" /> */}
            </Box>
              </Grid>
            </Grid>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 1,
                ml: 120,
                mt: 1,
                borderRadius: 1,
              }}
            > */}
            <Box display={"flex"} justifyContent={{xs:'center', md:"end"}}  sx={{ marginTop: "20px" }} paddingBottom={"25px"}>
              {/* <Grid item xs={2.5} > */}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: "20px",
                    padding: "10px 40px 10px 40px",
                    fontWeight: "bold",
                    marginRight:"20px"
                  }}
                  type="submit"
                  // onClick={handleOpen}
                  // onClick={onstandardsLoad}
                >
                  UPDATE AND PRINT FORM
                </Button>
              {/* </Grid> */}
              {/* <Grid item xs={1}> */}
                <Button
                  onClick={onLogout}
                  variant="contained"
                  sx={{
                    backgroundColor: "#ca424f",
                    borderRadius: "30px",
                    fontSize: 15,
                    fontWeight: "bold",
                    width: "120px",
                    "&:hover": { backgroundColor: "#ca424f" },
                  }}
                >
                  EXIT
                </Button>
              {/* </Grid> */}
            </Box>
            <Modal
              open={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box
                  sx={{
                    direction: "rtl",
                  }}
                >
                  <IconButton
                    color="error"
                    onClick={() => setOpen(false)}
                    aria-label="delete"
                    size="large"
                  >
                    <HighlightOffIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                <Typography variant="h6" textAlign="center">
                  <b>NEW APPLICATION FORM HAS BEEN GENERATED SUCCESSFULLY !</b>
                </Typography>
              </Box>
            </Modal>

            {/* </Box> */}

            {/* <Grid container spacing={2}> */}
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 1,
                ml: 185,
                mt: 1,
                borderRadius: 1,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: 5 ,width:'250px',marginRight:"1px"}}
                type="submit"
                // onClick={handleOpen}
                // onClick={onstandardsLoad}
              >
                UPDATE AND PRINT FORM 
              </Button>

              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Box
                    sx={{
                      direction: "rtl",
                    }}
                  >
                    <IconButton
                      color="error"
                      onClick={() => setOpen(false)}
                      aria-label="delete"
                      size="large"
                    >
                      <HighlightOffIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                  <Typography variant="h6" textAlign="center">
                    <b>NEW APPLICATION FORM HAS BEEN GENERATED SUCCESSFULLY !</b>
                  </Typography>
                </Box>
              </Modal>

              <Button
                variant="contained"
                color="error"
                sx={{ borderRadius: 5, ml: 2, width: 120 }}
                component="span"
              >
                EXIT
              </Button>
            </Box> */}
            {/* </Grid> */}
          </Box>
        </Box>
      </div>
    </>
  );
}

export default NewAdmission;
