import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Fade, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Button, Stack } from "@mui/material";
import Topbar from "../../MainTopbar/Topbar";
import FreezeModal from "../Dashboard/freezeModel";
import StandardComponent from "./StandardComponent";
import Backdrop from "@mui/material/Backdrop";

import { AddStandarsStrengthService } from "../../../services/standardStrength";
import { AddStandarsService } from "../../../services/addStandardsService";

import { SettingsOverscanOutlined } from "@mui/icons-material";
import { StandardDivCapacityService } from "../../../services/StandardDivCapacityService";

import { useNavigate } from "react-router-dom"; //for browser back button navigation handling
import { BuildSchoolControll } from "../../../services/buildSchoolControllService";
import SucessModal from "./popup/sucessPopUp";
import AlertModal from "./popup/alertPopUp";
import DeleteModal from "./popup/deletePopUp";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(0),
  // margin: theme.spacing(6),
  textAlign: "center",
  border: "1px solid ",
  // color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};
export default function BasicGrid() {
  //browser back button redirection to dashboard
  const Navigate = useNavigate();
  const [finishStatus, setfinishStatus] = React.useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back to Dashboard?")) {
        setfinishStatus(true);
        // your logic
        Navigate("/dashborad");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  let [yearID, setYearID] = React.useState();
  let [yearName, setYearName] = React.useState();

  React.useEffect(() => {
    let academicYear = JSON.parse(localStorage.getItem("academicYear"));
    onLoadBuildSchool();
    onStandardStrenghtload();
    // if (status == "true") {
    StandardDivisonList();
    // }
    setYearID(academicYear.yearID);
    setYearName(academicYear.yearName);
    // console.log(academicYear);
    console.log(yearID, yearName);
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  // * =======================window Pop=====================================
  // *delete
  const [openDelete, setDelete] = React.useState(false);
  const handleDeleteOpen = () => setDelete(true);
  const handleDeleteClose = () => setDelete(false);

  // *alert
  const [openAlert, setAlert] = React.useState(false);
  const [alertVal, setAlertVal] = React.useState("");
  const handleAlertOpen = () => setAlert(true);
  const handleAlertClose = () => setAlert(false);

  // *sucess
  const [openSucess, setSucess] = React.useState(false);
  const handleSucessOpen = () => setSucess(true);
  const handleSucessClose = () => setSucess(false);

  // * =======================window Popup end=====================================
  // back button logic ends

  const [open, setOpen] = React.useState(false);
  // const onFreeze = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [standardDivList, setStandardDivList] = React.useState([]);

  const StandardDivisonList = async () => {
    let academicYear = JSON.parse(localStorage.getItem("academicYear"));
    let standardDivList = [];
    let standardDivListFinal = [];
    try {
      let response = await StandardDivCapacityService.StandardDivisionList(
        academicYear.yearID,
        academicYear.yearName
      );
      setStandardDivList(response.data.data);
      standardDivList = response.data.data;
      console.log("aaaa", response.data.data);
    } catch (error) {
      console.error(error);
    }
    standardStrengthList.forEach((rec) => {
      // console.log(rec);
      standardDivList.forEach((r) => {
        if (rec.standardID == r.standardID) {
          standardDivListFinal.push({
            division: r.divisionName,
            capacity: r.maxNo,
          });
        } else {
          standardDivListFinal.push(r);
        }
      });
    });

    console.log(standardDivListFinal);
  };

  // +++++++++++++++++++++++++++++++++
  const onSave = async (event) => {
    event.preventDefault();

    // console.log(standardStrengthList);
    let EmptyStatus = false;
    standardStrengthList.forEach((r) => {
      const filteredArray = r.division.filter(
        (obj) => obj.deleteStatus !== true
      );
      r.division = filteredArray;

      r.division.forEach((rec) => {
        if (rec.maxNo === 0) {
          setAlert(true);
          setAlertVal(`One of the Divison ${rec.divisionName} is Empty !`);
          EmptyStatus = true;
        }
      });
    });
    if (EmptyStatus) return;
    // console.log(standardStrengthList);
    const data = {
      createdTime: 0,
      deleteStatus: false,
      standard: standardStrengthList,
      yearID: yearID,
      yearName: yearName,
    };

    // console.log(data);

    // return false;
    try {
      let response = await StandardDivCapacityService.createStandardDivision(
        data
      );

      if (response.data.success) {
        console.log("success");
        // alert("Strength Added Successfully");
        setOpen(true);
        onBuildSchool();
        // Navigate("/dashborad");
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.log("error");
      setAlertVal("Saving Data Failed");
      handleAlertOpen();
      // alert("Failure");
    }
    // navigate("/dashborad", { replace: true });
  };
  let navigate = useNavigate();

  const [one, setOne] = React.useState();
  const [oneId, setOneId] = React.useState();
  const [two, setTwo] = React.useState();
  const [twoId, setTwoId] = React.useState();
  const [status, setStatus] = React.useState();

  const onLoadBuildSchool = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.buildSchoolStatusListAll(
        data.yearName
      );
      // console.log(response.data.data[0]);
      setStatus(response.data.data[0].buildSchoolStatus);
      setOneId(response.data.data[0].buildSchoolID);
      setTwoId(response.data.data[1].buildSchoolID);
    } catch (error) {
      console.error(error);
    }
  };

  const onBuildSchool = async () => {
    console.log("onBuildSchool");

    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: oneId,
        buildSchoolName: "standard",
        buildSchoolStatus: "true",
        buildSchoolStep: "one",
        buildSchoolYear: data.yearName,
      });
    } catch (error) {
      setAlertVal("Error creating buildSchool " + error);
      handleAlertOpen();
      // alert("Error creating buildSchool " + error);
    }
    try {
      let response = await BuildSchoolControll.updateBuildSchoolStatus({
        activeStatus: "active",
        buildSchoolID: twoId,
        buildSchoolName: "subject",
        buildSchoolStatus: "false",
        buildSchoolStep: "two",
        buildSchoolYear: data.yearName,
      });
    } catch (error) {
      setAlertVal("Error creating buildSchool " + error);
      handleAlertOpen();
      // alert("Error creating buildSchool " + error);
    }

    navigate("/dashborad", { replace: true });
  };

  const onPause = () => {
    setAlertVal("Standard Module will be paused");
    handleAlertOpen();
    localStorage.setItem("data", standardStrengthList);
    // alert("Standard Module will be paused");
    navigate("/dashborad", { replace: true });
  };
  const onPrevious = () => {
    navigate("/addstandards", { replace: true });
  };
  // ===============================

  const [standardStrengthList, setStandardStrengthList] = React.useState([]);

  let allSections = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
  const _defaultSections = 1;
  // let standardArray = [];
  var returnDefultDivisions = () => {
    var retunObj = [],
      finalJSON = [];
    for (let i = 0; i < _defaultSections; i++) {
      retunObj.push({
        divisionName: allSections[i],
        maxNo: 0,
        deleteStatus: false,
      });
    }
    console.log(retunObj);
    return retunObj;
  };

  let [totalStrength, updateTotalStrength] = React.useState(0);
  let [totalSections, updateTotalSections] = React.useState(0);
  let [totalStandards, updateTotalStandards] = React.useState(0);

  let handlers = {
    sectionChange: (event) => {
      var stdIdx = event.target.getAttribute("stdidx");
      console.log("stdIdx " + stdIdx);
      var divisionIdx = event.target.getAttribute("divisionidx");
      var _standards = [...standardStrengthList];
      if (_standards[stdIdx].division == null) {
        _standards[stdIdx].division = returnDefultDivisions();
      }
      _standards[stdIdx].division[divisionIdx].maxNo = parseInt(
        event.target.value
      );
      let totalStrength = 0;
      Array.prototype.slice
        .call(document.getElementsByTagName("input"))
        .forEach(function (el) {
          totalStrength += parseInt(el.value);
        });
      updateTotalStrength(totalStrength);
      updateTotalSections(document.getElementsByTagName("input").length);
      setStandardStrengthList(_standards);
    },
    sectionAdd: (event) => {
      var stdIdx = event.target.getAttribute("stdidx");
      var _standards = [...standardStrengthList];
      if (_standards[stdIdx].division == null) {
        _standards[stdIdx].division = returnDefultDivisions();
      }
      if (
        _standards[stdIdx].division[_standards[stdIdx].division.length - 1]
          .deleteStatus
      ) {
        var idx = _standards[stdIdx].division.findIndex(
          (val) => val.deleteStatus == true
        );
        // alert(_standards[stdIdx].division.length);
        _standards[stdIdx].division[idx].deleteStatus = false;
      } else {
        if (_standards[stdIdx].division.length == 10) {
          setAlertVal("A Standard can't have more than 10 Divisions!");
          handleAlertOpen();
          // alert("A Standard can't have more than 10 Divisions!");
          return;
        }
        _standards[stdIdx].division.push({
          divisionName: allSections[_standards[stdIdx].division.length],
          maxNo: 0,
          deleteStatus: false,
        });
      }

      totalSections = totalSections + 1;
      updateTotalSections(totalSections);
      setStandardStrengthList(_standards);
    },
    sectionRemove: (event) => {
      var stdIdx = event.target.getAttribute("stdidx");
      var _standards = [...standardStrengthList];

      console.log(_standards[stdIdx]);

      if (_standards[stdIdx].division == null) {
        setAlertVal("There should be one Division for a Standard!");
        handleAlertOpen();
      } else if (
        _standards[stdIdx].division.length == 1 &&
        _standards[stdIdx].division[0].deleteStatus == false
      ) {
        setAlertVal("There should be one Division for a Standard!");
        handleAlertOpen();
        return;
      }

      if (
        _standards[stdIdx].division[_standards[stdIdx].division.length - 1]
          .deleteStatus
      ) {
        if (
          _standards[stdIdx].division.filter(function (division) {
            return division.deleteStatus == false;
          }).length == 1
        ) {
          setAlertVal("There should be one Division for a Standard!");
          handleAlertOpen();
          // alert("There should be one Division for a Standard!");
          return;
        }

        if (_standards[stdIdx].division == null) {
          _standards[stdIdx].division = returnDefultDivisions();
        }

        _standards[stdIdx].division.reverse();
        var idx = _standards[stdIdx].division.findIndex(
          (val) => val.deleteStatus == false
        );
        _standards[stdIdx].division[idx].deleteStatus = true;
        _standards[stdIdx].division.reverse();
      } else {
        // alert("else "+_standards[stdIdx].division.filter(function(division){
        //   return division.deleteStatus == false;
        // }).length);

        _standards[stdIdx].division[
          _standards[stdIdx].division.length - 1
        ].deleteStatus = true;
      }

      totalSections = totalSections - 1;
      updateTotalSections(totalSections);
      setStandardStrengthList(_standards);
    },
    default: (event) => {
      console.log(event.target.value);
      event.currentTarget.value = 10;
    },
  };

  const onStandardStrenghtload = async () => {
    // console.log("loading");
    let yearData = JSON.parse(localStorage.getItem("academicYear"));

    try {
      // console.log(response.data.data);
      let response = await AddStandarsService.standardStrengthListAll(
        yearData.yearName
      );
      // console.log(response.data.data);
      setStandardStrengthList(response.data.data);
      updateTotalStandards(response.data.data.length);
      updateTotalSections(response.data.data.length);
    } catch (error) {
      console.log(error);
      // alert("Failure");
    }
  };
  // let isonStandardStrenghtloadCalled = false;
  // const [ locationKeys, setLocationKeys ] = React.useState([])

  // React.useEffect(() => {
  // }, []);

  const setValue = (x) => {
    console.log(x);
  };

  let [count, setCount] = React.useState(1);
  let [sectionA, setsectionA] = React.useState(false);
  let [sectionB, setsectionB] = React.useState(true);
  let [sectionC, setsectionC] = React.useState(true);
  let [sectionD, setsectionD] = React.useState(true);

  const addSessions = (helo) => {
    setCount((count += 1));
    alert(count);
    console.log(count);
    if (count == 2) {
      setsectionB((sectionB = false));
    } else if (count == 3) {
      setsectionC((sectionC = false));
    } else if (count == 4) {
      setsectionD((sectionD = false));
    }
  };

  const removeSessions = () => {
    console.log(count);
    if (count == 2) {
      setsectionB((sectionB = true));
      setCount((count -= 1));
    } else if (count == 3) {
      setsectionC((sectionC = true));
      setCount((count -= 1));
    } else if (count == 4) {
      setsectionD((sectionD = true));
      setCount((count -= 1));
    }
  };

  return (
    <div>
      <Topbar
        text={
          "Step 1 : Build School, Create Standards and Division – Please Click to Select Standard, Enter Division and Seating Capacity"
        }
      />

      <Box display={status == "true" ? "none" : "block"} sx={{ flexGrow: 1 }}>
        <Grid variant="form" container spacing={0}>
          {/* <TextField
            id="outlined-basic"
            label="Outlined"
            variant="outlined"
            // type="number"
            sx={{
              "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
            }}
            // inputProps={{ inputMode: "numeric", maxLength: 2 }}
            InputProps={{
              inputProps: {
                min: 1,
                max: 99,
                // stdidx: stdIdx,
                // divisionidx: index,
                inputMode: "numeric",
                maxLength: 2,
              },
            }}
          /> */}
          {/* {standardStrengthList.map((element) => ( */}
          {/* <Grid key={element.standardID} item xs={2.4}>
            <Item>
              <Typography>{element.standardName}</Typography>
              <DataFields></DataFields>
            </Item>
          </Grid> */}
          {/* ))} */}

          {standardStrengthList.map((standard, stdIdx) => (
            <StandardComponent
              key={stdIdx}
              stdIdx={stdIdx}
              standard={standard}
              handlers={handlers}
            />
          ))}

          {standardStrengthList.map((element, index) => (
            <StandardComponent key={index} element={element}>
              {/* <Typography>{element.standardName}</Typography> */}
            </StandardComponent>
          ))}

          {/* {standardStrengthList.map((element, index) => (

            <Grid key={element.standardID} item xs={2.4}>
              <Item>                
                <Typography sx={{ p: 1 }}>{element.standardName}</Typography>
                <Box sx={{ width: "100%" }}>                  
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1, sm: 2 }}
                  >
                    <Grid item xs={6}>
                      <DataItem>
                      <Typography sx={{ p: 1 }}>A</Typography>
                        <TextField
                          id="outlined-basic"
                          // label="Outlined"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled={sectionA}
                          InputProps={{ inputProps: { min: 10, max: 99 } }}
                          onBlur={setValue(this)}
                          sx={{
                            width: "60px",
                          }}
                        />
                      </DataItem>
                    </Grid>                    
                   
                    <Grid item xs={6}>
                      <DataItem
                        sx={{
                          // display: "none"
                          display: { count },
                        }}
                      >
                        <Typography sx={{ p: 1 }}>B</Typography>
                        <TextField
                          // component="input"
                          id="outlined-basic"
                          // label="Outlined"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled={sectionB}
                          sx={{
                            width: "60px",
                          }}
                        />
                      </DataItem>
                    </Grid>
                    <Grid item xs={6}>
                      <DataItem>
                        <Typography sx={{ p: 1 }}>C</Typography>
                        <TextField
                          id="outlined-basic"
                          // label="Outlined"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled={sectionC}
                          sx={{
                            width: "60px",
                          }}
                        />
                      </DataItem>
                    </Grid>
                    <Grid item xs={6}>                    
                    <DataItem>
                        <Typography sx={{ p: 1 }}>D</Typography>
                        <TextField
                          id="outlined-basic"
                          // label="Outlined"
                          variant="outlined"
                          size="small"
                          type="number"
                          disabled={sectionD}
                          sx={{
                            width: "60px",
                          }}
                        />
                      </DataItem>
                    </Grid>
                  </Grid>
                </Box>
                F
              </Item>
            </Grid>
            
            )
          )} */}
        </Grid>
        <Grid variant="form" container spacing={0}>
          <Grid item md={2.4} xs={4}>
            <Item>
              <Typography variant="h6">Total Standards</Typography>
              <Typography variant="h6">{totalStandards}</Typography>
              {/* <DataFields></DataFields> */}
            </Item>
          </Grid>
          <Grid item md={2.4} xs={4}>
            <Item>
              <Typography variant="h6">Total Divisions</Typography>
              <Typography variant="h6">{totalSections}</Typography>
              {/* <DataFields></DataFields> */}
            </Item>
          </Grid>
          <Grid item md={2.4}xs={4}>
            <Item>
              {/* <Typography>PreKg</Typography> */}
              <Typography variant="h6">Total Capacity</Typography>
              <Typography variant="h6">
                {isNaN(totalStrength) ? "--" : totalStrength}
              </Typography>
              {/* <DataFields></DataFields> */}
            </Item>
          </Grid>
          <Grid item md={4.8}>
            <Item
              sx={{
                backgroundColor: "yellow",
              }}
            >
              {/* <Typography>PreKg</Typography> */}
              <Typography
                color="error"
                sx={{
                  margin: "10px 0 ",
                }}
                variant="subtitle2"
              >
                Please Review the information before FREEZE, Once information is
                Freeze it can’t be changed later.
              </Typography>
              {/* <DataFields></DataFields> */}
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item>
              <Button
                // type="submit"
                onClick={onPrevious}
                variant="contained"
                size="large"
                color="primary"
                
                sx={{
                  margin: "10px",
                  borderRadius: "25px",
                  height:"80px"
                }}
              >
                Previous Page
              </Button>
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item>
              {/* <Box sx={{ margin: "12px" }}>
                <FreezeModal onClick={onSave} id="StandardFreeze" />
              </Box> */}
              <Button
                type="submit"
                onClick={() => {
                  setOpen(true);
                }}
                variant="contained"
                size="large"
                color="primary"
                disabled={status == "true" ? true : false}
                sx={{
                  borderRadius: "25px",
                  margin: "10px",
                  height:"80px"
                }}
              >
                Freeze information
              </Button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                // onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <Typography
                      id="transition-modal-title"
                      variant="body1"
                      component="h2"
                    >
                      You have clicked on
                    </Typography>
                    <Typography
                      id="transition-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      FREEZ INFORMATION
                    </Typography>
                    <Typography
                      id="transition-modal-description"
                      sx={{ mt: 2 }}
                    >
                      We request you to kindly review the information once again
                      and then freeze the data, as once its FREEZE can’t be
                      changed. Thank you
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={onSave}
                      >
                        FREEZE
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => setOpen(false)}
                      >
                        Review
                      </Button>
                    </Stack>
                  </Box>
                </Fade>
              </Modal>
              {/* <DataFields></DataFields> */}
            </Item>
          </Grid>

          <Grid item xs={4}>
            <Item>
              <Button
                type="submit"
                onClick={onPause}
                variant="contained"
                size="large"
                disabled={status == "true" ? true : false}
                color="primary"
                
                sx={{
                  borderRadius: "25px",
                  margin: "10px",
                  height:"80px"
                }}
              >
                pause and Continue later
              </Button>
            </Item>
          </Grid>
        </Grid>
      </Box>

      <Box display={status == "true" ? "block" : "none"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign:"center",
            // alignItems: "center",
            // minHeight: 490,
            m: 2,
          }}
        >
          <h1>"Standard and respected Division has been Freezed❗"</h1>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // minHeight: 490,
            m: 2,
          }}
        >
          <Grid container spacing={2}>
            {standardDivList.map((r, stdIdx) => (
              <Grid item xs={2.4}>
                <Item
                  sx={{
                    backgroundColor: "#aaecac",
                    "&:hover": { background: "#035e06", color: "white" },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {r.standardName} | {r.divisionName}
                  </Typography>
                  {/* <Typography>{r.divisionName}</Typography> */}
                  <Typography>Capacity : {r.maxNo}</Typography>
                </Item>
              </Grid>
            ))}
          </Grid>
          {/* <Grid container spacing={2}>
            {standardDivList.map((r, stdIdx) => (
              <Grid item xs={4}>
                <Item>{r.standardName}</Item>
              </Grid>
            ))}
          </Grid> */}
        </Box>
        {/* <Box display="flex" justifyContent="center">
          {standardDivList.map((standard, stdIdx) => (
            <StandardComponent
              key={stdIdx}
              stdIdx={stdIdx}
              standard={standard}
              handlers={handlers}
            />
           */}
        {/* </Box> */}
      </Box>

      <Modal
        open={openSucess}
        onClose={handleSucessClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <SucessModal name />
        </div>
      </Modal>
      <Modal
        open={openAlert}
        onClose={handleAlertClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <AlertModal text={alertVal} />
        </div>
      </Modal>
      <Modal
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div sx={style}>
          <DeleteModal name />
        </div>
      </Modal>
    </div>
  );
}
