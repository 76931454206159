// import * as React from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
// import { ButtonGroup, Skeleton, Typography } from "@mui/material";
// import Topbar from "../MainTopbar/LaunchPadTopbar";
// import ListEvent from "./events/eventList";
// import ListEventPast from "./events/eventPast";
// import ListEventFuture from "./events/eventFuture";
// import ListEventPresent from "./events/eventPresent";
// import ListEventNextMonth from "./events/eventNextMonth";
// import CalendarEvent from "./events/event";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
// import TableContainer from "@mui/material/TableContainer";
// import Table from "@mui/material/Table";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import { useNavigate } from "react-router-dom";
// import { LaunchPadAuth } from "../util/launchPadAuth";
// import { StaffAttendenanceService } from "../../services/StaffAttendanence";
// import { StudentAttendenanceService } from "../../services/studentAttendance";
// import { StaffService } from "../../services/StaffService";
// import { FeeCollectionStructure } from "../../services/Feescollectionservice";

// const CardItem = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   // ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   height: 150,
//   // color: theme.palette.text.secondary
// }));

// const EventItem = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   // ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   height: 315,
//   // color: theme.palette.text.secondary,
// }));
// const Item = styled(Paper)(({ theme }) => ({
//   // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   // ...theme.typography.body2,
//   padding: theme.spacing(1),
//   border: "2px solid black",
//   height: 100,
//   // textAlign: "center"
//   // color: theme.palette.text.secondary,
// }));

// export default function FullWidthGrid() {
//   let data = JSON.parse(localStorage.getItem("academicYear"));

//   const [value, onChange] = React.useState(new Date());
//   const [girlAbsent, setGirlAbsent] = React.useState(0);
//   const [boyAbsent, setBoyStudent] = React.useState(0);
//   const [studentStrength, setStudentStrength] = React.useState(0);
//   const [studentTotalAbsent, setStudentTotalAbsent] = React.useState(0);
//   const [absentTeachers, setAbsentTeachers] = React.useState(0);
//   const [absentOthers, setAbsentOthers] = React.useState(0);
//   const [totalAbsent, setTotalAbsent] = React.useState(0);
//   const [totalPresent, setTotalPresent] = React.useState(0);
//   const [totalStrength, setTotalStrength] = React.useState(0);
//   const [totalSalary, setTotalSalary] = React.useState();
//   const [totalFee, setTotalFee] = React.useState();

//   const navigate = useNavigate();

//   React.useEffect(() => {
//     console.log("launch pad");
//     let authResp = LaunchPadAuth.authenticateLaunchPad();
//     let pin = JSON.parse(localStorage.getItem("pin"));
//     // console.log(authResp, pin);
//     if (authResp === pin) {
//       console.log("login success");
//       localStorage.removeItem("pin");
//       onStaffAttendenceLoad();
//       onStudentAttendenceLoad();
//       onStudentSalaryLoad();
//       onFeesLoad();
//     } else {
//       console.log("login failure");
//       localStorage.removeItem("pin");
//       navigate("/launchpadlogin", { replace: true });
//     }
//   }, []);

//   const onStudentAttendenceLoad = async () => {
//     let data = JSON.parse(localStorage.getItem("academicYear"));

//     const currentDate = new Date();
//     const iso8601Date = currentDate.toISOString().split("T")[0];
//     const options = { month: "long" };
//     const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);
//     console.log(iso8601Date);

//     try {
//       let response = await StudentAttendenanceService.getTotalReport(
//         iso8601Date,
//         month,
//         data.yearName
//       );
//       console.log(response.data.data);
//       setGirlAbsent(response.data.data.absentGirls);
//       setBoyStudent(response.data.data.absentBoys);
//       setStudentTotalAbsent(response.data.data.totalAbsent);
//       setStudentStrength(response.data.data.total);
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const onStaffAttendenceLoad = async () => {
//     let data = JSON.parse(localStorage.getItem("academicYear"));

//     const currentDate = new Date();
//     const iso8601Date = currentDate.toISOString().split("T")[0];
//     const options = { month: "long" };
//     const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);
//     console.log(iso8601Date);

//     try {
//       let response = await StaffAttendenanceService.DayStaffAttendance(
//         iso8601Date,
//         data.yearName
//       );
//       console.log(response.data.data);
//       // setAbsentTeachers(response.data.data.absantTeachers);
//       // setAbsentOthers(response.data.data.absantOthers);
//       setTotalPresent(response.data.data.totalPresent);
//       setTotalAbsent(response.data.data.totalAbsent);
//       setTotalStrength(response.data.data.total);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const onFeesLoad = async () => {
//     let data = JSON.parse(localStorage.getItem("academicYear"));

//     const currentDate = new Date();
//     const iso8601Date = currentDate.toISOString().split("T")[0];
//     const options = { month: "long" };
//     const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);
//     console.log(iso8601Date);

//     try {
//       let response = await FeeCollectionStructure.getFeesCollectedLaunchPad(
//         iso8601Date,
//         month,
//         data.yearName
//       );
//       console.log(response.data.data);
//       setTotalFee(response.data.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   function GetMonthName(monthNumber) {
//     var months = [
//       "JANUARY",
//       "FEBRUARY",
//       "MARCH",
//       "APRIL",
//       "MAY",
//       "JUNE",
//       "JULY",
//       "AUGUST",
//       "SEPTEMBER",
//       "OCTOBER",
//       "NOVEMBER",
//       "DECEMBER",
//     ];
//     return months[monthNumber];
//   }

//   const onStudentSalaryLoad = async () => {
//     let data = JSON.parse(localStorage.getItem("academicYear"));

//     let month = new Date();
//     let presentMonth = month.getMonth();
//     let nextMonth = month.getMonth() - 1;
//     const pmonth = GetMonthName(presentMonth);
//     const nmonth = GetMonthName(nextMonth);

//     console.log();

//     // const options = { month: "long" };
//     // const pmonth = new Intl.DateTimeFormat("en-US", options).format(
//     //   presentMonth
//     // );
//     // const nmonth = new Intl.DateTimeFormat("en-US", options).format(nextMonth);

//     try {
//       let response = await StaffService.getStaffSalaryLaunchpad(
//         pmonth,```jsx
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { ButtonGroup, Skeleton, Typography } from "@mui/material";
import Topbar from "../MainTopbar/LaunchPadTopbar";
import ListEvent from "./events/eventList";
import ListEventPast from "./events/eventPast";
import ListEventFuture from "./events/eventFuture";
import ListEventPresent from "./events/eventPresent";
import ListEventNextMonth from "./events/eventNextMonth";
import CalendarEvent from "./events/event";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { LaunchPadAuth } from "../util/launchPadAuth";
import { StaffAttendenanceService } from "../../services/StaffAttendanence";
import { StudentAttendenanceService } from "../../services/studentAttendance";
import { StaffService } from "../../services/StaffService";
import { FeeCollectionStructure } from "../../services/Feescollectionservice";
import axios from "axios";

const CardItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  height: 150,
}));

const EventItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  height: 315,
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  border: "2px solid black",
  height: 100,
}));

export default function FullWidthGrid() {
  const data = JSON.parse(localStorage.getItem("academicYear"));

  const [value, onChange] = React.useState(new Date());
  const [girlAbsent, setGirlAbsent] = React.useState(0);
  const [boyAbsent, setBoyStudent] = React.useState(0);
  const [studentStrength, setStudentStrength] = React.useState(0);
  const [studentTotalAbsent, setStudentTotalAbsent] = React.useState(0);
  const [absentTeachers, setAbsentTeachers] = React.useState(0);
  const [absentOthers, setAbsentOthers] = React.useState(0);
  const [totalAbsent, setTotalAbsent] = React.useState(0);
  const [totalPresent, setTotalPresent] = React.useState(0);
  const [totalStrength, setTotalStrength] = React.useState(0);
  const [totalSalary, setTotalSalary] = React.useState();
  const [previous, setPrevious] = React.useState(0);
  const [present, setPresent] = React.useState(0);
  const [totalFee, setTotalFee] = React.useState();

  const navigate = useNavigate();

  // React.useEffect(() => {
  //   const authResp = LaunchPadAuth.authenticateLaunchPad();
  //   const pin = JSON.parse(localStorage.getItem("pin"));
  //   if (authResp === pin) {
  //     localStorage.removeItem("pin");
  //     onStaffAttendenceLoad();
  //     onStudentAttendenceLoad();
  //     onStudentSalaryLoad();
  //     onFeesLoad();
  //   } else {
  //     localStorage.removeItem("pin");
  //     navigate("/launchpadlogin", { replace: true });
  //   }
  // }, []);

  React.useEffect(() => {
    // const authResp = LaunchPadAuth.authenticateLaunchPad();
    // const pin = JSON.parse(localStorage.getItem("pin"));
    // if (authResp === pin) {
      // localStorage.removeItem("pin");
      onStaffAttendenceLoad();
      onStudentAttendenceLoad();
      onStudentSalaryLoad();
      onFeesLoad();
    // } 
    // else {
    //   localStorage.removeItem("pin");
    //   navigate("/launchpadlogin", { replace: true });
    // }
  }, []);

  const onStudentAttendenceLoad = async () => {
    const currentDate = new Date();
    const iso8601Date = currentDate.toISOString().split("T")[0];
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);

    try {
      const response = await StudentAttendenanceService.getTotalReport(
        iso8601Date,
        month,
        data.yearName
      );
      setGirlAbsent(response.data.data.absentGirls);
      setBoyStudent(response.data.data.absentBoys);
      setStudentTotalAbsent(response.data.data.totalAbsent);
      setStudentStrength(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const onStaffAttendenceLoad = async () => {
    const currentDate = new Date();
    const iso8601Date = currentDate.toISOString().split("T")[0];
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);

    try {
      const response = await StaffAttendenanceService.DayStaffAttendance(
        iso8601Date,
        data.yearName
      );
      setTotalPresent(response.data.data.totalPresent);
      setTotalAbsent(response.data.data.totalAbsent);
      setTotalStrength(response.data.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const onFeesLoad = async () => {
    const currentDate = new Date();
    const iso8601Date = currentDate.toISOString().split("T")[0];
    const options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(currentDate);

    try {
      const response = await FeeCollectionStructure.getFeesCollectedLaunchPad(
        iso8601Date,
        month,
        data.yearName
      );
      setTotalFee(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetMonthName = (monthNumber) => {
    const months = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];
    return months[monthNumber];
  };

  const onStudentSalaryLoad = async () => {
    // const month = new Date();
    // const presentMonth = month.getMonth();
    // const nextMonth = month.getMonth() - 1;
    // const pmonth = GetMonthName(presentMonth);
    // const nmonth = GetMonthName(nextMonth);
    const currentDate = new Date();

    // Get current month name
    const currentMonthName = currentDate.toLocaleString('default', { month: 'long' }).toUpperCase();

    // Get previous month by subtracting one month
    const previousDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
    const previousMonthName = previousDate.toLocaleString('default', { month: 'long' }).toUpperCase();

    try {
      let response = await axios.get(`http://139.59.11.130:6561/api/salary/${currentMonthName}/${previousMonthName}/${data.yearName}`)
      // const response = await StaffService.getStaffSalaryLaunchpad(
      //   pmonth,
      //   nmonth,
      //   data.yearName
      // );
      // setTotalSalary(response.data.data)
      // setTotalSalary(response.data.data);
      console.log(response.data.launchpadSalary);
      setTotalSalary(response.data.launchpadSalary.total);
      setPresent(response.data.launchpadSalary.presentMonth);
      setPrevious(response.data.launchpadSalary.previousMonth);
    } catch (error) {
      console.log(error);
    }
  };

  const date = new Date();
  const today = {
    day: "2-digit",
    month: "long",
    year: "numeric",
    weekday: "long",
  };
  const textDate = date.toLocaleDateString("eng-GB", today);
  return (
    <div>
      <Topbar id="launchpad" />
      <Box sx={{ flexGrow: 1, p: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <CardItem sx={{ backgroundColor: "rgb(215 228 189)" }}>
              <Typography>STUDENTS ABSENT TODAY</Typography>
              {/* <Skeleton variant="rounded" width={350} height={120} /> */}
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>BOYS</th>
                      <th>GIRLS</th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td>{boyAbsent}</td>
                      <td>{girlAbsent}</td>
                      <td style={{ color: "#ff0000" }}>{studentTotalAbsent}</td>
                    </tr>
                  </tbody>
                </table>
                <Typography variant="h6" fontWeight={"bold"} marginTop={2}>
                  Total Strength ↦ {studentStrength}
                </Typography>
              </div>
            </CardItem>
          </Grid>
          <Grid item xs={6} md={3}>
            <CardItem sx={{ backgroundColor: "rgb(183 221 232)" }}>
              <Typography>STAFF ABSENT TODAY</Typography>
              {/* <Skeleton variant="rounded" width={350} height={120} /> */}
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>ABSENT</th>
                      <th>PRESENT</th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{totalAbsent}</td>
                      <td>{totalPresent}</td>
                      <td style={{ color: "#ff0000" }}>{totalStrength}</td>
                    </tr>
                  </tbody>
                </table>
                <Typography variant="h6" fontWeight={"bold"} marginTop={2}>
                  Total Strength ↦ {totalStrength}
                </Typography>
              </div>
            </CardItem>
          </Grid>

          <Grid item xs={6} md={3}>
            <CardItem sx={{ backgroundColor: "rgb(186 205 229)" }}>
              <Typography>FEES COLLECTED</Typography>
              {/* <Skeleton variant="rounded" width={350} height={120} /> */}
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>TODAY</th>
                      <th>THIS MONTH</th>
                      <th>YEAR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{totalFee ? totalFee.dayFeesCollected : 0}</td>
                      <td>{totalFee ? totalFee.monthFeesCollected : 0}</td>
                      <td>{totalFee ? totalFee.yearCollected : 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardItem>
          </Grid>

          <Grid item xs={6} md={3}>
            <CardItem sx={{ backgroundColor: "rgb(184 222 233)" }}>
              <Typography>SALARIES</Typography>
              {/* <Skeleton variant="rounded" width={350} height={120} /> */}
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>LAST MONTH</th>
                      <th>THIS MONTH</th>
                      <th>YEARLY</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {previous
                          ? previous
                          : 0}
                      </td>
                      <td>
                        {present
                          ? present
                          : 0}
                      </td>
                      <td>{totalSalary ?totalSalary  : 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardItem>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={6} md={8.5}
            sx={{ border: "2px solid black", overflow: "scroll" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <CardItem sx={{ backgroundColor: "#e3f2ff" }}>
                  <Typography>
                    <b>UPCOMING EVENTS</b>
                  </Typography>
                  {/* <Skeleton variant="rounded" width={350} height={120} /> */}
                  <Box
                    sx={{
                      height: "80%",
                      overflowY: "scroll",
                    }}
                  >
                    <ListEventFuture />
                  </Box>
                </CardItem>
              </Grid>
              <Grid item xs={6} md={4}>
                <CardItem sx={{ backgroundColor: "#e3f2ff" }}>
                  <Typography>
                    <b>PRESENT EVENTS</b>
                  </Typography>
                  {/* <Skeleton variant="rounded" width={350} height={120} /> */}
                  <Box
                    sx={{
                      height: "80%",
                      overflowY: "scroll",
                    }}
                  >
                    <ListEventPresent />
                  </Box>
                </CardItem>
              </Grid>
              <Grid item xs={6} md={4}>
                <CardItem sx={{ backgroundColor: "#e3f2ff" }}>
                  <Typography>
                    <b>PAST EVENTS</b>
                  </Typography>
                  {/* <Skeleton variant="rounded" width={350} height={120} /> */}
                  <Box
                    sx={{
                      height: "80%",
                      overflowY: "scroll",
                    }}
                  >
                    <ListEventPast />
                  </Box>
                </CardItem>
              </Grid>
              <Grid item xs={6} md={12}>
                <CardItem sx={{ backgroundColor: "#e3f2ff" }}>
                  <Typography>
                    <b>FUTURE EVENTS</b>
                  </Typography>
                  {/* <Skeleton variant="rounded" width={350} height={120} /> */}
                  <Box
                    sx={{
                      height: "80%",
                      overflowY: "scroll",
                    }}
                  >
                    <ListEventNextMonth />
                  </Box>
                </CardItem>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={3.5} sx={{ border: "2px solid black", borderLeft:"none" }}>

            <Box sx={{ textAlign: "center"}}>
              <Box display={"flex"}>
                <Typography fontWeight={"bold"}>{textDate}</Typography> <hr />
                <Typography fontWeight={"bold"} marginRight={"5px"}>
                  Academic Year <br /> {data.yearName}
                </Typography>
              </Box>
              <Box  sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                {/* <Calendar
                  onChange={onChange}
                  value={value}
                /> */}
                <CalendarEvent />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
