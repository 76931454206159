import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Stack, Typography,Modal,Fade,Backdrop } from "@mui/material";
import SucessModal from "../../buildSchool/Dashboard/popup/sucessPopUp";
import { C } from "../../util/C";
import { StandardDivision } from "../../../services/StandardDIvision";
import { AddStandarsService } from "../../../services/addStandardsService";
import { DataGrid, GRID_CHECKBOX_SELECTION_FIELD } from "@mui/x-data-grid";
import { CheckBox } from "@mui/icons-material";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { useNavigate } from "react-router-dom";
import { FeeStructure } from "../../../services/FeeStructure";
import { StudentService } from "../../../services/studentService";
import { StudentFeeStructure } from "../../../services/StudentFees";
import axios from "axios"

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary,
}));
const StandardItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid",
}));

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

// ---------------------------------student columns--------------------------------

const columns = [
  {
    field: "id",
    headerName: "USID ",
    // type: 'number',
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"USID"}</strong>,
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    field: "name",
    headerName: "NAME",
    flex: 2,
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    field: "gender",
    headerName: "GENDER",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"GENDER"}</strong>,
    headerClassName: "super-app-theme--header",
    editable: true,
  },

  // {
  //   field: "appliedFees",
  //   headerName: " APPLIED FEES",
  //   headerAlign: "center",
  //   align: "center",
  //   sortable: false,
  //   renderHeader: () => (
  //     <strong style={{ fontSize: 15 }}>{"APPLIED FEES"}</strong>
  //   ),
  //   headerClassName: "super-app-theme--header",
  //   flex: 1,
  // },
];

// ---------------------------------fees columns--------------------------------

const column = [
  {
    field: "id",
    headerName: " NO",
    width: 60,
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
    headerClassName: "super-app-theme--header",
    hide: true,
  },
  {
    field: "feesId",
    headerName: " NO",
    width: 60,
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "feesTitle",
    headerName: "FEE TITLE",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"FEE TITLE"}</strong>,
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    field: "amount",
    headerName: "AMOUNT",
    flex: 1,
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderHeader: () => <strong style={{ fontSize: 15 }}>{"AMOUNT"}</strong>,
    headerClassName: "super-app-theme--header",
    editable: true,
  },
];

export default function BasicGrid() {
  const [Standard, setStandard] = React.useState([]);
  const [Standardname, setStandardName] = React.useState([]);
  const [StandardID, setStandardID] = React.useState([]);
  const [row, setrow] = React.useState([]);
  const [rows, setrows] = React.useState([]);
  const [studentList, setStudentList] = React.useState([]);
  const [feesList, setFeesList] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };

  const onStandardFessSelect = async (e) => {
    e.preventDefault();
    setStandardID(e.target.id);
    // C.c(e.currentTarget.id);
    let yearData = JSON.parse(localStorage.getItem("academicYear"));
    try {
      // let response = await FeeStructure.studentfeesStructureStandard(
      //   e.target.id,
      //   yearData.yearName
      // );
      let response = await axios.get(`http://139.59.11.130:6561/api/feeStructure/${e.target.id}/${yearData.yearName}`)
      setFeesList(response.data.data);
      C.c(response.data.data);

      let feesAmountstd = [];
      response.data.data.forEach(function (data, i) {
        if (data.feestitleName != "Total Fees Amount") {
          feesAmountstd.push({
            // id: data.feesStructureID,
            id:data._id,
            feesId: i + 1,
            feesTitle: data.feestitleName,
            amount: data.amount,
          });
        }
      });
      setrow(feesAmountstd);
    } catch (error) {
      C.c(error);
    }

    try {
      // let response = await StudentFeeStructure.GetStudentsFeesApplied(
      //   e.target.id,
      //   yearData.yearName
      // );
      let response = await axios.get(`http://139.59.11.130:6561/api/student/${e.target.id}`)
      setStudentList(response.data.data);
      console.log(response.data.data);

      let studentfeesdetails = [];
      response.data.data.forEach(function (rec, i) {
        // if (!rec.studentFeesID) {
        studentfeesdetails.push({
          id: rec.studentUSN,
          name: rec.studentName,
          gender: rec.studentGender,
          appliedFees: rec.appliedFees,
        });
        // }
      });
      console.log(studentfeesdetails);
      setrows(studentfeesdetails);
    } catch (error) {
      C.c(error);
    }
  };

  let navigate = useNavigate();
  const onLogout = () => {
    console.log("onLogout");
    navigate("/launchpad", { replace: true });
  };

  const [selectedStudent, setSelectedStudent] = React.useState([]);
  const [selectedFees, setSelectedFees] = React.useState([]);

  const onFeeApply = async (e) => {
    let data = JSON.parse(localStorage.getItem("academicYear"));

    const selectedStudentArray = [];
    const selectedFeesArray = [];
    let FeeAmount = 0;
    if (studentList) {
      selectedStudent.forEach((r) => {
        studentList.forEach((s) => {
          if (r == s.studentUSN) {
            selectedStudentArray.push(s);
          }
        });
      });
      C.c(selectedStudentArray);
    } else {
      alert("Please select");
    }
    if (feesList) {
      selectedFees.forEach((r) => {
        feesList.forEach((s, i) => {
          console.log(r)
          console.log(s)
          // if (r == s.feesStructureID)
            if(r == s._id)
            {
            FeeAmount = FeeAmount + s.amount;
            selectedFeesArray.push(s);
          }
        });
      });
      C.c(selectedFeesArray);
    } else {
      alert("Please select");
    }

    let obj = [];
    selectedStudentArray.forEach((r) => {
      console.log(r);
      obj.push({
        appliedFees: FeeAmount,
        divisionID: r.divisionID,
        divisionName: r.divisionName,
        feesStructure: selectedFeesArray,
        standard: r.standardName,
        standardId: r.standardID,
        // studentFeesID:r. "string",
        studentProfileUrl: r.studentProfileUrl,
        studentGender: r.studentGender,
        studentID: r._id,
        studentName: r.studentName,
        studentUSN: r.studentUSN,
        fatherName:r.fatherName,
        // type:r. "string",
        yearID: data.yearID,
        yearName: data.yearName,
      });
    });
    console.log(obj);

    try {
      // let response = await StudentFeeStructure.studentsFeesAppliedCreate(obj);
      let response = await axios.post('http://139.59.11.130:6561/api/fee/',obj)
      C.c(response.data.data);
      handleClose();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (error) {
      C.c(error);
    }

  };

  return (
    <div>
      {/* <LaunchpadTopBar id="fees" /> */}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Item>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item xs={4} md={2} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      name={element.standardName}
                      onClick={(e) => {
                        onStandardFessSelect(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={0} sx={{ mt: 2 }}>
          <Grid item xs={12} md={8}>
            <Item sx={{ height: 550 }}>
              <Box
                sx={{
                  mt: 2,
                  height: 420,
                  width: "100%",
                  textAlign: "center",
                  "& .super-app-theme--header": {
                    backgroundColor: "#c0c0c0",
                    //  color:'white',
                    fontWeight: "bold",
                    fontSize: "15px",
                    border: "1px solid white",
                  },
                  overflowX: "auto",
                }}              >
                <DataGrid
                  showColumnRightBorder={true}
                  showCellRightBorder={true}
                  rows={rows} 
                  columns={columns}
                  checkboxSelection
                  sx={{minWidth:600}}
                  isRowSelectable={(params) => params.row.appliedFees == null}
                  disableColumnMenu
                  //  pageSize={7}
                  onSelectionModelChange={(ids) => setSelectedStudent(ids)}
                  rowsPerPageOptions={[10]}
                // checkboxSelection
                // disableSelectionOnClick
                // experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
              <Typography
                sx={{
                  border: "1px solid black",
                  borderRadius: "10px",
                  fontWeight: "bold",
                }}
              >
                DO you want to Apply the Selected{" "}
                <b sx={{ color: "red" }}>FEES Amount XXXXXX</b> to the Above
                Selected Students, IF any student’s Fees Receipts is Generated
                earlier this fees amount will not get applicable, You can select
                Individual FEES Module for applying FEES to individual students
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12} md={4}>
            <Item sx={{ height: 550 }}>
              <Typography
                sx={{
                  fontSize: "15px",
                  textAlign: "center",
                  fontWeight: "Bold",
                  backgroundColor: "lightblue",
                }}
              >
                SELECT THE STUDENT, FEES AND APPLY
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  height: 420,
                  width: "100%",
                  textAlign: "center",
                  "& .super-app-theme--header": {
                    backgroundColor: "#c0c0c0",
                    //  color:'white',
                    fontWeight: "bold",
                    fontSize: "15px",
                    border: "1px solid white",
                  },
                  overflowX: "auto",
                }}              >
                <DataGrid
                  showColumnRightBorder={true}
                  showCellRightBorder={true}
                  rows={row}
                  columns={column}
                  disableColumnMenu
                  //  pageSize={7}
                  rowsPerPageOptions={[10]}
                  checkboxSelection
                  onSelectionModelChange={(ids) => setSelectedFees(ids)}
                  sx={{
                    minWidth:400
                  }}
                />
              </Box>
              <Button
                variant="contained"
                color="success"
                sx={{
                  border: "1px solid black",
                  mt: 1,
                  fontWeight: "bold",
                  borderRadius: "10px",
                  color: "white",
                  fontSize:15,
                  backgroundColor: "green",
                }}
              >
                SLECTED FEES TAG AMOUNT
              </Button>
              <Button
                variant="contained"
                color="info"
                sx={{
                  border: "1px solid black",
                  width: 200,
                  mt: 1,
                  ml: 1,
                  borderRadius: "10px",
                  fontWeight: "bold",
                  fontSize:15,
                  backgroundColor: "blue",
                  color: "white",
                }}
                onClick={() => {
                  setOpen(true);
                }}
                // onClick={onFeeApply}
              >
                APPLY Fees
              </Button>

 <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              // onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  {/* <Typography
                    id="transition-modal-title"
                    variant="body1"
                    component="h2"
                  >
                    You have clicked on
                  </Typography>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    FREEZ INFORMATION
                  </Typography> */}
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    Do you want to apply the selected fees amount to the above students
                    Please verify the details thoroughly before applying as this process
                    is infrequent
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      // onClick={marksSave}
                      onClick={onFeeApply}
                    >
                      apply
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Box>
              </Fade>
            </Modal>


            <Modal
              open={success}
              // onClose={() => setSucess(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div sx={style}>
                <SucessModal
                  text="Fees Applied  Successfully"
                  name="Fees Applied  Successfully"
                />
              </div>
            </Modal>

            </Item>

            <Box display={"flex"} justifyContent={"center"} mt={2} paddingBottom="25px">
              <Button
                variant="contained"
                color="error"
                onClick={onLogout}
                sx={{
                  borderRadius: "10px",
                  fontWeight: "bold",
                  // ml: 40,
                  fontSize:15,
                  backgroundColor: "#ca424f",
                  color: "white",
                }}
              >
                Do NOTHING-EXIT
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
