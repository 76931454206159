import {
  Avatar,
  Button,
  gridClasses,
  Paper,
  Typography,
  Grid,
  ButtonGroup,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { C } from "../../util/C";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { StudentInfoService } from "../../../services/StudentInfo/StudentList";
import { UploadFileService } from "../../../services/FileUploadService";
import { LibraryService } from "../../../services/libraryService";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";

const FormItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: 180,
  // height: 80
  // color: theme.palette.text.secondary,
}));
const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  // fontWeight:"bold"
  // color: theme.palette.text.secondary
}));
export default function Books() {
  const [imageUrl, setImageUrl] = React.useState("");
  const [imageSrc, setImageSrc] = React.useState();
  const [excel, setExcel] = React.useState([]);
  const [studentInfo, updateStudentInfo] = React.useState({});
  const [infoEditable, updateInfoEditable] = React.useState(false);

  let navigate = useNavigate();
  const onLaunchPad = () => {
    navigate("/launchpad", { replace: true });
  }

  const fileBrowseHandler = (event) => {
    let value = URL.createObjectURL(event.currentTarget.files[0]);
    setImageSrc(event.currentTarget.files[0]);
    C.c(value);
    // C.c(filevalue);
    setImageUrl(value);
    // setImageSrc(filevalue);
    C.c(imageSrc);
  };
  const [columns, setColumns] = React.useState([
    {
      field: "id",
      headerName: "NO",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"No."}</strong>,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "bookNumber",
      headerName: "BOOK NUMBER",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BOOK NUMBER"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "bookTitle",
      headerName: "TITLE OF THE BOOK",
      flex: 3,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"TITLE OF THE BOOK"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "borrow",
      headerName: "BORROWED ON",
      flex: 1,
      type: "date",
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"BORROWED ON"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "return",
      headerName: "RETURNED ON ",
      flex: 1,
      type: "date",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"RETURNED ON"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "condition",
      headerName: "CONDITION ",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderHeader: () => (
        <strong style={{ fontSize: 15 }}>{"CONDITION"}</strong>
      ),
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "photo",
    //   headerName: "COVER PHOTO",
    //   flex: 2,
    //   height: "20%",
    //   // align: "center",
    //   renderHeader: () => (
    //     <strong style={{ fontSize: 15 }}>{"COVER PHOTO"}</strong>
    //   ),
    //   headerClassName: "super-app-theme--header",
    //   renderCell: () => {
    //     return (
    //       // <FormItem>
    //       <>
    //         <Avatar alt="A" src={imageUrl} sx={{ width: 80, height: 80 }} />
    //         <input
    //           style={{ display: "none", ml: 5 }}
    //           id="contained-button-file"
    //           type="file"
    //           accept="image/*"
    //           name="bookImg"
    //           onChange={fileBrowseHandler}
    //         />
    //         <label htmlFor="contained-button-file">
    //           <Button variant="contained" color="primary" component="span">
    //             Upload
    //           </Button>
    //         </label>
    //       </>
    //       // </FormItem>
    //     );
    //   },
    // },
  ]);

  const [rows, setRows] = React.useState([]);
  const studentDetailsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let studentUSN = localStorage.getItem("studentUSN");
    try {
      let response = await StudentInfoService.studentInfoShowStudentDetails(
        studentUSN,
        data.yearName
      );
      console.log(response.data.data);
      updateStudentInfo(response.data.data);
      DocumentLoad(response.data.data.studentUSN);

      // setStudentList(response.data.data);
      // let {fatherName,fatherProfession,fatherQualification,motherName,motherProfession,motherQualification,standardName,
      //   studentAddress,studentFirstLanguage,studentName,studentReligion,studentUSN,yearName} = response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const DocumentLoad = async (usid) => {
    let libData = [];
    let data = JSON.parse(localStorage.getItem("academicYear"));

    try {
      let response = await LibraryService.GetStudentBooks(usid, data.yearName);
      console.log(response.data.data);
      if (response.data.data.libraryBooksBorroweds) {
        response.data.data.libraryBooksBorroweds.forEach((rec, i) => {
          libData.push({
            id: i + 1,
            bookNumber: rec.bookNumber,
            bookTitle: rec.bookTitle,
            borrow: rec.borrowedon,
            return: rec.returnedOn,
            condition: rec.condition,
          });
        });
      }
      setRows(libData);

      let stud = [];
      response.data.data.libraryBooksBorroweds.forEach((res, i) => {
        stud.push({
          NO: i + 1,
          BOOKNUMBER: res.bookNumber,
          BOOKTITLE: res.bookTitle,
          BORROW: res.borrowedon,
          RETURN: res.returnedon,
          CONDITION: res.condition
        });
      });
      console.log(stud);
      setExcel(stud);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    studentDetailsLoad();
  }, []);

  const editInfo = () => {
    updateInfoEditable(true);
  };

  const updateStudentName = async (e) => {
    // alert(e.target.value);
    const new_obj = { ...studentInfo, sudentName: e.target.value };
    console.log(JSON.stringify(new_obj));
    updateStudentInfo(new_obj);
  };

  const onAddRoW = (e) => {
    console.log(rows.length);

    let new_row = {
      id: rows.length + 1,
      // id: "",
      bookNumber: "",
      bookTitle: "",
      condition: "",
      borrow: "",
      return: " ",
      image: "0",
    };

    setRows((rows) => [...rows, new_row]);
    console.log(rows);
  };

  const school = JSON.parse(localStorage.getItem("schoolDetails"));
  const academic = JSON.parse(localStorage.getItem("academicYear"));


  const genaratePDF = () => {
    const doc = new jsPDF("landscape");

    const sch = school.schoolNameOnDocs.toUpperCase();
    const acad = academic.yearName;
    const img = school.schoolLogoUrl;
    doc.setFont("helvetica", "bold");
    var fontSize = 18;
    var text = `${sch}`;
    var pageWidth = doc.internal.pageSize.getWidth();
    var textWidth =
      (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
    var centerX = (pageWidth - textWidth) / 2;
    doc.text(text, centerX, 15);

    doc.addImage(img, "JPEG", 15, 5, 27, 27);
    doc.setFontSize(10);
    doc.text(` ${acad}`, 250, 15);



    doc.setFontSize(15);
    doc.setFont("helvetica", "bold");
    doc.text("INDIVIDUAL STUDENT LIBRARY REPORT", 90, 30);

    doc.setFontSize(14);
    doc.text(studentInfo.studentName + "--" + studentInfo.studentUSN, 110, 48);
    doc.addImage(studentInfo.studentProfileUrl, 'JPEG', 220, 25, 30, 36);
    // doc.text(studentInfo.studentUSN, 100,150);
    // doc.text(studentInfo.standardName + "-"+ studentInfo.divisionName, 150,150);
    // doc.text(studentInfo.studentMedicalAttentionDetail,240,150);
    // doc.text(studentInfo.studentBloodGroup,330,150);
    doc.autoTable({
      startY: 60,
      head: [["STD-DIV : " + studentInfo.standardName + "-" + studentInfo.divisionName,
      "MED :" + studentInfo.studentMedicalAttentionDetail,
      "BG :" + studentInfo.studentBloodGroup,
      "DOB :" + studentInfo.studentDOB, "AGE :" + studentInfo.studentAge
      ]],
      headStyles: {
        fillColor: "white",
        textColor: 0,
        fontSize: 12
      },
      theme: "grid",
    });

    const tableData = rows.map((row) => [
      row.id, row.bookNumber, row.bookTitle, row.borrow,
      row.return, row.condition
    ]);

    doc.autoTable({
      startY: 80,
      tableLineColor: [0, 0, 0],
      head: [["NO", "BOOK NUMBER", "BOOK TITLE", "BORROWED ON", "RETURNED ON", "CONDITION"]],
      body: tableData,
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
      },
      tableLineWidth: 0.4,
      theme: "grid",
    });
    doc.save(studentInfo.standardName + "-" + studentInfo.divisionName + "--" + studentInfo.studentUSN + "Library Report.pdf");

  }

  const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(excel);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, studentInfo.standardName + "-" + studentInfo.divisionName + "--" + studentInfo.studentUSN + "Library Report.csv");
  };


  return (
    <React.Fragment>
     
      {/* <LaunchpadTopBar id="studentinfo" /> */}
      <Box sx={{ width: "100%", paddingLeft: "30px", boxSizing: "border-box", mt: 2}}>
          <Grid container>
            <Grid item md={10} xs={8}>
              <Box
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}


              >
                <Stack direction="column" sx={{ width: "100%",  boxSizing: "border-box", mt: 2 }}>
                  <Item >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: "550",
                        fontSize: "17px",
                        backgroundColor: "lightgray",
                      }}
                    >
                      STUDENT NAME
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      {studentInfo ? studentInfo.studentName : "--"}
                    </Typography>
                  </Item>
                </Stack>
              </Box>
              <Box
                sx={{ width: "100%", boxSizing: "border-box", my: "1%" }}
              >
                <Grid  container spacing={{ xs: 1, sm: 1, md: 2 }} sx={{ width: "100%", boxSizing: "border-box", mt: 2 }}>
                  <Grid item md={1} xs={3} >
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        USN
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentUSN : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        STANDARD | DIVISION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.standardName +
                          " | " +
                          studentInfo.divisionID
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={1} xs={3}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        CLASS TR
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {"--"}
                        {/* {studentInfo ? studentInfo.classteacher : "--"} */}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        MEDICAL ATTENTION
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo
                          ? studentInfo.studentMedicalAttentionDetail
                          : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        BLOOD GROUP
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentBloodGroup : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <Item >
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        DOB
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentDOB : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                  {/* <Typography
              variant="body1"
              width="250px"
              sx={{ textAlign: "center" }}
            >
              {studentInfo.studentAge + " Age"}
            </Typography> */}
                  <Grid item md={2} xs={6}>
                    {/* STAFF DESIGNATION */}
                    <Item>
                      <Typography
                        variant="h6"
                        // gutterBottom
                        sx={{
                          // fontWeight: "550",
                          fontSize: "16px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        AGE
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          padding:"16.5px 0px"
                          // fontSize: "18px",
                        }}
                      >
                        {studentInfo ? studentInfo.studentAge : "--"}
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
              {/* <Box
              sx={{
                height: 400,
                width: "100%",
                // ml: "2%",
                "& .super-app-theme--header": {
                  backgroundColor: "#c0c0c0",
                  //  color:'white',
                  fontWeight: "bold",
                  fontSize: "15px",
                  border: "1px solid white",
                },
              }}
            >
              <DataGrid
                disableColumnMenu
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </Box> */}
            </Grid>
            <Grid item md={2} xs={4}>
              <Box display={"flex"} justifyContent={"center"}  sx={{mt:"2%"}}>
                <Avatar
                  alt="Remy Sharp"
                  src={studentInfo ? studentInfo.studentProfileUrl : ""}
                  //   id={element.staffID}
                  variant="square"
                  sx={{
                    width: 150,
                    height: 190,
                    mx: 2,
                    // mt: 1,
                    border: "3px solid skyblue",
                    borderRadius: "5%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
      </Box>
      
      <Grid container>
            <Grid item md={11.35} xs={11.5}>
              <Box
                sx={{
                  height: 400,
                  width: "100%",
                  ml: "2%",
                  // marginTop: "15px",
                  "& .super-app-theme--header": {
                    backgroundColor: "#c0c0c0",
                    //  color:'white',
                    fontWeight: "bold",
                    fontSize: "15px",
                    border: "1px solid white",
                  },
                }}
          >
            <DataGrid
              disableColumnMenu
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              getRowHeight={() => "55px"}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 3,
                },
              }}
            />
              </Box>
            </Grid>
      </Grid>
      
      <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 2 }}>
        <Button
          variant="contained"
          size="large"
          color="error"
          onClick={genaratePDF}
          sx={{
            borderRadius: "30px",
            width: "140px",
            marginBottom: 2,
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          PDF
        </Button>
        <Button
          variant="contained"
          size="large"
          color="success"
          onClick={exportToCSV}
          sx={{
            borderRadius: "30px",
            width: "140px",
            marginBottom: 2,
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          excel
        </Button>

        <Button
          variant="contained"
          size="large"
          onClick={() => { window.print() }}
          sx={{
            borderRadius: "30px",
            backgroundColor: "black",
            color: "white",
            width: "140px",
            marginBottom: 2,
            fontSize: 15,
            fontWeight: "bold",
            "&:hover": { backgroundColor: "black", color: "white" }
          }}
        >
          print
        </Button>

        <Button
          variant="contained"
          size="large"
          onClick={onLaunchPad}
          sx={{
            borderRadius: "30px",
            backgroundColor: "#ca424f",
            color: "white",
            width: "140px",
            marginBottom: 2,
            fontSize: 15,
            fontWeight: "bold",
            "&:hover": { backgroundColor: "#ca424f", color: "white" }
          }}
        >
          exit
        </Button>
      </Box>
    </React.Fragment>
  );
}
