import * as React from "react";
import LaunchpadTopBar from "../../MainTopbar/LaunchPadTopbar";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { AddStandarsService } from "../../../services/addStandardsService";
import { StandardSubjectService } from "../../../services/StandardSubject";
import { StandardDivision } from "../../../services/StandardDIvision";
import { BuildSchoolExam } from "../../../services/examStandards";
import { ExamMarksService } from "../../../services/examMarksService";
import { C } from "../../util/C";
import Divider from "@mui/material/Divider";
// import Img from '../../launchPad/student.jpg'
import { StudentService } from "../../../services/studentService";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as filesaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";

const style = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1350,
  height: 565,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const ItemS = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#aaecac",
  "&:hover": {
    background: "#035e06",
    color: "white",
  },
}));
const ItemD = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#03dff9",
  "&:hover": {
    background: "#038da6",
    color: "white"
  },
}));

// const Item = styled(Paper)(({ theme }) => ({
//   // padding: theme.spacing(1),
//   textAlign: "center",
//   border: "1px solid black",
// }));
const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary
}));

const Item1 = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));

const ItemSb = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: "#37bc98",
  "&:hover": {
    background: "#03946d",
  },
  color: "white",
}));

const ItemE = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  // background: "rgb(255,148,121)",
  background: "#5485e594",
  "&:hover": {
    background: "#3661b494",
  },
}));

const ItemBg = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  border: "1px solid black",
  background: " linear-gradient(180deg,transparent,#c0c0c059, #bbbebe)",
}));

export default function ExamResults() {
  const [Standard, setStandard] = React.useState([]);
  React.useEffect(() => {
    onstandardsLoad();
  }, []);

  const [columns, setColumns] = React.useState([
    {
      field: "id",
      // headerName: "NO.",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NO"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,

    },
    {
      field: "StudentLists",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"NAME"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
    },
    {
      field: "marks",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"MARKS"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
    },
    {
      field: "grade",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: () => <strong style={{ fontSize: 15 }}>{"GRADE"}</strong>,
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
    },
  ]);

  const onstandardsLoad = async () => {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await AddStandarsService.standardListAll(data.yearName);
      C.c(response.data.data);
      setStandard(response.data.data);
    } catch (error) {
      C.c(error);
    }
  };
  const [Standardname, setStandardName] = React.useState();
  const [StandardID, setStandardID] = React.useState();
  const [DivisionName, setDivisionName] = React.useState();
  const [Division, setDivision] = React.useState([]);
  const [StudentListAll, SetStudentListAll] = React.useState([]);
  const [StandardSubjectListAll, updateStandardSubject] = React.useState([]);
  const [standardTest, updateStandardTest] = React.useState([]);
  const [subjectName, setSubjectName] = React.useState();
  const [subjectID, setSubjectID] = React.useState();
  const [testName, setTestName] = React.useState();
  const [testID, setTestID] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [rows, setRows] = React.useState([]);

  async function onStandardSelect(e, index) {
    e.preventDefault();
    setStandardName(e.target.textContent);
    setStandardID(e.target.id);
    C.c(e.currentTarget.id);

    let highlightStandardList = [...Standard];
    highlightStandardList = highlightStandardList.map(
      ({ isSelected, ...highlightStandardList }) => highlightStandardList
    );
    setStandard(highlightStandardList);
    highlightStandardList[index]["isSelected"] = highlightStandardList[index]["isSelected"] ? false : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));
    try {
      let response = await StandardDivision.StandardDivisionWiseStandard(
        e.currentTarget.id,
        data.yearName,
        data.yearID
      );
      C.c(response.data.data);
      setDivision(response.data.data);
    } catch (error) {
      C.c(error.message);
    }

    try {
      let response =
        await StandardSubjectService.findSubjectListByStandardIDYearID(
          data.yearName,
          e.target.id
        );
      console.log(response.data.data);
      response.data.data.sort(function (a, b) {
        return a.subjectOrder - b.subjectOrder;
      });
      updateStandardSubject(response.data.data);
    } catch (error) {
      console.log(error);
    }
    console.log(StandardSubjectListAll);

    try {
      let response = await BuildSchoolExam.showBuildSchoolStandardTest(
        e.target.id,
        data.yearName
      );
      console.log(response.data.data);

      response.data.data.sort(function (a, b) {
        return a.testOrder - b.testOrder;
      });
      updateStandardTest(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }


  async function onSelectDivision(e, index) {
    console.log(e.currentTarget.id);
    setDivisionName(e.currentTarget.id);
    let highlightDivision = [...Division];
    highlightDivision = highlightDivision.map(
      ({ isSelected, ...highlightDivision }) => highlightDivision
    );
    setDivision(highlightDivision);
    highlightDivision[index]["isSelected"] = highlightDivision[index]["isSelected"] ? false : true;

    let data = JSON.parse(localStorage.getItem("academicYear"));
    let student = [];
    try {
      let response = await StudentService.approveddivisionstudentListAll(
        data.yearName,
        e.currentTarget.id,
        StandardID
      );
      C.c(response.data.data);
      SetStudentListAll(response.data.data.sort((a, b) => a.studentName.localeCompare(b.studentName)));

      // let studentArray = [];
      response.data.data.forEach(function (data, i) {
        console.log(data);

        student.push({
          // id: data.studentUSN,
          id: i + 1,
          StudentLists: data.studentName,
        });
      });
      setRows(student);
      // console.log(student)
    } catch (error) {
      C.c(error.message);
    }
  }

  async function onSubjectSelect(e, index) {
    C.c(e.target.textContent);
    let highlightSubject = [...StandardSubjectListAll];
    highlightSubject = highlightSubject.map(
      ({ isSelected, ...highlightSubject }) => highlightSubject
    );
    updateStandardSubject(highlightSubject);
    highlightSubject[index]["isSelected"] = highlightSubject[index][
      "isSelected"
    ]
      ? false
      : true;
  }

  async function onTestSelect(e, index, et) {
    let data = JSON.parse(localStorage.getItem("academicYear"));
    let highlightTest = [...standardTest];
    highlightTest = highlightTest.map(
      ({ isSelected, ...highlightTest }) => highlightTest
    );
    updateStandardTest(highlightTest);
    highlightTest[index]["isSelected"] = highlightTest[index]["isSelected"]
      ? false
      : true;


    let students = [];

    try {
      let response = await axios.get(`http://139.59.11.130:6561/api/marks/${StandardID}/${DivisionName}/${e.target.textContent}/${subjectID}/${data.yearName}`)
      // let response = await ExamMarksService.marksList(
      //   DivisionName,
      //   StandardID,
      //   e.target.id,
      //   subjectID,
      //   data.yearName
      // );
      console.log(response.data.marksList);
      response.data.marksList.forEach((rec, i) => {
        students.push({
          id: i + 1,
          StudentLists: rec.studentName,
          marks: rec.markSheet,
          grade: rec.grade
        });
      });
      setRows(students);
    }
    catch (error) {
      console.log(error);
    }
  }

  const academic = JSON.parse(localStorage.getItem("academicYear"));
  const school = JSON.parse(localStorage.getItem("schoolDetails"));
  const generatePDF = () => {
    const doc = new jsPDF("p", "pt", "a4");

    doc.setFontSize(10);
    doc.text(`${academic.yearName}`, 500, 35);

    const text = `${school.schoolNameOnDocs.toUpperCase()}`;
    const fontSize = 18;
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.setFontSize(fontSize);
    doc.setFont("Helvetica", "bold");
    doc.text(text, pageWidth / 2, 35, { align: 'center' });

    const img = `${school.schoolLogoUrl}`;
    doc.addImage(img, 'JPEG', 20, 20, 80, 70);

    const tableData = rows.map((row) => [
      row.id,
      row.StudentLists,
      row.marks,
      row.grade
    ]);

    const std = Standardname;
    const div = DivisionName;
    const sub = subjectName.toUpperCase();
    const tes = testName.toUpperCase();

    doc.setFontSize(15);
    doc.setFont("Tims-Roman", "bold");
    doc.text("SUBJECT WISE EXAM RESULT", 180, 75)


    doc.setFontSize(12);
    doc.setFont("Times-Roman", "bold");
    doc.text(std, 105, 115);
    doc.text(div, 220, 115);
    doc.text(tes, 300, 115);
    doc.text(sub, 450, 115);

    doc.autoTable({
      startY: 140,
      tabLineColor: [0, 0, 0],
      head: [
        ["NO", "NAME", "MARKS", "GRADE"],
      ],
      body: tableData,
      headStyles: {
        valign: "middle",
        halign: "center",
      },
      bodyStyles: {
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
      },
      tableLineWidth: 0.95,
      theme: "grid",
    });
    doc.setFontSize(10);
    doc.save("Result -  " + Standardname + " - " + DivisionName + " - " + testName + " - " + subjectName + " .pdf")
  }

  const fileType = "csv";
  const exportToCSV = () => {
    const visible = XLSX.utils.json_to_sheet(rows);
    const wb = { Sheets: { product: visible }, SheetNames: ["product"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    filesaver.saveAs(data, " Monthly Attendance" + ".csv");
  }
  let navigate = useNavigate();
  const onLaunchPad = () => {
    navigate("/launchpad", { replace: true })
  }

  return (
    <React.Fragment>
      {/* <LaunchpadTopBar id="reports" /> */}
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          backgroundColor: "lightblue",
          my: 2,
          fontWeight: "bold",
        }}
      >
        SELECT STANDARD, DIVISION, SUBJECT, TEST TO GET THE RESULTS
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          mt: 1,
          width: "100%",
          padding: "0 10px",
          boxSizing: "border-box",
        }}
      >
        <Grid container spacing={1}>
          <Grid item md={8} sm={8} xs={8}>
            <ItemBg>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Standard.map((element, index) => (
                  <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                    <ItemS
                      id={element.standardID}
                      value={element.standardName}
                      onClick={(e) => {
                        onStandardSelect(e, index);
                      }}
                      className={element.isSelected ? "Selected" : ""}
                      sx={{ fontWeight: "bold" }}
                    >
                      {element.standardName}
                    </ItemS>
                  </Grid>
                ))}
              </Grid>
            </ItemBg>
          </Grid>
          <Grid item md={4} sm={4} xs={4}>
            <ItemBg>
              <Grid container spacing={1} sx={{ minHeight: 40 }}>
                {Division.map((element, index) => (
                  <Grid item md={1.5} xs={4} sx={{ cursor: "pointer" }}>
                    <ItemD
                      sx={{
                        fontWeight: "bold",
                      }}
                      onClick={(e) => {
                        onSelectDivision(e, index);
                      }}
                      id={element.divisionName}
                      className={element.isSelected ? "selected" : ""}
                    >
                      {" "}
                      {element.divisionName}
                    </ItemD>
                  </Grid>
                ))}
              </Grid>
            </ItemBg>
          </Grid>
        </Grid>
      </Box>
      
      <Grid
        container
        spacing={1}
        sx={{ width: "100%", mt: "10px", padding: "0 10px", boxSizing: "border-box" }}
      >
        <Grid item xs={8.1} md={8.05}>
          <ItemBg>
            <Grid container spacing={1} sx={{ minHeight: 40 }}>
              {StandardSubjectListAll.map((element, index) => (
                <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                  <ItemSb
                    sx={{ fontWeight: "bold" }}
                    id={element.standardSubjectID}
                    value={element.subjectName}
                    onClick={(e) => {
                      onSubjectSelect(e, index);
                      setSubjectName(e.target.textContent);
                      setSubjectID(e.target.id);
                    }}
                    className={element.isSelected ? "selectSubject" : ""}
                  >
                    {element.subjectName.toUpperCase()}
                  </ItemSb>
                </Grid>
              ))}
            </Grid>
          </ItemBg>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{ width: "100%", mt: "10px", padding: "0 10px", boxSizing: "border-box" }}
      >
        <Grid item xs={8.1} md={8.05}>
          <ItemBg>
            <Grid container spacing={1} sx={{ minHeight: 40 }}>
              {standardTest.map((element, index) => (
                <Grid item md={2} xs={4} sx={{ cursor: "pointer" }}>
                  <ItemE
                    sx={{ fontWeight: "bold" }}
                    onClick={(e) => {
                      onTestSelect(e, index, element.testTitleName);
                      setTestName(e.target.textContent);
                    }}
                    className={element.isSelected ? "selectedTest" : ""}
                  >
                    {element.testTitleName.toUpperCase()}
                  </ItemE>
                </Grid>
              ))}
            </Grid>
          </ItemBg>
        </Grid>
      </Grid>

        <Grid container spacing={2}>
          <Box
            sx={{
              height: 400,
              width: "100%",
              mt: 3,
              // border: "2px solid black",
            }}
          >
            <Box
              sx={{
                height: 370, margin: "2%",
                "& .super-app-theme--header": {
                  backgroundColor: "#c0c0c0",
                  //  color:'white',
                  fontWeight: "bold",
                  fontSize: "15px",
                  border: "1px solid white",
                },
              }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                disableColumnMenu
              />
            </Box>
          </Box>
        </Grid>

      <Box sx={{ display: "flex", justifyContent: "space-evenly", mt: 2 ,paddingBottom:"25px"}}>
        <Button
          variant="contained"
          color="error"
          sx={{
            fontSize: 15,
            fontWeight: 'bold',
            borderRadius: "30px",
            width: '120px',
            "&:hover": { backgroundColor: "#ca424f" }
          }}
          onClick={generatePDF}
        >PDF
        </Button>

        <Button
          variant="contained"
          color="success"
          sx={{
            fontSize: 15,
            fontWeight: 'bold',
            borderRadius: "30px",
            width: '120px',
          }}
          onClick={exportToCSV}
        >Excel
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#ca424f",
            fontSize: 15,
            fontWeight: 'bold',
            width: "120px",
            borderRadius: "30px",
            "&:hover": { backgroundColor: "#ca424f" }
          }}
          onClick={onLaunchPad}
        >Exit</Button>
        </Box>
    </React.Fragment>
  );
}

