import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Button, ButtonGroup, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SchoolMaster } from "../../services/SchoolMaster";
import { UploadFileService } from "../../services/FileUploadService";

const TopBar = () => {
  const [schoolName, setschoolName] = React.useState("");
  const [schoolImgID, setschoolImgID] = React.useState("");
  const [schoolImg, setschoolImg] = React.useState("");
  const [academicYear, setacademicYear] = React.useState("");
  const [userData, setUserData] = React.useState(
    JSON.parse(localStorage.getItem("userData"))
  );

  const [date, setDate] = React.useState("");

  React.useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date();
    let year = date.getFullYear();
    let month = months[date.getMonth()];
    let day = date.getDate();

    setDate(`${day} ${month} ${year}`);

    const user = JSON.parse(localStorage.getItem("userData"));
    const schoolData = JSON.parse(localStorage.getItem("schoolDetails"));
    const academicData = JSON.parse(localStorage.getItem("academicYear"));
    setschoolName(
      schoolData ? (
        <b>{schoolData.schoolNameOnDocs.toUpperCase()}</b>
      ) : (
        "NEW INTERNATIONAL SCHOOL"
      )
    );
    setacademicYear(academicData.yearName);
    setschoolImgID(schoolData.schoolLogoUrl);
    setUserData(user);
    console.log(user.userName);
    onschoolLoad();
  }, []);

  const onschoolLoad = async () => {
    try {
      let response = await SchoolMaster.schoolList();
      setschoolImg(response.data.data[0].schoolLogoUrl);
      console.log(response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  let navigate = useNavigate();

  const onLaunchPad = () => {
    console.log("launchpad");
    navigate("/launchpad", { replace: true });
  };
  // * "========================Route===================================="

  return (
    <div>
      <AppBar position="static" color="transparent">
        <Container maxWidth="xl">
          <Toolbar sx={{ height: 120 }} disableGutters>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              {/* <Tooltip title="writting future"> */}
              <Avatar
                variant="square"
                sx={{
                  width: 90,
                  height: 90,
                }}
                alt="Myschool Book"
                src={schoolImg ? schoolImg : ""}
              />
              {/* </Tooltip> */}
              <Typography
                variant="subtitle1"
                noWrap
                component="div"
                fontWeight="bold"
                sx={{ flexGrow: 1 }}
              >
                {userData.userName ? userData.userName : "ADMIN"}
                {/* Academic Year : {academicYear ? academicYear : "invalid year"} */}
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                {schoolName}
                {/* VENUS INTERNATIONAL SCHOOL */}
              </Typography>
              {/* <Tooltip sx={{}} title="writting future"> */}
              <IconButton sx={{ p: 0 }}>
                <Avatar
                  alt="writting future"
                  src="ms2.jpg"
                  variant="square"
                  sx={{
                    width: 145,
                    height: 80,
                  }}
                  onClick={onLaunchPad}
                />
              </IconButton>
              {/* </Tooltip> */}
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              {/* <Tooltip title="MySchool Book"> */}
              <Avatar
                alt="MySchool Book"
                src="logo.png"
                variant="square"
                sx={{
                  width: 90,
                  height: 90,
                }}
              />
              {/* </Tooltip> */}
              <Typography
                variant="subtitle1"
                noWrap
                component="div"
                fontWeight="bold"
                sx={{ flexGrow: 1 }}
              >
                School Wave
              </Typography>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
export default TopBar;
